.reimbursement-container {
  display: block;
  min-height: 60vh;
}
.reimbursement-container .title-container {
  height: 50px;
  padding: 15px 0;
}
@media (min-width: 768px) {
  .reimbursement-container .title-container {
    height: 79px !important;
  }
}
@media (min-width: 992px) {
  .reimbursement-container .title-container {
    height: 97px !important;
  }
}
.reimbursement-container .select-type {
  margin-top: 15px;
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 0;
  font-size: 18px;
  color: #28373a;
  font-weight: 600;
}
.reimbursement-container .margin-bottom {
  margin-bottom: 15px !important;
}
.reimbursement-container .margin-bottom-half {
  margin-bottom: 7px !important;
}
.reimbursement-container a {
  text-decoration: none;
}
.reimbursement-container hr {
  height: 2px;
  background: #E4E4E4;
  margin: 0px;
  border-top: 0;
}
.reimbursement-container .faq-container {
  border-width: 2px;
  border-style: solid;
  border-color: #E4E4E4;
  border-radius: 8px;
}
.reimbursement-container .edit-reimbrusement-container {
  border-width: 2px;
  border-style: solid;
  border-color: #E4E4E4;
  border-radius: 8px;
}
.reimbursement-container .edit-reimbrusement-container .tab-parent {
  margin: 25px 15px;
  max-width: 384px;
}
.reimbursement-container .edit-reimbrusement-container .tab-parent .unselected-tab {
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  border-width: 2px;
  border-style: solid;
  border-color: #4aa574;
  box-shadow: none;
  font-weight: 700;
  font-size: 16px;
  color: #4aa574;
}
.reimbursement-container .edit-reimbrusement-container .tab-parent .selected-tab {
  background-color: #4aa574;
  box-sizing: border-box;
  border-width: 2px;
  border-style: solid;
  border-color: #4aa574;
  font-weight: 700;
  font-size: 16px;
  color: #FFFFFF;
}
.reimbursement-container .edit-reimbrusement-container .tab-parent .left-tab {
  height: 44px;
  margin-bottom: 0;
  border-radius: 40px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding-left: 0;
  padding-right: 0;
}
.reimbursement-container .edit-reimbrusement-container .tab-parent .right-tab {
  height: 44px;
  margin-bottom: 0;
  border-radius: 40px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-left: 0;
  padding-right: 0;
}
.reimbursement-container .edit-reimbrusement-container .billing-notes {
  background-color: #f5fafb;
  padding: 15px;
  margin-bottom: 0;
  font-weight: 400;
  color: #000000;
  font-size: 12px;
}
@media (min-width: 992px) {
  .reimbursement-container .edit-reimbrusement-container .billing-notes {
    font-size: 14px;
  }
}
.reimbursement-container .link-text {
  font-size: 14px;
  font-weight: 700;
}
.reimbursement-container .link-icon {
  font-size: 13px;
}

.account-info-image {
  height: 105px;
  object-fit: contain;
}

.account-info-heading-text {
  color: rgb(51, 51, 51);
  margin-bottom: 0px;
}

.account-info-margin {
  margin-top: 22px;
}

.close-button {
  width: 238px;
}

