@import "./../../styles/_variables.scss";
@import "./../../styles/_mixins.scss";

.home {
  padding: 20px 15px;

  .text-primary-heading.mb-0{
    color:$header-Color !important;
  }
  .list-container {
    a,
    a:hover,
    :active {
      color: $header-Color !important;
      text-decoration: none !important;
    }
  }

  #extole-home-refer {
    cursor: pointer;
  }

  .home-list-box {
    display: flex;
    align-items: center;
    border: 2px solid $light;
    padding: 10px 20px;
    border-radius: 10px;
    height: 100%;

    @include media-breakpoint-only(md){
      padding:10px;
    }

    .home-list-icons {
      width: 40px;
      height: 40px;
    }
    .text-primary {
      color: $header-Color !important; 
    }
    .text-secondary-angle {
      color: $header-Color;
    }

    .claim-icon{
      height: 45px;
      width: 45px;
    }

    .home-list-fa-icons {
      font-size: 36px;
    }

    .home-list-title {
      font-size: 18px;
      font-weight: 600;
      @include media-breakpoint-only(md)
      {
        padding:0px 0px 0px 10px;
      }
    }
  }
  .justify-content-between .text-secondary {
    content:url(../../../public/assets/images/edit_icon.svg);
  }
  .link-list {
    padding: 0 0 40px 0 !important;
    border: 2px solid $light;
    border-radius: 10px;
    flex-grow: 1;
    .list-title {
      padding: 15px 20px 25px 20px;
      color: $primary;
      font-size: 24px;
      border-bottom: 2px solid $light;
    }

    a, a:hover {
      text-decoration: none !important;
    }

    .list-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 8px 20px;
      border-bottom: 2px solid $light;
      color: #000000;
      text-decoration: none;
    }
  }

  .links {
    .content-wrapper {
      padding: 22px 20px;
      border: 2px solid $light;
      flex-grow: 1;
      border-radius: 10px;
      overflow-wrap: break-word;

      a {
        text-decoration: none;
        font-weight: 600;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .home {
    .link-list {
      padding: 22px 20px !important;

      .list-title {
        padding: 0 0 5px 0 !important;
        font-weight: 700;
        color: #000000;
        font-size: 16px;
        border: none !important;
      }

      a {
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline !important;
      }

      .list-content {
        color: $text-Blue;
        padding: 5px 0 0 0 !important;
        border: none !important;
        font-weight: 600;

        i {
          display: none;
        }
      }
    }
    .home-list-box {
      height: 78px;
    }
  }
}
