@import "./../../../../styles/_variables.scss";
@import "./../../../../styles/_mixins.scss";


.background {
  height: auto;
  width: 100%;  
  padding: 0px;
 
  .desktopView{
    display:none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
    height: 580px;
  }
  .mobileView{
    height: auto;
    width: 100%;
    position: relative;
    font-family: 'OpenSans-LightItalic', 'Open Sans Light Italic', 'Open Sans';
    font-weight: lighter;
    font-style: italic;
    font-size: 17px;
    color: #373737;
    .padding{
     padding: 15px 15px 15px 15px;
     i{
       color:#FF5E0F;
     }
    }
    p{
      margin-bottom: 0px !important;
    }
    .bolder{
      font-weight: 600;
    }
    .greenText{
      font-family: 'OpenSans-Regular', 'Open Sans';
      font-weight: 400;
      font-style: normal;
      font-size:  24px;
      color: #19A64A;
    }
    .mobNavtext{
      position: relative;
      border-color: #e4e4e4;
      border-width: 2px 0px 0px 0px;
      border-style: solid;
      padding: 0px;
      .numbers{
        position: absolute;
        top: 11px;
        padding-left: 15px;
        font-weight: 700;
        font-style: normal;
        font-size: 24px;
        color: #FF5E0F;
      }
    .number2Content{
      margin-left: 50px;
      display: inline-flex;
      padding: 16px 10px;
      color: black !important;
      text-decoration: none;
      font-family: 'OpenSans-SemiBold', 'Open Sans SemiBold', 'Open Sans';
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
    }
    i{
      float: right;
      padding: 10px 20px 0px 0px;
      font-size: 32px;
    }
    .greenPadding{      
      padding-bottom: 30px;
      border-style: solid;
      border-color: #34A350;
      border-width: 0px 0px 4px 0px;
    }

  }
  }

  @include media-breakpoint-down(sm) {
    background-image: none !important;
  }

  @include media-breakpoint-up(xl) { 
      .gif{
        padding-left: 255px !important;
        }
        .arrowUp{
          left: 310px !important;
        }
        .appGoogle{
          left: 795px !important;
        }
        .appStore{
          left: 960px !important;
        }
  }

  @include media-breakpoint-up(lg) { 
    height: 640px;
    .mobileView{
      display: none;
    }
    .desktopView{
      display: block;
      .display{
        display: none;
      }
      .modal{
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color:#00000091;
        z-index: 1001;
      }
      .modalBox{
        background-color: white;
        height: 340px;
        width: 721px;
        margin: 16% auto;
        z-index: 1003;
        .modalHeading{
          font-size: 30px;
          color: #35A34E;          
          padding: 5px 15px;
          i{
            float: right;
            color: black;
            cursor: pointer;
          }
        }
        .modalContent{
          color: #666666;
          padding: 5px 15px;
        }
        .closeButton{
          width: 240px;
          height: 40px;
          font-family: 'OpenSans-SemiBold', 'Open Sans SemiBold', 'Open Sans';
          font-weight: 650;
          font-style: normal;
          font-size: 18px;
          background-color: rgba(255, 90, 10, 1);
          color: #FBFBFB;
          text-align: center;
          padding: 6px;
          margin-left: 15px;
          cursor: pointer;
        }
      }
      .gif{
        padding-top: 62px;
        padding-left: 90px;
        height: 550px;
        width: 80%;
        .gifImg{
          width: 248px;
          height: 545px;
        }
        .orangeIcon{
            color:#FF5E0F;
        }
        .text1{
          position: relative;
          bottom: 515px;
          left: 310px;
          font-family: 'OpenSans-LightItalic', 'Open Sans Light Italic', 'Open Sans';
          font-weight: lighter;
          font-style: italic;
          font-size: 22px;
          color: #373737;
          .bolder{
            font-weight: 600;
          }
          .greenText{
            font-family: 'OpenSans-Regular', 'Open Sans';
            font-weight: 400;
            font-style: normal;
            font-size: 30px;
            color: #19A64A;
          }
          
          }
        }
        .thirdMargin{
          margin-top: 90px !important;
        }
        .text2{
          margin-top: 10px;
          position: relative;
          bottom: 515px;
          left: 310px;
          .numbers{
            position: absolute;
            top: 15px;
            font-weight: 700;
            font-style: normal;
            font-size: 28px;
            color: #FF5E0F;
          }
          .number1Content{
            margin-left: 50px;
            font-family: 'OpenSans-Regular', 'Open Sans';
            font-weight: 400;
            font-style: normal;
            font-size: 18px;
            color: #4F4F4F;
            p{
              margin-bottom: 0px;
            }
            .specialP{
              display: flex;
              font-family: 'OpenSans-Regular', 'Open Sans';
              font-weight: 400;
              font-style: normal;
              font-size: 14px;
              text-decoration: underline;
              color: #E17440;
              cursor: pointer;
            }
        }
        .number2Content{
          margin-left: 50px;
          display: inline-flex;
          border: 2px solid #E4E4E4;
          padding: 16px 10px;
          width: 290px;
          height: 63px;      
          color: black !important;
          text-decoration: none;
          border-radius: 10px;
          position: absolute;
          top: 7px;
          font-family: 'OpenSans-Bold', 'Open Sans Bold', 'Open Sans';
          font-weight: 700;
          font-style: normal;
          i{
            position: absolute;
            left: 255px;
            bottom: 15px;
          }
        }
        .number2Content:hover{      
          text-decoration: none;
        }
    
      }
      .footer{
        position: absolute;
        bottom: inherit;
        width: 100%;
        height: 93px;
        background-color: #717171;
    
        .arrowUp{
          position: absolute;
          left: 210px;
          top: 34px;
          width: 475px;
          height: 24px;
          i{
            color: white;
            font-size: 30px;
          }
          .arrowText{
            position: absolute;
            display: inline;
            font-style: inherit;
            font-size: large;
            font-weight: 500;
            margin-left: 10px;
            color: white;
          }
          }
        .appStore{
          border-width: 0px;
          display: inline;
          left: 700px;
          top: 25px;
          position: absolute;      
            .appStoreImg{
              width: 149px;
              height: 44px;
            }
        }
        .appGoogle{
          border-width: 0px;
          display: inline;
          left: 860px;
          top: 25px;
          position: absolute;      
            .appGoogleImg{
              width: 149px;
              height: 44px;
            }
        }
      }
    }
  }
  @include media-breakpoint-only(md) {
    height: 575px;
    .mobileView{
      display: none;
    }
    .desktopView{
      background-size: 1200px;
      display: block;
      .display{
        display: none;
      }
      .modal{
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background-color:#00000091;
        z-index: 1001;
      }
      .modalBox{
        background-color: white;
        height: 340px;
        width: 721px;
        margin: 16% auto;
        z-index: 1003;
        .modalHeading{
          font-size: 30px;
          color: #35A34E;          
          padding: 5px 15px;
          i{
            float: right;
            color: black;
            cursor: pointer;
          }
        }
        .modalContent{
          color: #666666;
          padding: 5px 15px;
        }
        .closeButton{
          width: 240px;
          height: 40px;
          font-family: 'OpenSans-SemiBold', 'Open Sans SemiBold', 'Open Sans';
          font-weight: 650;
          font-style: normal;
          font-size: 18px;
          background-color: rgba(255, 90, 10, 1);
          color: #FBFBFB;
          text-align: center;
          padding: 6px;
          margin-left: 15px;
          cursor: pointer;
        }
      }
      .gif{
        padding-top: 62px;
        padding-left: 90px;
        height: 490px;
        width: 100%;
        .gifImg{
          display: none;
        }
        .orangeIcon{
            color:#FF5E0F;
        }
        .text1{
          bottom: 515px;
          left: 310px;
          font-family: 'OpenSans-LightItalic', 'Open Sans Light Italic', 'Open Sans';
          font-weight: lighter;
          font-style: italic;
          font-size: 22px;
          color: #373737;
          .bolder{
            font-weight: 600;
          }
          .greenText{
            font-family: 'OpenSans-Regular', 'Open Sans';
            font-weight: 400;
            font-style: normal;
            font-size: 30px;
            color: #19A64A;
          }
          
          }
        }
        .thirdMargin{
          margin-top: 90px !important;
        }
        .text2{
          margin-top: 10px;
          .numbers{
            position: absolute;
            font-weight: 700;
            margin-top: 10px;
            font-style: normal;
            font-size: 28px;
            color: #FF5E0F;
          }
          .number1Content{
            margin-left: 50px;
            font-family: 'OpenSans-Regular', 'Open Sans';
            font-weight: 400;
            font-style: normal;
            font-size: 18px;
            color: #4F4F4F;
            p{
              margin-bottom: 0px;
            }
            .specialP{
              display: flex;
              font-family: 'OpenSans-Regular', 'Open Sans';
              font-weight: 400;
              font-style: normal;
              font-size: 14px;
              text-decoration: underline;
              color: #E17440;
              cursor: pointer;
            }
        }
        .number2Content{
          margin-left: 50px;
          display: inline-flex;
          border: 2px solid #E4E4E4;
          padding: 16px 10px;
          width: 290px;
          height: 63px;      
          color: black !important;
          text-decoration: none;
          border-radius: 10px;
          position: absolute;
          font-family: 'OpenSans-Bold', 'Open Sans Bold', 'Open Sans';
          font-weight: 700;
          font-style: normal;
          i{
            position: absolute;
            left: 255px;
            bottom: 15px;
          }
        }
        .number2Content:hover{      
          text-decoration: none;
        }
    
      }
      .footer{
        position: absolute;
        bottom: inherit;
        width: 100%;
        height: 93px;
        background-color: #717171;
    
        .arrowUp{
          position: absolute;
          left: 1%;
          top: 34px;
          width: 475px;
          height: 24px;
          i{
            color: white;
            font-size: 30px;
          }
          .arrowText{
            position: absolute;
            display: inline;
            font-style: inherit;
            font-size: large;
            font-weight: 500;
            margin-left: 10px;
            color: white;
          }
          }
        .appStore{
          border-width: 0px;
          display: inline;
          left: 482px;
          top: 25px;
          position: absolute;      
            .appStoreImg{
              width: 130px;
              height: 44px;
            }
        }
        .appGoogle{
          border-width: 0px;
          display: inline;
          left: 630px;
          top: 25px;
          position: absolute;      
            .appGoogleImg{
              width: 130px;
              height: 44px;
            }
        }
      
      
      
      
      }
    }
  }
}
