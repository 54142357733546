.cancel-confirm {
  margin-top: 20px;
}
.cancel-confirm a {
  text-decoration: none;
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .cancel-confirm .title-container {
    margin-bottom: 15px;
  }
}
.cancel-confirm .content-wrapper {
  padding: 22px 20px;
  border: 2px solid #E4E4E4;
  height: 100%;
  border-radius: 10px;
  overflow-wrap: break-word;
}
.cancel-confirm .content-wrapper span {
  font-weight: 600;
}
.cancel-confirm .content-wrapper .sub-heading {
  font-weight: 400;
  color: #657493;
  display: block;
  font-size: 12px;
  text-transform: uppercase;
}
.cancel-confirm .terms-and-condition strong {
  color: #28373a;
}
.cancel-confirm .terms-and-condition .bg-color {
  background-color: #fff;
  font-size: 12px;
}
@media (min-width: 768px) {
  .cancel-confirm .underline {
    text-decoration: underline;
  }
}
@media (min-width: 992px) {
  .cancel-confirm {
    margin-top: 0;
  }
}
.cancel-confirm .button-container {
  width: 100%;
}
@media (min-width: 768px) {
  .cancel-confirm .button-container {
    width: 300px;
  }
}

