@import "./../../../styles/_variables";
.error-border {
    box-sizing: border-box;
    border-width: 2px;
    border-style: solid;
    border-color: $danger;
    border-radius: 3px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.icon-color {
    background-color: $danger;
    width: 48px;
    padding: 12px;
    height:100%;
}

.error-icon {
    font-size: 25px;
    color: #FFFFFF;
}

.error-text {
    font-size: 14px;
    color: $danger;
    padding: 12px;

    a {
        font-size: 14px;
        font-weight: bold;
        color: $danger !important;
        text-decoration: none;

        &:hover {
            color: $danger !important;
        }
    }
}