@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";

.new-address-container {
    display: block;

    @include media-breakpoint-up(lg) {
        min-height: 60vh;
    }

    #changeAddress-zipCode {
        .note-view {
            width: 100%;

            @include media-breakpoint-up(md) {
              width: 470px;
            }
        }
    }

    .note-icon-size {
        font-size: 24px;
    }

    .title-container {
        
        padding: 15px 0;
        color:$header-Color;

        @include media-breakpoint-up(md) {
            height: 79px !important;
            padding-top: 0px;
            padding-bottom: 0px;
        }

        @include media-breakpoint-up(lg) {
            height: 97px !important;
            padding-top: 0px;
            padding-bottom: 0px;
        }
    }

    .address-container {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 470px;
        }
    }

    .button-container {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 220px;
        }
    }

    .check-container {
        width: 100%;
    }
}


.h1_v2{
    font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
    color: #28373a !important;
    line-height: normal;
    font-size: 21px;
    font-weight: 400;
    margin-bottom: 0;
  }
  
  .next-arrow-circle-right-custom {
    margin-left: 5px;
    padding-bottom: 1px;
}

.mobileTextV2{
font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
color: #657493 !important;
font-size: 14px;
}