.update-profile-container {
  display: block;
  min-height: 60vh;
}
.update-profile-container .title-container {
  height: 50px;
  padding: 15px 0;
}
@media (min-width: 768px) {
  .update-profile-container .title-container {
    height: 79px !important;
  }
}
@media (min-width: 992px) {
  .update-profile-container .title-container {
    height: 97px !important;
  }
}
.update-profile-container .input-container {
  width: 100%;
}
@media (min-width: 768px) {
  .update-profile-container .input-container {
    width: 470px;
    max-width: 470px;
  }
}
.update-profile-container .button-link_cancel {
  margin-top: 5px;
}
.update-profile-container .phone-number-container {
  width: 100%;
}
@media (min-width: 768px) {
  .update-profile-container .phone-number-container {
    width: 200px;
  }
}
.update-profile-container .margin-bottom {
  margin-bottom: 12px !important;
}
@media (min-width: 768px) {
  .update-profile-container .margin-bottom {
    margin-bottom: 14px !important;
  }
}
.update-profile-container .second-parent-title {
  margin-top: 30px;
}
.update-profile-container .sub_title_1 {
  font-weight: 700;
  font-size: 16px;
  color: #28373a;
}
@media (min-width: 768px) {
  .update-profile-container .sub_title_1 {
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .update-profile-container .sub_title_1 {
    font-size: 24px;
  }
}
.update-profile-container .sub_title_2 {
  font-weight: 400;
  color: #333333;
  font-size: 14px;
  margin-bottom: 5px;
  padding-left: 5px;
}

.uneditable-input {
  background-color: rgb(242, 242, 242);
}

.uneditable-input-wrapper {
  padding-top: 0px;
  padding-bottom: 5px;
  padding-left: 10px;
  margin-bottom: 15px;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(141, 141, 141);
  border-radius: 0px;
}

.uneditable-label {
  padding: 0px;
  font-family: "OpenSans-Regular", "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  color: #000000;
  margin: 0px;
}

.uneditable-field {
  padding: 0px;
  font-size: 20px;
  margin: 0px;
  margin-top: -5px;
}

.cursor-pointer {
  cursor: pointer !important;
}

