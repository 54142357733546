@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.billing-info {
    background: #f5fafb;
    border: 2px solid $gray-3;
    border-radius: 10px;
        // margin: 30px;

    .info-header {
        color: $header-Color !important;
        padding: 15px;
        font-size: 24px;
        font-weight: 400;
        color: $header-Color !important;
    }

    .info-body {
        background: #ffffff;
        padding: 20px 15px;
        overflow-wrap: break-word;

        .subtitle {
            color: $gray-4;
            font-weight: 400;
            font-size: 12px;
            text-transform: uppercase;
        }
        .text-primary {
            color : $home-button !important;
        }
        span {
            color: #333333;
            font-weight: 600;
        }
        button {
            color: $text-Blue !important;
            border: none;
            background: none;
            font-weight: 650;
        }
        
        button:hover {
            text-decoration: underline;
        }
        i {
            font-style: normal;
            font-style: italic;
            font-weight: 200;
        }
    }

    .info-footer {
        padding: 15px;
    }
}