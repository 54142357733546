@import "./../../../styles/_variables.scss";
#header-group {
  background-color: #f0f0ee;

  // Drop down menu items if Desktop header
  ul {
    list-style-type: none;
    padding: 10px 10px 5px 15px;
  }
  li {
    padding: 2px;
  }
  a {
    color: black !important;
    text-decoration: none !important;
    font-size: 16px;
  }

  #header-top {
    background-color: #f0f0ee;
    min-height: 126px;

    #logo {
      border-width: 0px;
      width: 235px;
      height: 60px;
    }
  }

  
  .menu-item, .menu-item a{
    font-size:15px;
    color:#657493 !important;
    

    #file-claim-menu{
      background-color: transparent;
      border:2px solid $text-Blue;
      color:$text-Blue;
      border-radius:40px;
    }
  }
}

#welcome-container {
  padding-left: 35px;
  font-size: 15px;
  font-weight: 400;
  flex-grow: 1;
}

#header-right-container {
  
  display: flex;
  justify-content: space-evenly;

}

.headertop-image-group {
  padding: 0 8px;
  align-self: center;
  cursor: pointer;
  text-align: center;
  position:relative;

  .headertop-image-item {
    height: 24px;
    margin-right: 12px;
    cursor: pointer;
  }

  .headertop-image-text {
    font-weight: 600;
    align-self: center;
    padding-left: 0px;
    font-size: 12px;
  }
}

#file-claim-menu {
  height: 40px;
  font-weight: 800;
  margin-top: 10px;
  justify-content: center;
}
#file-claim-menu span {
  margin: auto;
}
