.view-claims-info .header {
  padding: 22px 20px 15px 20px;
  color: #28373a !important;
}
.view-claims-info .subtitle {
  font-weight: 700;
  padding: 0 20px;
}
.view-claims-info .no-claims {
  background-color: #f2f2f2;
  padding: 5px;
  height: 200px;
  font-size: 14px;
}
.view-claims-info .body {
  position: relative;
}
.view-claims-info .body .claims-table {
  margin-top: 10px;
}
.view-claims-info .body .claims-table a {
  font-size: 14px;
  text-decoration: none !important;
  color: #000000 !important;
}
.view-claims-info .body .claims-table a:hover {
  color: #000000 !important;
  text-decoration: none !important;
}
.view-claims-info .body .claims-table .claims-striped-table .claim-item {
  padding: 10px 0;
}
.view-claims-info .body .claims-table .claims-striped-table .stripes:last-child {
  border-bottom: none;
}
.view-claims-info .body .claims-table .claims-striped-table .stripes:nth-child(odd) .claim-item {
  background-color: #f5fafb;
}
.view-claims-info .body .claims-table .claims-striped-table .stripes:nth-child(even) .claim-item {
  background-color: #ffffff;
}
.view-claims-info .footer {
  padding: 20px;
  text-align: center;
}
.view-claims-info .footer a {
  text-decoration: none;
}
.view-claims-info .footer a:hover {
  text-decoration: underline;
}

