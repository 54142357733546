.success-container .successHeader {
  color: #657493 !important;
  margin-bottom: 0;
  font-size: 12px !important;
  text-transform: uppercase;
  font-weight: 400;
}
.success-container .keep-in-mind {
  font-size: 13px !important;
  font-weight: 600;
  color: #28373a !important;
  text-transform: none;
}
.success-container .margin-bottom {
  margin-bottom: 20px;
}
.success-container span {
  font-size: 14px;
  font-weight: 600;
}
@media (min-width: 768px) {
  .success-container span {
    font-size: 16px;
  }
}
.success-container span.keep-in-mind {
  font-weight: 400;
}

