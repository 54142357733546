.faq-container .grayBorder {
  border-top: solid 1px #d7d7d7;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #d7d7d7;
}
@media (min-width: 768px) {
  .faq-container .ReimbursementCalculationTable {
    margin-left: 25px;
  }
}
@media (max-width: 991.98px) {
  .faq-container .ReimbursementCalculationTable {
    margin-left: 5px;
  }
}
.faq-container .title {
  height: 45px;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .faq-container .title {
    height: 79px;
    margin-top: 0px;
  }
}
@media (min-width: 992px) {
  .faq-container .title {
    height: 97px;
    margin-top: 0px;
  }
}
.faq-container .billing-info-margin {
  margin-top: 30px;
  margin-bottom: 30px;
}
.faq-container .container-view {
  z-index: 0;
}
.faq-container .container-view .search-text {
  color: #28373a;
  font-weight: 400;
}
.faq-container .container-view .margin-left-right {
  margin-left: 5px;
  margin-right: 5px;
}
.faq-container .container-view .margin-bottom {
  margin-bottom: 15px;
  display: inline-block;
  color: #28373a;
}
.faq-container .container-view .wellHeight {
  height: 4500px;
}
.faq-container .container-view .faq-title {
  height: 32px;
  padding-left: 5px;
  height: auto;
  margin-bottom: 5px;
  margin-top: 20px;
  color: #28373a;
  font-weight: 700;
}
.faq-container .container-view .faq-search-title {
  margin-bottom: 15px;
}
.faq-container .container-view .faqQuestion {
  height: auto;
  color: #6b61f2;
  cursor: pointer;
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 15px;
  font-weight: 600;
}
.faq-container .container-view .faqNoMargin {
  margin: 0px;
}
.faq-container .container-view .faq-search-question {
  height: auto;
  color: #6b61f2;
  cursor: pointer;
  margin-bottom: 0px;
  font-weight: 600;
}
.faq-container .container-view .faqAnswer {
  display: none;
  line-height: 1.5em;
  display: block;
  margin-left: 30px;
}
.faq-container .container-view .font-italic {
  font-style: italic;
}
.faq-container .container-view .font-bold {
  font-family: "OpenSans-Bold", "Open Sans Bold", "Open Sans";
  font-weight: 700;
}
.faq-container .container-view .font-600 {
  font-weight: 600;
}
.faq-container .container-view ul.blackdisc {
  list-style-type: disc;
}
.faq-container .container-view .no-padding {
  padding: 0;
}
.faq-container .container-view #search-input-wrapper {
  padding: 15px;
  background-color: #f2f2f2;
  height: auto;
  width: 100%;
}
.faq-container .container-view .suggestion-input {
  position: relative;
  height: 50px;
  width: 100%;
  padding-left: 25px;
  padding-right: 35px;
  box-sizing: border-box;
  border: 1px solid #8D8D8D;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
}
.faq-container .container-view .suggestion-icon-wrapper {
  height: auto;
  width: 100%;
}
.faq-container .container-view .suggestion-icon {
  position: relative;
  float: right;
  width: 17px;
  height: 17px;
  top: 0px;
  font-size: 17px;
  color: #999999;
  margin-top: -88px;
  margin-right: 0px;
  z-index: 100;
}
@media (min-width: 768px) {
  .faq-container .container-view .suggestion-icon {
    top: 40px;
    right: 35px;
  }
}
.faq-container .container-view .suggestion-icon-mobile {
  position: relative;
  float: right;
  width: 17px;
  height: 17px;
  top: 88px;
  font-size: 17px;
  color: #999999;
  margin-top: -135px;
  margin-right: 40px;
  z-index: 100;
}
@media (min-width: 768px) {
  .faq-container .container-view .suggestion-icon-mobile {
    top: 40px;
    right: 30px;
  }
}
.faq-container .container-view .suggestion-input:focus-within {
  border: 2px solid #0070C9;
  outline-offset: 0px;
  outline: none;
}
.faq-container .container-view .suggestion-mobile-container {
  width: 100%;
  height: 85px;
  background: #F2F2F2;
  padding: 0px;
  border-radius: 8px 8px 0px 0px;
  border-width: 3px 3px 3px 3px;
  border-style: solid;
  border-color: rgb(228, 228, 228);
}
.faq-container .container-view .suggestion-answer-container {
  background-color: white;
  width: auto;
  height: auto;
  max-height: 30vh;
  z-index: 1;
  padding: 15px 15px 0 15px;
  overflow-x: hidden;
  overflow-y: scroll;
  border-width: 0px 3px 3px 3px;
  border-style: solid;
  border-color: rgb(228, 228, 228);
}
@media (min-width: 768px) {
  .faq-container .container-view .suggestion-answer-container {
    position: absolute;
    border-width: 0px 2px 2px 2px;
    right: 15px;
    left: 15px;
  }
}
.faq-container .container-view .suggestion-answer {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 10px;
}
.faq-container .container-view .search-result-element {
  margin-bottom: 25px;
}
.faq-container .container-view .search-result-element a {
  cursor: pointer;
}
.faq-container .col-refer-friend {
  margin-bottom: 30px;
}
.faq-container .rightColumn a {
  text-decoration: none;
}
.faq-container #preexisting-cruciate-examples-container {
  display: none;
}
.faq-container .grayBorderLine {
  border-top: solid 1px #d7d7d7;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: solid 1px #d7d7d7;
}
.faq-container a {
  text-decoration: underline !important;
}
.faq-container .preExistingExample, .faq-container .preExistingExample:hover {
  text-decoration: none !important;
}
.faq-container .preExistingAnswerExampleContainer2 {
  max-height: 0;
}
.faq-container .preExistingAnswerExampleContainer2.expanded {
  max-height: max-content;
  transition: max-height 0.15s ease-out;
  display: block !important;
}

