.justify-content-between .heading {
  color: #657493 !important;
  font-weight: 400 !important;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 6px;
}
.justify-content-between span {
  color: #333333;
  font-weight: 600;
}
.justify-content-between .text-secondary {
  color: #6b61f2 !important;
  font-weight: 650;
  text-transform: none;
}
.justify-content-between .btn-outline-secondary {
  color: #038062;
  border-color: #00c885;
}
.justify-content-between .smallText {
  color: #657493 !important;
  font-weight: 400 !important;
  font-size: 12px;
  text-transform: uppercase;
  font-style: normal;
}
.justify-content-between .consent-text {
  text-transform: none !important;
}
.justify-content-between .consent-text a {
  text-decoration: underline !important;
}

