@import "./../../../styles/_variables";
@import "./../../../styles/_mixins";

#footer-group {
  background-color: #717171;
  color: white;
  padding: 10px 2px;
  font-size: 14px;

  @include media-breakpoint-up(md) {
    padding-top: 25px;
  }

  .social-icons {
    padding: 10px;
  }

  .social-icons-group {
    color: white;
  }

  .social-icon {
    height: 38px;
    width: 38px;
    margin: 5px;
    cursor: pointer;
  }

  .address-social-icons-wrapper{
    @include media-breakpoint-only(lg) {
      padding-left:0px;
      padding-right:0px;
    }
  }

  .address {
    @include media-breakpoint-only(lg) {
      padding-left:0px;
    }
    font-weight: 400;
    font-size: 14px;
    white-space: nowrap;
    padding-right: 0px;
  }

  .address-row {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .address-row :last-child {
    width: fit-content;
  }

  .address:first-child :last-child {
    margin-bottom: 20px;
  }

  .footer-info, .footer-info a {
    margin-top: 20px;
    font-size: 10px;
    color:white !important;
  }

  .footer-image {
    margin: 10px;
  }

  ul {
    list-style: none;
    padding: 0px;
  }

  ul > li > a {
    color: white !important;
    font-size: 14px;
    white-space: nowrap;
    text-decoration: none !important;
    cursor: pointer;
  }

  a:hover {
    text-decoration: underline !important;
  }

  ul li:first-child > a {
    font-weight: 700;
  }

  ul li {
    padding: 2px;
  }

  &.has-banner {
    padding: 10px 0 35px 0;
  }
}

@include media-breakpoint-up(md) {
  #footer-group {
    font-size: 12px;

    .footer-info {
      font-size: 10px;
    }

    ul > li > a {
      font-size: 12px;
    }

    .address {
      font-size: 12px;
    }

    #footer-connected-icons {
      padding-right: 15px;
      padding-left: 0px;
    }

    .mobile-hide {
      display: inline;
    }
    .mobile-show {
      display: none;
    }
  }
}

@include media-breakpoint-up(xs) {
  .mobile-hide {
    display: none;
  }
  .mobile-show {
    display: inline;
  }
}

.USFooterLink {
    text-decoration: underline !important;
    cursor: pointer;
    font-size:14px;
}