.avatar-editor .avatar-dropzone {
  position: static !important;
}
.avatar-editor .active-avatar-dropzone .avatar-container {
  border: 4px solid #34A350;
}
.avatar-editor .avatar-container {
  height: 133px;
  width: 133px;
  background-color: rgb(233, 230, 230);
  color: grey;
  border-radius: 133px;
  border: 2px solid #34A350;
  overflow: hidden;
  align-self: center;
}
.avatar-editor .avatar-container img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: inherit;
}
.avatar-editor .avatar-container.no-image {
  cursor: pointer;
  padding: 0;
}
.avatar-editor .edit-container {
  height: 25px;
  width: 25px;
  border-radius: 12px;
  background-color: #34A350;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 4px;
  right: 10px;
}
.avatar-editor .edit-container i {
  cursor: pointer;
}

@media (min-width: 768px) {
  .avatar-editor .active-avatar-dropzone .avatar-container {
    border: 6px solid #34A350;
  }
  .avatar-editor .avatar-container {
    height: 191px;
    width: 191px;
    border: 4px solid #34A350;
  }
  .avatar-editor .edit-container {
    height: 35px;
    width: 35px;
    right: 20px;
  }
  .avatar-editor .edit-container i {
    font-size: 24px;
  }
}
.photo-editor-modal {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  max-width: 700px;
}
.photo-editor-modal .loader-container {
  z-index: 3;
}
.photo-editor-modal .modal-content {
  margin: 0;
  border: none;
  border-radius: 0;
  width: 100%;
  height: inherit;
}
.photo-editor-modal .modal-content .modal-header {
  padding: 4px 16px;
  border: 0;
}
.photo-editor-modal .modal-content .modal-header .close {
  padding: 0;
  margin: 0;
  z-index: 2;
}
.photo-editor-modal .modal-content .modal-header .modal-title {
  width: 100%;
  text-align: center;
}
.photo-editor-modal .modal-content .modal-body {
  padding: 0;
}
.photo-editor-modal .modal-content .modal-body .grow {
  padding: 10px 0;
}
.photo-editor-modal .modal-content .modal-body .title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 10px;
}
.photo-editor-modal .modal-content .modal-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767.98px) {
  .photo-editor-modal {
    max-width: 100%;
    height: 100%;
    margin: 0;
    min-height: calc(100vh - 20px);
  }
  .photo-editor-modal .modal-content {
    width: 100%;
    height: 100%;
  }
}
@media only screen and (max-device-width: 767px) and (orientation: portrait) {
  .photo-editor-modal .modal-body {
    display: flex;
    flex-direction: column;
  }
  .photo-editor-modal .modal-body > div {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .photo-editor-modal .modal-body > div .grow {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .photo-editor-modal .modal-popup {
    display: block;
  }
}

