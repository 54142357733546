.text-color-violet1 {
  color: #6B61F2 !important;
}

.text-color-solidgreen3 {
  color: #038062 !important;
}

.file-claim .text-primary {
  margin: 15px 0px;
}

.text-primary-heading {
  color: #28373a !important;
}

.claims {
  padding-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-size: 14px;
}
.claims .section-check-status {
  padding-right: 5px;
  display: inline-block;
}
.claims .upload-file-edit {
  color: #FF5B0B;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}
.claims .problem-upload-toggler {
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 10px;
  cursor: pointer;
  color: #6b61f2 !important;
}
@media (min-width: 768px) {
  .claims {
    font-size: 16px;
  }
}
.claims .claim-collapse-box {
  border: 2px solid #E4E4E4;
  border-radius: 10px;
  margin-bottom: 15px;
}
.claims .claim-collapse-box .collapse-box-item {
  padding: 10px;
  border-bottom: 2px solid #E4E4E4;
  background: #ffffff;
}
.claims .claim-collapse-box .collapse-box-item:last-of-type {
  border-bottom: none !important;
}
.claims .claim-collapse-box .selected {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #FFFFFF;
}
.claims .claim-collapse-box .disable {
  color: #CCCCCC;
  pointer-events: none;
}
.claims .claim-collapse-box .disable i {
  display: none;
}
.claims .claim-collapse-box .cancel-btn {
  color: #6b61f2 !important;
}
@media (min-width: 576px) {
  .claims .claim-collapse-box .claimform-submit-box {
    margin-left: 14px;
    margin-right: 14px;
  }
}
.claims .claim-collapse-box .box-title {
  font-weight: 700;
}
@media (min-width: 576px) {
  .claims .claim-collapse-box .box-title {
    margin-left: 14px;
    margin-top: 10px;
  }
}
.claims .claim-collapse-box .box-title span {
  font-weight: normal;
}
.claims .claim-collapse-box .box-title i {
  font-size: 22px !important;
}
.claims .claim-collapse-box textarea {
  font-size: 12px;
  font-weight: normal;
  width: 100%;
  margin: 10px 0;
  font-size: 10px;
  height: 90px;
}
@media (min-width: 768px) {
  .claims .claim-collapse-box textarea {
    font-size: 12px;
    height: 45px;
  }
}
.claims .claim-collapse-box .btn-secondary {
  font-weight: 700;
  padding: 12px;
  width: 100%;
  border-radius: 5px;
  border: none;
}
@media (min-width: 768px) {
  .claims .claim-collapse-box .btn-secondary {
    width: 65%;
  }
}
.claims .claim-collapse-box .btn-secondary:hover {
  background: #FF5B0B !important;
  color: #ffffff !important;
}
.claims .claim-collapse-box .disabled-btn {
  background: #dae3e8 !important;
  pointer-events: none;
}
.claims .claim-collapse-box .cancel-btn {
  font-weight: 700;
  width: 25%;
  text-align: center;
  text-decoration: underline;
  border: none;
  background: none;
}
.claims .faq-toggle {
  font-weight: 700;
}

.modal-dialog.message-modal {
  max-width: 700px !important;
}

@media (max-width: 767px) {
  .modal-dialog {
    top: 140px;
  }
}
@media (min-width: 768px) {
  .modal-dialog {
    top: 40px;
  }
}
.modal-header {
  border: none !important;
}
.modal-header .title-container {
  padding-top: 20px;
}
.modal-header .modal-title {
  font-size: 21px;
  font-weight: 400;
  line-height: none;
}
.modal-header button.close {
  padding: 0.5rem;
}
.modal-header .close span {
  font-size: 30px;
  font-weight: 700;
  color: #333333;
  font-family: Arial, Helvetica, sans-serif;
}
.modal-header span {
  font-size: 14px;
  font-weight: normal;
}
@media (min-width: 768px) {
  .modal-header .title-container {
    padding: 20px 0 0 20px;
  }
  .modal-header .modal-title {
    font-size: 30px;
  }
}

.modal-body {
  border: none !important;
}
.modal-body .body-container {
  padding: 0;
}
.modal-body .modal-buttons {
  margin-top: 20px;
}
.modal-body .modal-buttons .btn-secondary {
  font-weight: 700;
  font-size: 14px;
  padding: 10px;
  width: 60%;
  border-radius: 5px;
  border: none;
}
.modal-body .modal-buttons .btn-secondary:hover {
  background-color: #4aa574 !important;
  color: #ffffff !important;
}
.modal-body .modal-buttons .btn-skip {
  border: none;
  width: 40%;
  text-align: center;
  color: #4aa574;
  background: none;
  font-weight: 700;
  text-align: center;
}
.modal-body .modal-buttons .btn-skip:hover {
  cursor: pointer;
  text-decoration: underline !important;
}
@media (min-width: 768px) {
  .modal-body .body-container {
    padding: 0 20px;
  }
  .modal-body .pet-joined-date {
    width: 65%;
  }
  .modal-body .btn-secondary {
    font-size: 16px;
    width: 40% !important;
  }
  .modal-body .btn-skip {
    width: 30% !important;
  }
}

.modal-footer {
  border: none !important;
}

