.photo-control {
  background-color: #fff;
}
.photo-control .btn-secondary.photo-control-btn {
  width: 136px !important;
}
.photo-control .photo-stepper-indicators {
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin: 0 10px;
  list-style: none;
}
.photo-control .photo-stepper-indicators .step-indicator {
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  margin-left: 3px;
  background-color: #CCCCCC;
  border-radius: 50%;
}
.photo-control .photo-stepper-indicators .step-indicator.active {
  background-color: #34A350;
}
.photo-control .control-group {
  padding: 25px;
}
.photo-control .outline-btn {
  margin: 15px;
  width: 120px;
  height: 40px;
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(121, 121, 121);
  border-radius: 5px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.photo-control .outline-btn i {
  margin-right: 5px;
}

