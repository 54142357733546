#file-upload-preview-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  text-align: center;
}
#file-upload-preview-overlay .zoom-view {
  margin: 25px;
  padding: 25px 10px;
  background-color: white;
  top: 100px;
  position: relative;
  width: 266px;
  height: 355px;
  margin: 0 auto;
}
@media (min-width: 576px) {
  #file-upload-preview-overlay .zoom-view {
    height: 667px;
    width: 500px;
  }
}
@media (min-width: 768px) {
  #file-upload-preview-overlay .zoom-view {
    height: 860px;
    width: 645px;
  }
}
#file-upload-preview-overlay .preview-arrow-button {
  height: 30px;
  width: 30px;
  align-self: center;
  color: black;
  flex-shrink: 0;
}
#file-upload-preview-overlay .delete-icon-zoom {
  width: 10px;
  height: 10px;
  float: right;
  transform: translate(-50%, -50%);
}
#file-upload-preview-overlay .image-number-zoom {
  font-size: 20px;
  font-weight: bold;
  color: white;
  background-color: #34A350;
  padding: 5px;
  margin-bottom: 0px;
  position: absolute;
  width: 30px;
  height: 40px;
}
#file-upload-preview-overlay .trash-icon-zoom {
  margin-top: 20px;
  width: 30px;
  height: 30px;
  float: right;
}
#file-upload-preview-overlay .zoomed-preview {
  border: 2px solid #34A350;
  height: 90%;
  width: 100%;
}
#file-upload-preview-overlay .btn-close {
  margin-top: 20px !important;
  width: 50% !important;
}
#file-upload-preview-overlay .preview-fallback {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
}
#file-upload-preview-overlay .download-icon {
  color: #FF5B0B;
}

