@import "./styles/_variables.scss";
@import "bootstrap/scss/bootstrap";

body {
  margin: 0;
  padding: 0 !important;
  font-family: "Open Sans", sans-serif !important;
  font-size: 14px;
}

h1 {
  color: $header-Color;
  font-size: 21px;
  font-weight: 400;
  margin-bottom: 0;
}

h2 {
  color: #19a64a;
  font-size: 20px;
}

h3 {
  color: #4f4f4f;
  font-size: 18px;
  font-weight: 600;
}

h4 {
  color: #4f4f4f;
  font-size: 14px;
  font-weight: 700;
}

// Links
a {
  font-family: "OpenSans", "Open Sans";
  font-weight: bold;
  font-style: normal;
  font-size: 14px;
  color: $text-Blue !important;
  text-decoration: none;

  &:hover,
  &:active {
    color: $text-Blue;
    text-decoration: underline;
    text-decoration: none !important;
  }
}


.extole-footer a {
 color:#fff !important;
}


input {
  &::-ms-clear,
  &::-ms-reveal {
    display: none;
  }
}

button {
  cursor: pointer;
}

.link-text {
  color: $text-Blue;
}

.link-text:hover {
  cursor: pointer;
  text-decoration: underline;
}

.truncate {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 200px;
  display: inline-block;
  vertical-align: bottom;
}

.display-center {
    display: block;
    margin: 0 auto;
}

// For Tablet
@include media-breakpoint-up(md) {
    body {
        font-size: 16px;
    }

    h1 {
        font-size: 30px;
    }

    h2 {
        font-size: 24px;
    }

    h4 {
        font-size: 16px;
    }

    a {
        font-weight: 400;
        font-size: 16px;
        text-decoration: underline;
    }
}

// No padding for container fluid, - extend bars till edges
.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
}

.disable-user-edit {
  position: absolute;
  left: 0;
  right: 0;
  top:0;
  z-index: 5;
  bottom:0;background: rgba(255, 255, 255, 0.5);
}

// For Mobile
@include media-breakpoint-down(sm) {
  .sticky-bottom {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    position: sticky;
    bottom: 0;

    background-color: rgba(248, 248, 248, 0.9);
    border-top: 1px solid $secondary;
    box-shadow: 0 -4px 0px 0px rgba(194, 194, 194, 1);
    padding: 15px 30px;
  }

  .sticky-bottomv2 {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    position: sticky;
    bottom: 0;

    background-color: #ffffff;
    border-top: 1px solid #dae3e8;
    padding: 15px 30px;
  }
}

// For Mobile and Tablet, 100% width
@include media-breakpoint-down(md) {
  .container {
    max-width: 100% !important;
  }
}

// For Desktop
@include media-breakpoint-up(lg) {
  h1 {
    font-size: 36px;
    padding-top: 34px;
    padding-bottom: 34px;
  }
}

// For Large Desktop
@include media-breakpoint-up(xl) {
  .container {
    max-width: 1200px;
  }
}

// Button Config
%button-text-style {
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
}

%button-primary-containerV2 {
    border: none;
    border-width: 0px;
    width: 100%;
    background-color: $solidgreen;
    border-radius: 20px;
}

%button-primary-container {
  background-color: $button_Green;
  border: none;
  border-width: 0px;
  border-radius: 40px;
  width: 100%;
}

button:disabled {
  background-color: $gray-3;
  border-radius:40px;
}

.button-primaryv2-outlined {
    background-color: transparent !important;
    color: $solidgreen-3;
    border-color: $solidgreen-2;
    border-width:2px;
    border-style:solid;
}
.button-primaryV2 {
    @extend %button-text-style;
    @extend %button-primary-containerV2;
    height: 40px;

    @include media-breakpoint-up(md) {
        height: 55px;
    }
}

.button-primaryV2:disabled{
  background-color: #dae3e8;
  border-radius:40px;
}

.button-primaryV2 {
    @extend %button-text-style;
    @extend %button-primary-containerV2;
    height: 40px;

    @include media-breakpoint-up(md) {
        height: 55px;
    }
}

.button-primary {
  @extend %button-text-style;
  @extend %button-primary-container;
  height: 40px;

  @include media-breakpoint-up(md) {
    height: 55px;
  }
}
/*green plus button*/
.button-primary-plusV2 {
    @extend %button-text-style;
    @extend %button-primary-containerV2;
    height: 40px;
}
/*green plus button*/
.button-primary-plusV2 {
    @extend %button-text-style;
    @extend %button-primary-containerV2;
    height: 40px;
}

.button-primary-plus {
  @extend %button-text-style;
  @extend %button-primary-container;
  height: 40px;
}

%button-secondary {
  height: 40px;
  color: $home-button;
  background-color: #ffffff;
  width: 100%;
}

.button-secondary {
  @extend %button-text-style;
  @extend %button-secondary;
  border-width: 2px;
  border-style: solid;
  border-color: $box-border;
  border-radius: 40px;
    @include media-breakpoint-up(md) {
      height: 55px;
  }
}
.button-secondaryV2 {
  border:none;
  background-color: transparent;
  outline: none;
}
.button-secondaryV3 {
  border:1px solid $secondary;
  background-color: transparent;
  outline: none;
}

.button-link {
  @extend %button-text-style;
  @extend %button-secondary;
  border-width: 0px;
  font-size: 16px;
  height: 40px;

  @include media-breakpoint-up(md) {
    height: 55px;
    font-weight: 400;
  }
}

.button-link_cancel {
  @extend %button-text-style;
  @extend %button-secondary;
  border-width: 0px;
  font-size: 16px;
  height: 40px;
  color: $text-Blue;
}

.fit-image-into-container {
  object-fit: contain;
  font-family: "object-fit: contain;";
}

input[type="checkbox"].ios8-switch {
  position: absolute;
  margin: 8px 0 0 16px;
  display: none;
}

input[type="checkbox"].ios8-switch + label {
  position: relative;
  padding: 0px 0 0 50px; // line-height: 2.0em;
}

input[type="checkbox"].ios8-switch + label:before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 40px;
  /* x*5 */
  height: 24px;
  /* x*3 */
  border-radius: 16px;
  /* x*2 */
  background: #fff;
  border: 1px solid #d9d9d9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="checkbox"].ios8-switch + label:after {
  content: "";
  position: absolute;
  display: block;
  left: 0px;
  top: 0px;
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background: #fff;
  border: 1px solid #d9d9d9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}

input[type="checkbox"].ios8-switch + label:hover:after {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

input[type="checkbox"].ios8-switch:checked + label:after {
  margin-left: 16px;
}

input[type="checkbox"].ios8-switch:checked + label:before {
  background: #55d069;
}
