/* ==================================== Header CSS ====================================*/
.pet_detail_header {
  height: 55px;
}
@media (min-width: 768px) {
  .pet_detail_header {
    height: 80px;
  }
}
@media (min-width: 992px) {
  .pet_detail_header {
    height: 100px;
  }
}

.add_pet_button {
  width: 239px;
  height: 40px;
}

/* ==================================== PetDetail List Item CSS ====================================*/
.detail-title {
  color: #333333;
  margin: 0px;
  padding-right: 0px;
  padding-left: 0px;
  display: table-cell;
  vertical-align: middle;
  line-height: 1;
}

.detail-value {
  text-align: right;
  display: table-cell;
  vertical-align: middle;
  padding-right: 0px;
  padding-left: 0px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}
@media (min-width: 768px) {
  .detail-value {
    font-size: 16px;
    text-align: left;
  }
}

/* ==================================== Waiting Period CSS ====================================*/
.waiting_period {
  margin-top: 20px;
  margin-bottom: 15px;
  margin-right: 15px;
}

.waiting_period_text {
  color: #6b61f2;
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 700;
}
@media (min-width: 768px) {
  .waiting_period_text {
    font-size: 14px;
    font-weight: 600;
  }
}

.waiting_terms {
  font-weight: 400;
  color: #333333;
  font-size: 12px;
  line-height: normal;
}

