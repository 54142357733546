.password-conatiner {
  position: relative;
}

.show-password-icon {
  display: block;
  position: absolute;
  width: 25px;
  height: 25px;
  top: 15px;
  right: 10px;
}