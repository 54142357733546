.sign-in-box {
  background-color: white;
  margin: 0 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  border-radius: 6px;
  position: relative;
}
@media (min-width: 576px) {
  .sign-in-box {
    width: 381px;
  }
}
@media (min-width: 768px) {
  .sign-in-box {
    padding: 10px 20px 0 20px;
  }
}
.sign-in-box .success_logo {
  width: 75px;
  height: 75px;
}
.sign-in-box .loader-container {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  overflow: hidden;
  flex-wrap: wrap;
  background-color: black;
  opacity: 0.25;
  width: 100%;
}
@media (min-width: 576px) {
  .sign-in-box .loader-container {
    width: 381px;
  }
}
.sign-in-box .loader-image {
  width: 50px;
  height: 50px;
}
.sign-in-box .password-conatiner {
  position: relative;
  width: 100%;
}
.sign-in-box .title-green {
  font-size: 28px;
  color: #28373a;
  text-align: left;
}
@media (max-width: 767.98px) {
  .sign-in-box .title-green {
    font-size: 22px;
  }
}
.sign-in-box .title-green-center {
  text-align: center;
}
.sign-in-box .margin-bottom {
  margin-bottom: 10px;
}
.sign-in-box .title-description {
  font-weight: 400;
  font-size: 16px;
}
.sign-in-box .login-lock-icon {
  margin-right: 5px;
}
.sign-in-box .login-button-container {
  margin-bottom: 15px;
  width: 100%;
  background-color: #4aa574;
  border-radius: 30px;
  margin-top: 10px;
}
@media (max-width: 767.98px) {
  .sign-in-box .login-button-container {
    font-size: 16px;
  }
}
.sign-in-box .send-Password-container {
  margin-top: 15px;
  height: 40px !important;
}
.sign-in-box .back-to-signin-container {
  margin-top: 15px;
  height: 40px !important;
}
.sign-in-box .button-secondary:disabled {
  background: #ffffff;
}
.sign-in-box .back-to-signin-button {
  font-weight: 700;
  font-size: 18px;
  color: #6b61f2;
  text-align: center;
  cursor: pointer;
}
.sign-in-box .login-button-text {
  height: 23px;
}
.sign-in-box .remember-me-container {
  margin-bottom: 10px;
}
@media (min-width: 576px) {
  .sign-in-box .remember-me-container {
    margin-bottom: 0px;
  }
}
.sign-in-box .remember-me {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin-bottom: 0px;
}
.sign-in-box .forgot-password {
  font-weight: 700;
  height: 40px;
  font-size: 14px;
  color: #6b61f2;
  text-align: center;
  cursor: pointer;
}
@media (min-width: 576px) {
  .sign-in-box .forgot-password {
    line-height: 40px;
  }
}
.sign-in-box .create-password-container {
  margin: 10px 0;
}
@media (min-width: 576px) {
  .sign-in-box .create-password-container {
    margin: 20px 0;
  }
}
.sign-in-box .ruler {
  display: block;
  margin: 0px;
}
@media (min-width: 576px) {
  .sign-in-box .ruler {
    display: none;
  }
}
.sign-in-box .enrolled-by-phone {
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin-right: 4px;
}
.sign-in-box .create-password {
  font-weight: 700;
  color: #6b61f2;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.sign-in-box input[type=checkbox].ios8-switch {
  position: absolute;
  margin: 8px 0 0 16px;
  display: none;
}
.sign-in-box input[type=checkbox].ios8-switch + label {
  position: relative;
  padding: 0px 0 0 50px;
}
.sign-in-box input[type=checkbox].ios8-switch + label:before {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 40px;
  /* x*5 */
  height: 24px;
  /* x*3 */
  border-radius: 16px;
  /* x*2 */
  background: #fff;
  border: 1px solid #d9d9d9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.sign-in-box input[type=checkbox].ios8-switch + label:after {
  content: "";
  position: absolute;
  display: block;
  left: 0px;
  top: 0px;
  width: 24px;
  height: 24px;
  border-radius: 16px;
  background: #fff;
  border: 1px solid #d9d9d9;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.sign-in-box input[type=checkbox].ios8-switch + label:hover:after {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.sign-in-box input[type=checkbox].ios8-switch:checked + label:after {
  margin-left: 16px;
}
.sign-in-box input[type=checkbox].ios8-switch:checked + label:before {
  background: #55D069;
}
.sign-in-box .no-padding {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.sign-in-box .remember-me-forgot-password-continer {
  margin-right: 0px !important;
  margin-left: 0px !important;
}
.sign-in-box .remember-me-container {
  justify-content: center;
  align-items: center;
}
@media (min-width: 576px) {
  .sign-in-box .remember-me-container {
    justify-content: flex-start;
    margin-bottom: 0px;
  }
}

.password-sent-box {
  height: 377px;
}

