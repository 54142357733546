.change-policy-confirmation {
  padding: 0 15px;
}
.change-policy-confirmation .summary-content {
  border: 2px solid rgb(228, 228, 228);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 25px;
}
.change-policy-confirmation .summary-content .summary-list {
  padding-inline-start: 20px;
}
.change-policy-confirmation .summary-content .font-weight-bold-new {
  color: #657493;
  font-weight: 500;
}
.change-policy-confirmation .summary-content .new-summary-list {
  color: #333333;
  font-family: "ArialMT", "Arial", sans-serif;
  font-weight: 550;
}
.change-policy-confirmation .summary-content .keep-in-mind-content {
  font-size: 13px;
}
@media (min-width: 992px) {
  .change-policy-confirmation {
    padding-top: 0 !important;
  }
}

