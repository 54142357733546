.dropdown-container {
  height: 55px;
  width: 100%;
  background-color: transparent;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(166, 166, 166);
  border-image: initial;
  border-radius: 0px;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  text-decoration: none;
  color: #333333;
  text-indent: 5px;
}

.dropdown-body {
  background: #DDDDDD !important;
  opacity: 0.95;
}

.dropdown-item {
  padding-left: 12px !important;
}

.no-padding {
  padding: 0px;
  margin: 0px;
  background: #ffffff;
}

.top-padding {
  margin-top: -25px;
}

.top-padding-double {
  margin-top: -55px;
}

