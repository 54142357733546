@import "../../../styles/variables";
@import "../../../styles/mixins";

.justify-content-between {
    .heading  {
        color: $gray-4 !important;
        font-weight: 400 !important;
        font-size: 12px;
        text-transform: uppercase;
       margin-bottom:6px;
    }
    span {
        color: #333333;
        font-weight: 600;
    }
    .text-secondary {
        color: $text-Blue !important;
        font-weight: 650;
        text-transform: none;
    }
    .btn-outline-secondary {
        color: #038062;
        border-color: #00c885;
    }
    .smallText{
        color: $gray-4 !important;
        font-weight: 400 !important;
        font-size: 12px;
        text-transform: uppercase;
        font-style: normal;
    }
    .consent-text {
        text-transform: none !important;
        a {
            text-decoration: underline !important;
        }
    }
}