.homeBanner .main-content {
  background-position-x: 85%;
  background-size: contain;
  background-repeat: no-repeat;
  padding: 60px 0px;
}
.homeBanner .main-content .heading {
  font-size: 44px;
  color: #333;
  font-weight: normal;
}
.homeBanner .main-content .subHeading {
  font-size: 24px;
  color: #333;
  line-height: 50px;
}
.homeBanner .main-content .CTA {
  font-size: 18px;
  color: #fff !important;
  padding: 8px 50px;
  text-decoration: none;
  background-color: #ff5b0b;
  border-radius: 50px;
  display: block;
  width: max-content;
  font-weight: bold;
}
.homeBanner .main-content .CTA:hover {
  text-decoration: none !important;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .homeBanner .main-content {
    background-position-x: 100%;
  }
  .homeBanner .main-content .heading {
    font-size: 30px;
  }
  .homeBanner .main-content .subHeading {
    font-size: 18px;
    line-height: 26px;
  }
}
@media (max-width: 767.98px) {
  .homeBanner .main-content {
    padding-left: 20px;
    padding-right: 20px;
    background-image: none !important;
  }
  .homeBanner .main-content .heading {
    font-size: 30px;
  }
  .homeBanner .main-content .subHeading {
    font-size: 18px;
    line-height: 26px;
  }
  .homeBanner .main-content .CTA {
    font-size: 16px;
    width: 100%;
    text-align: center;
  }
  .homeBanner .main-content .AddVetClinicImg {
    width: 100%;
  }
}

