.success-view {
  min-height: 60vh;
}
.success-view .unBoldv2 {
  color: #657493 !important;
  font-weight: 100;
  padding-bottom: 4px;
}
.success-view .h1_v2 {
  font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
  color: #28373a !important;
  line-height: normal;
  font-size: 21px;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: -70px;
}
.success-view .mobileTextV2 {
  font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
  color: #657493 !important;
  font-size: 14px;
}
.success-view .content-wrapper {
  padding: 22px 20px;
  border: 2px solid #E4E4E4;
  border-radius: 10px;
  overflow-wrap: break-word;
}
.success-view .fs-12 {
  font-size: 12px;
}
@media (min-width: 768px) {
  .success-view .update-success {
    padding-bottom: 0;
    min-height: 60vh;
  }
}
@media (min-width: 992px) {
  .success-view .update-success {
    padding-bottom: 50px;
    min-height: 50vh;
  }
}
@media (min-width: 1200px) {
  .success-view .update-success {
    padding-bottom: 50px;
    min-height: 70vh;
  }
}
.success-view span.keep-in-mind {
  font-size: 13px;
  font-weight: 400;
}
.success-view h4.keep-in-mind {
  font-size: 13px;
  font-weight: 600;
  text-transform: unset;
}
.success-view .margin-bottom {
  margin-bottom: 20px;
}
.success-view h4 {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}
.success-view span, .success-view li {
  font-size: 14px;
  font-weight: 600;
}
@media (min-width: 768px) {
  .success-view span, .success-view li {
    font-size: 16px;
  }
}

