.close-button {
    width: 195px;
}

.image-conatiner {
    margin-top: 15px;
}

.verified-image {
    width: 88px;
    height: 69px;
    margin-right: 15px;
    object-fit: contain;
}

.accredited-image {
    width: 116px;
    height: 56px;
    object-fit: contain;
}

.cvv-1-image {
    width: 150px;
    height: 91px;
    object-fit: contain;
    margin-bottom: 18px;
}

.cvv-2-image {
    width: 159px;
    height: 92px;
    margin-right: 18px;
    object-fit: contain;
}