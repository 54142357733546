@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";

 .cancel-component{

    display: block;

    .title-container {
        height: 50px;
        padding: 15px 0;

        @include media-breakpoint-up(md) {
            height: 79px !important;
        }

        @include media-breakpoint-up(lg) {
            height: 97px !important;
        }
    }
    
    a{
        text-decoration: none;
        font-weight:700;
    }

    .dropdown-container {
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: 770px;
        }
    }

    .button-container {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 300px;
        }
    }

    .date-container {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 370px;
        }
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 100px;

    }

    @include media-breakpoint-up(lg) {
        margin-top: 0;
        margin-bottom: 20px;

    }
    @include media-breakpoint-up(xl) {
        margin-bottom: 100px;

    }
 }