@import "../../../src/styles/_variables.scss";
@import "../../../src/styles/_mixins.scss";

.faq-container {
    .grayBorder {
        border-top: solid 1px #d7d7d7;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: solid 1px #d7d7d7;
    }

    .ReimbursementCalculationTable
    {
        @include media-breakpoint-up(md) {
            margin-left:25px;
        }
        @include media-breakpoint-down(md) {
            margin-left:5px;
        }
    }

    .title {
        height: 45px;
        margin-top: 10px;

        @include media-breakpoint-up(md) {
            height: 79px;
            margin-top: 0px;
        }

        @include media-breakpoint-up(lg) {
            height: 97px;
            margin-top: 0px;
        }
    }

    .billing-info-margin {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .container-view {
        z-index: 0;
        .search-text {
            color: $header-Color;
            font-weight: 400;
        }
        .margin-left-right {
            margin-left: 5px;
            margin-right: 5px;
        }

        .margin-bottom {
            margin-bottom: 15px;
            display: inline-block;
            color: $header-Color;
        }

        .wellHeight {
            height: 4500px;
        }


        .faq-title {
            height: 32px;
            padding-left: 5px;
            height: auto;
            margin-bottom: 5px;
            margin-top: 20px;
            color: $header-Color;
            font-weight: 700;
        }

        .faq-search-title {
            margin-bottom: 15px;
        }

        .faqQuestion {
            height: auto;
            color: $text-Blue;
            cursor: pointer;
            margin-bottom: 10px;
            margin-top: 10px;
            margin-left: 15px;
            font-weight: 600;
        }

        .faqNoMargin{
            margin:0px;
        }

        .faq-search-question {
            height: auto;
            color: $text-Blue;
            cursor: pointer;
            margin-bottom: 0px;
            font-weight: 600;
        }

        .faqAnswer {
            display: none;
            line-height: 1.5em;
            display: block;
            margin-left: 30px;
        }

        .font-italic {
            font-style: italic;
        }

        .font-bold {
            font-family: 'OpenSans-Bold', 'Open Sans Bold', 'Open Sans';
            font-weight: 700;
        }

        .font-600 {
            font-weight: 600;
        }

        ul.blackdisc {
            list-style-type: disc;
        }

        .no-padding {
            padding: 0;
        }

        #search-input-wrapper {
            padding: 15px;
            background-color: #f2f2f2;
            height: auto;
            width: 100%;
        }

        .suggestion-input {
            position: relative;
            height: 50px;
            width: 100%;
            padding-left: 25px;
            padding-right: 35px;
            box-sizing: border-box;
            border: 1px solid #8D8D8D;
            margin-bottom: 0px;
            font-weight: 400;
            font-size: 20px;
            color: $dark;
        }

        .suggestion-icon-wrapper {
            height: auto;
            width: 100%;
        }


        .suggestion-icon {
            position: relative;
            float: right;
            width: 17px;
            height: 17px;
            top: 0px;
            font-size: 17px;
            color: #999999;
            margin-top: -88px;
            margin-right: 0px;
            z-index: 100;

            @include media-breakpoint-up(md) {
                top: 40px;
                right: 35px;
            }
        }

        .suggestion-icon-mobile {
            position: relative;
            float: right;
            width: 17px;
            height: 17px;
            top: 88px;
            font-size: 17px;
            color: #999999;
            margin-top: -135px;
            margin-right: 40px;
            z-index: 100;

            @include media-breakpoint-up(md) {
                top: 40px;
                right: 30px;
            }
        }



        .suggestion-input:focus-within {
            border: 2px solid #0070C9;
            outline-offset: 0px;
            outline: none;
        }

        .suggestion-mobile-container {
            width: 100%;
            height: 85px;
            background: #F2F2F2;
            padding: 0px;
            border-radius: 8px 8px 0px 0px;
            border-width: 3px 3px 3px 3px;
            border-style: solid;
            border-color: rgb(228, 228, 228);
        }

        .suggestion-answer-container {
            background-color: white;
            width: auto;
            height: auto;
            max-height: 30vh;
            z-index: 1;
            padding: 15px 15px 0 15px;
            overflow-x: hidden;
            overflow-y: scroll;
            border-width: 0px 3px 3px 3px;
            border-style: solid;
            border-color: rgb(228, 228, 228);

            @include media-breakpoint-up(md) {
                position: absolute;
                //top: 77px;
                border-width: 0px 2px 2px 2px;
                right: 15px;
                left: 15px;
            }
        }


        .suggestion-answer {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            margin-right: 10px;
        }

        .search-result-element {
            margin-bottom: 25px;

            a {
                cursor: pointer;
            }
        }
    }

    .col-refer-friend {
        margin-bottom: 30px;
    }

    .rightColumn {
        a {
            text-decoration: none;
        }
    }

    #preexisting-cruciate-examples-container {
        display: none;
    }

    .grayBorderLine {
        border-top: solid 1px #d7d7d7;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: solid 1px #d7d7d7;
    }

    a {
        text-decoration: underline !important;
    }

    .preExistingExample, .preExistingExample:hover {
        text-decoration: none !important;
    }

    .preExistingAnswerExampleContainer2{
        max-height:0;
        
    }

    .preExistingAnswerExampleContainer2.expanded{
        max-height: max-content;
        transition: max-height 0.15s ease-out;
        display:block !important;
    }
}