.modal-header-close-button {
  display: none !important;
}

.confirm-charges {
  color: #28373A !important;
  padding: 30px 15px 0 15px;
}
.confirm-charges .fa-pencil {
  font-size: 28px;
  float: right;
}
.confirm-charges .pencil-edit-custom {
  height: 28px;
  float: right;
}
.confirm-charges .next-arrow-circle-right-custom {
  height: 25px;
  margin-left: 10px;
  padding-bottom: 1px;
}
.confirm-charges h1, .confirm-charges h2 {
  font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
  font-weight: 400;
  font-style: normal !important;
  font-size: 36px !important;
  color: #28373a !important;
}
@media (max-width: 991.98px) {
  .confirm-charges h1, .confirm-charges h2 {
    font-size: 21px !important;
  }
}
.confirm-charges .terms {
  font-size: 24px !important;
}
@media (max-width: 991.98px) {
  .confirm-charges .terms {
    font-size: 14px !important;
  }
}
.confirm-charges .text-dark {
  color: #28373A !important;
}
.confirm-charges .text-medium {
  color: #8D8D8D !important;
}
.confirm-charges .summary-content {
  font-weight: normal;
}
.confirm-charges .billing-summary {
  border: 2px solid rgb(228, 228, 228);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 25px;
}
.confirm-charges .billing-summary .summary-list {
  padding-inline-start: 20px;
}
.confirm-charges .terms-conditions {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 25px;
  height: 88px;
}
.confirm-charges .agreement {
  margin-bottom: 25px;
}
.confirm-charges .disabled-btn {
  background: #dae3e8;
  pointer-events: none;
}
@media (min-width: 992px) {
  .confirm-charges {
    padding-top: 0 !important;
  }
}

@media (min-width: 768px) {
  .confirm-charges {
    padding: 30px 15px;
  }
  .confirm-charges .terms-conditions {
    height: 44px;
  }
}

