.home {
  padding: 20px 15px;
}
.home .text-primary-heading.mb-0 {
  color: #28373a !important;
}
.home .list-container a,
.home .list-container a:hover,
.home .list-container :active {
  color: #28373a !important;
  text-decoration: none !important;
}
.home #extole-home-refer {
  cursor: pointer;
}
.home .home-list-box {
  display: flex;
  align-items: center;
  border: 2px solid #E4E4E4;
  padding: 10px 20px;
  border-radius: 10px;
  height: 100%;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .home .home-list-box {
    padding: 10px;
  }
}
.home .home-list-box .home-list-icons {
  width: 40px;
  height: 40px;
}
.home .home-list-box .text-primary {
  color: #28373a !important;
}
.home .home-list-box .text-secondary-angle {
  color: #28373a;
}
.home .home-list-box .claim-icon {
  height: 45px;
  width: 45px;
}
.home .home-list-box .home-list-fa-icons {
  font-size: 36px;
}
.home .home-list-box .home-list-title {
  font-size: 18px;
  font-weight: 600;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .home .home-list-box .home-list-title {
    padding: 0px 0px 0px 10px;
  }
}
.home .justify-content-between .text-secondary {
  content: url(../../../public/assets/images/edit_icon.svg);
}
.home .link-list {
  padding: 0 0 40px 0 !important;
  border: 2px solid #E4E4E4;
  border-radius: 10px;
  flex-grow: 1;
}
.home .link-list .list-title {
  padding: 15px 20px 25px 20px;
  color: #34A350;
  font-size: 24px;
  border-bottom: 2px solid #E4E4E4;
}
.home .link-list a, .home .link-list a:hover {
  text-decoration: none !important;
}
.home .link-list .list-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 20px;
  border-bottom: 2px solid #E4E4E4;
  color: #000000;
  text-decoration: none;
}
.home .links .content-wrapper {
  padding: 22px 20px;
  border: 2px solid #E4E4E4;
  flex-grow: 1;
  border-radius: 10px;
  overflow-wrap: break-word;
}
.home .links .content-wrapper a {
  text-decoration: none;
  font-weight: 600;
}

@media (min-width: 768px) {
  .home .link-list {
    padding: 22px 20px !important;
  }
  .home .link-list .list-title {
    padding: 0 0 5px 0 !important;
    font-weight: 700;
    color: #000000;
    font-size: 16px;
    border: none !important;
  }
  .home .link-list a {
    text-decoration: none;
  }
  .home .link-list a:hover {
    text-decoration: underline !important;
  }
  .home .link-list .list-content {
    color: #6b61f2;
    padding: 5px 0 0 0 !important;
    border: none !important;
    font-weight: 600;
  }
  .home .link-list .list-content i {
    display: none;
  }
  .home .home-list-box {
    height: 78px;
  }
}

