@import "./../../../styles/_variables.scss";



.heading {
    font-weight: 700;
}

a:hover {
    text-decoration: underline !important;
}

.btn {
    font-weight: 700;
}

.btn:hover, .btn:active {
    color: $secondary !important;
    background: #ffffff !important;
    box-shadow: none !important;
}

.primary-text{
    color:$home-button !important;
}

.consent-text {
    font-size: 12px;
    font-weight: 400;
    color: #4F4F4F;

    i {
        font-size: 18px;
    }
}

.tos-privacy-link {
    font-size: 12px;
    text-decoration: underline !important;
    color: #666 !important;
}