@import "./../../styles/_variables.scss";
@import "./../../styles/_mixins.scss";

.view-profile {
  padding: 20px 15px;

  overflow-wrap: break-word;

  .page-title {
    font-size: 24px;
    margin-bottom: 15px;
    @include media-breakpoint-up(md) {
        font-size: 30px;
        margin-bottom: 25px;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0px;
      font-size: 36px;
    }
  }
  .justify-content-between .text-secondary {
    content:url(../../../public/assets/images/edit_icon.svg);
  }

  .view-profile button {
    font-weight: 700;
  }

  .card-wrapper {
    padding: 0px 10px 10px 10px;
    border: 2px solid $light;
    margin-bottom: 10px;
    border-radius: 10px;
    overflow-wrap: break-word;
    flex-grow: 1;

    a:hover {
      color: $secondary !important;
    }

    .alert-box {
      border: 1px solid $danger;
      font-size: 12px;
    }

    @include media-breakpoint-up(md) {
        padding: 25px;
    }    
  }
  @include media-breakpoint-up(lg) {
    padding-top: 0!important;

  } 

  .mobile-section-head {
    width: 100%;
    h2 {
      color: $header-Color;
    }
  }

  .profile-font-icons {
    font-size: 40px;
  }

  .section-header {
    margin-bottom: 15px;
    h2, i {
      color: $header-Color !important;
    }
    
  }

  a {
    text-decoration: none;
  }
}
