@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";

.background-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  height: 210px;
  width: 100%;

  @include media-breakpoint-up(sm) {
    height: 282px;
  }

  a{
    text-decoration: none !important;
  }

  .image-slider {
    justify-content: center;
    flex-direction: row;
    text-align: center;
    height: 100%;
    padding: 15px;
  }

  .carousal-image-item {
    height: 73px;
    width: 73px;
    @include media-breakpoint-up(sm) {
      height: 118px;
      width: 118px;
    }
    margin: 10px;
    margin-bottom: 0px;
    margin-top: 50px;
    border-radius: 70px;
    border: 2px solid $primary;
    @include media-breakpoint-up(sm) {
      border: 4px solid $primary;
    }
    overflow: hidden;
    align-self: center;
    cursor: pointer;
  }

  .carousal-image-edit {
    height: 25px;
    width: 25px;
    border-radius: 12px;
    //background-color: $primary;
    background-color: #7db67b;
    color: white;
    position: absolute;
    top: 0;
    right: 20px;
    @include media-breakpoint-up(sm) {
      height: 35px;
      width: 35px;
      border-radius: 17px;
      top: 17px;
      right: 5px;
    }
  }

  .edit-icon {
    @include media-breakpoint-up(sm) {
      font-size: 24px;
      padding-top: 5px;
    }
    cursor: pointer;
  }

  .no-image {
    background-color: #F2F2F3;
    padding: 10px;
  }

  .carousal-image-item-active {
    height: 133px;
    width: 133px;
    @include media-breakpoint-up(sm) {
      height: 191px;
      width: 191px;
      border: 4px solid $primary;
    }
    background-color: rgb(233, 230, 230);
    color: grey;
    border-radius: 133px;
    border: 2px solid $primary;
    overflow: hidden;
    align-self: center;
  }

  .carousal-image-item-active img {
    width: 100%;
    height: 100%;
  }

  .carousal-image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // .no-image img{
  //   object-fit: unset !important;
  //   width:90%;
  // }

  .carousal-image {
    max-height: 100%;
    max-width: 100%;
  }

  .corousal-bottom {
    text-align: center;
  }

  .corousal-controls {
    color: white;
    display: inline-block;
    height: 35px;
    @include media-breakpoint-up(sm) {
      height: 45px;
    }
    margin: 0 auto;
    margin-top: 10px;
  }

  .pet-name-carousal {
    height: 35px;
    border-radius: 50px;
    width: 200px;
    padding-top: 5px;
    overflow: hidden;
    cursor: pointer;
    @include media-breakpoint-up(sm) {
      padding-top: 8px;
      width: 300px;
      height: 45px;
      padding-top: 10px;
    }
    background-color: $button_Green;
  }
  .corousal-controls span {
    font-weight: 700;
    margin: 3px;
    opacity: 1;
  }

  .icons {
    font-size: 35px;
    // text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.6);
    @include media-breakpoint-up(sm) {
      font-size: 45px;
    }
    padding: 10px 20px;
    font-weight: 700;
    color: #00c885;
    cursor: pointer;
  }

  .add-photo-span {
    padding-top: 20px;
  }

  .active-no-image {
    cursor: pointer;
  }

  .add-pet-button {
    background-color:#fbfbfb;
    color: #038062;
    border: 2px solid #00c885;
    padding: 2px;
    padding-top: 20px;
    font-size: 12px;
    font-weight: 700;
    height: 100px;
    width: 100px;
    font-size: 14px;
    margin-top: 0px;
    border-radius: 70px;
    cursor: pointer;
    align-self: center;
    position: relative;
    top: -11px;
  }

  .add-pet-button-one {
    margin-left: -30px !important;
  }

  .add-pet-button-two {
    margin-left: 10px !important;
    top: 60px;
  }

  .add-pet-button-text {
    margin: 10px;
  }

  .carousal-images-div {
    padding-top: 0px;
    @include media-breakpoint-up(sm) {
      padding-top: 10px;
    }
  }

  .center-align-images {
    @include media-breakpoint-up(sm) {
      margin-left: 100px;
    }
  }

  .pet-image-edit {
    color: $primary;
    font-size: 12px;
  }

  .center-carousal-image {
    width: 53%;
    margin: 0 auto;
  }
}
