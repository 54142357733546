#table-component {
  margin: 5px;
}
#table-component img {
  height: 100%;
  width: auto;
}
#table-component .table-component-header {
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  #table-component .table-component-header {
    font-size: 14px;
  }
}
#table-component .pagination-container {
  padding: 15px 0px;
  height: 32px;
  user-select: none;
}
#table-component .pagination-text-span {
  cursor: pointer;
}
#table-component .pagination-text-span-disabled {
  pointer-events: none;
  color: #CCCCCC;
}
#table-component .no-padding-cell-content-mobile {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
@media (min-width: 992px) {
  #table-component .no-padding-cell-content-mobile {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
#table-component .table-component-striped-table {
  border: 2px solid #E4E4E4;
}
#table-component .table-component-striped-table .table-component-item {
  border-bottom: 2px solid #E4E4E4;
  padding: 10px 0;
}
#table-component .table-component-striped-table .table-component-item .slideup,
#table-component .table-component-striped-table .table-component-item .slidedown {
  max-height: 0;
  overflow-y: hidden;
  -webkit-transition: max-height 0.5s ease-in-out;
  -moz-transition: max-height 0.5s ease-in-out;
  -o-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}
#table-component .table-component-striped-table .table-component-item .slidedown {
  max-height: 60px;
}
#table-component .table-component-striped-table .table-component-item:last-child {
  border-bottom: none;
}
#table-component .table-component-striped-table .table-component-item:nth-child(odd) {
  background-color: #f2f2f2;
}
#table-component .table-component-striped-table .table-component-item:nth-child(even) {
  background-color: #ffffff;
}

