@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";

.retry-modal-footer-btn {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 40%;
  }
}

.retry-billing-button {
  @include media-breakpoint-up(md) {
    margin-left: 10px;
  }
}
