@import "./../../styles/_variables.scss";
@import "./../../styles/_mixins.scss";

.cancel-parent-container {
  min-height: 60vh;

  .button-keep{
    height:40px;
  }
  
}