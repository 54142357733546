.login-main {
  background-image: url("../../../public/assets/images/login/login_mobile.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  padding: 15px 0;
}
.login-main .logo {
  width: 175px;
  height: 45px;
  margin-bottom: 30px;
}
.login-main .main-content {
  padding: 30px 0;
}
@media (min-width: 992px) {
  .login-main {
    padding: 12% 0;
  }
  .login-main .logo {
    position: absolute;
    left: 15%;
    top: 17px;
  }
}
@media screen and (min-width: 2000px) {
  .login-main {
    padding: 20% 0;
  }
}
@media (min-width: 1200px) {
  .login-main {
    padding: 13% 0;
  }
}
@media (min-width: 768px) {
  .login-main .logo {
    width: 200px;
    height: 50px;
  }
}
@media (min-width: 576px) {
  .login-main {
    background-image: url("../../../public/assets/images/login/login_bg2.jpg");
  }
}
.login-main .title {
  font-weight: 300;
  font-size: 32px;
  color: #ffffff;
  text-align: center;
}
@media (min-width: 576px) {
  .login-main .title {
    font-size: 44px;
  }
}
.login-main .sub-title {
  font-weight: 300;
  font-size: 22px;
  color: #ffffff;
  margin-top: -10px;
  margin-bottom: 10px;
  text-align: center;
}
@media (min-width: 576px) {
  .login-main .sub-title {
    font-size: 28px;
  }
}

