@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";

.cancel-confirm{
    margin-top: 20px;

    a {
        text-decoration: none;
        font-weight:700;
    }

    .title-container{
        @include media-breakpoint-down(md){
            margin-bottom:15px;
        }
    }
    .content-wrapper{
        padding: 22px 20px;
        border: 2px solid #E4E4E4;
        height: 100%;
        border-radius: 10px;
        overflow-wrap: break-word;

        span{
            font-weight:600;
        }
        .sub-heading{
            font-weight:400;
            color:$gray-4;
            display: block;
            font-size:12px;
            text-transform: uppercase;
        }
    }
    .terms-and-condition{
        strong{
            color:$header-Color;
        }
        .bg-color{
            background-color:#fff;
            font-size:12px;
        }
    }
    @include media-breakpoint-up(md) {
        .underline{
            text-decoration: underline;
        }
    }
    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }
    .button-container {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 300px;
        }
    }
}