@import "./../../styles/_variables.scss";
@import "./../../styles/_mixins.scss";

.post-cancellation-container {
    .header-group {
        background-color: #f0f0ee;

        .header-top {
            background-color: #f0f0ee;
            min-height: 126px;

            .logo {
                border-width: 0px;
                width: 235px;
                height: 60px;
            }
        }
    }

    .title-container {
        height: 50px;
        margin: 10px 0 10px 0;

        @include media-breakpoint-up(md) {
            margin: 0;
            height: 79px;
        }

        @include media-breakpoint-up(lg) {
            height: 97px;
        }
    }

    .status-bar {
        height: 37px;
        background-color: $primary;
    }

    .form-box {
        width: 100%;
        height: 1400px;
        overflow: hidden;

        @include media-breakpoint-up(md) {
            height: 1225px;
        }

        @include media-breakpoint-up(lg) {
            width: 700px;
        }
    }

    li {
        position: relative;
        left: -20px;
    }

    .sign-out-button {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 470px;
        }
    }

}