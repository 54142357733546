.contact-form-container .margin-bottom {
  margin-bottom: 15px !important;
}
.contact-form-container .contact-form {
  margin-top: 15px;
  margin-bottom: 40px;
}
.contact-form-container .contact-form h4 {
  color: #28373a;
}
.contact-form-container .contact-form .input-container {
  width: 100%;
}
@media (min-width: 768px) {
  .contact-form-container .contact-form .input-container {
    width: 470px;
  }
}
.contact-form-container .contact-form .phone-input-container {
  width: auto !important;
}
@media (min-width: 768px) {
  .contact-form-container .contact-form .phone-input-container {
    width: 200px !important;
  }
}
.contact-form-container .contact-form .address-input-container {
  width: 100% !important;
}
@media (min-width: 768px) {
  .contact-form-container .contact-form .address-input-container {
    width: 570px !important;
  }
}
.contact-form-container .contact-form .text-area-input {
  box-sizing: border-box;
  border: 1px solid #8D8D8D;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
  padding: 15px 0px 0px 15px;
}
.contact-form-container .contact-form .text-area-input:focus {
  border: 2px solid #0097ab;
  outline: none;
}
.contact-form-container .contact-form .related-faq {
  font-weight: 700;
  font-size: 14px;
  color: #28373a;
}
@media (min-width: 768px) {
  .contact-form-container .contact-form .related-faq {
    font-size: 24px;
  }
}
.contact-form-container .contact-form .faqQuestion {
  height: auto;
  color: #6b61f2;
  cursor: pointer;
  margin-bottom: 15px;
  margin-top: 15px;
  font-weight: 600;
}
.contact-form-container .contact-form .faq-container {
  margin-left: 20px;
}
.contact-form-container .contact-form hr {
  height: 2px;
  background: #E4E4E4;
  margin: 0px;
  border-top: 0;
}
.contact-form-container .rightColumn .info-body-padding {
  padding-top: 5px !important;
}
.contact-form-container .rightColumn .billing-info {
  margin-bottom: 30px;
}

h4 {
  color: #28373a;
}

.contact-image-item {
  display: inline-block;
  vertical-align: top;
  margin-top: -4px;
}

.text-container {
  display: inline-block;
  margin-left: 5px;
}
.text-container p, .text-container strong {
  margin-bottom: 0px;
}

.margin-bottom {
  margin-bottom: 20px;
}
.margin-bottom strong {
  color: #657493;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
  display: block;
  margin-bottom: 5px;
}
.margin-bottom div {
  color: #28373a;
  font-weight: 600;
}

