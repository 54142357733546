@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";

.change-password {
  min-height: 60vh;

  .title-container {
    height: 50px;

    @include media-breakpoint-up(md) {
      height: 79px ;
    }

    @include media-breakpoint-up(lg) {
      height: 97px ;
    }
  }

  @include media-breakpoint-up(md) {
    height: 75vh;
  }

  .field-width {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: 470px;
    }
  }

  #change_password_field {
    margin: 10px 0px 10px 0px;
  }

  .button-container-margin {
    margin: 20px 0px 0px 0px;
  }

  .button-margin {
    margin: 0px 0px 12px 0px;
  }

  .fa-question-circle {
    font-size: 13px;
    color: #ff5b0b;
  }

  .password-note {
    font-size: 14px;

    .fa-question-circle-o {
      font-size: 24px;
      margin-right: 10px;
    }

    + i {
      // only for Question icon
      margin: 10px;
    }

    .fa-info-circle {
      color: #8e8e8e;
    }

    .fa-check-circle {
      color: $primary;
    }
  }
}

.password-change-success {

  @include media-breakpoint-up(md) {
    height: 75vh;
    .password-change-success-home-button {
      max-width: 255px;
    }
  }
  .status {
    height: 42px;
  }
  .button-secondary {
    height: 40px;
  }

  .fa-check-circle {
    font-size: 28px;
  }
  .status-info {
    font-size: 18px;
    font-weight: 700;
    padding-left: 8px;
  }

  .status-text {
    font-size: 18px;
    color: white;
    font-weight: 400;
  }

  .content {
    h1 {
      color: $header-Color;
      font-weight: 400;
    }
  }
}