@import "./../../styles/_variables.scss";
@import "./../../styles/_mixins.scss";

.mobile-home {

    a{
        text-decoration: none !important;
    }

    .header {
        background: $gray-1;
        border-bottom:  2px solid $light;
        text-align: left;
        padding: 10px 20px;
        font-weight: 700;
        font-size: 16px;
        min-height: 32px;
        color: $header-Color;
    }
    
    .list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid $light;
        padding: 10px 20px;

        .list-fa-icons {
            font-size: 30px;
            color: $header-Color !important;
        }

        .list-image {
            height: 35px;
            width: 30px;
        }

        .list-title {
            font-size: 16px;
            font-weight: 400;
            color: $header-Color !important;
            font-weight: normal !important;
        }

        .list-fa-arrow {
            font-size: 32px;
            color: $header-Color !important;
        }
    }
}

