@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";

@include media-breakpoint-down(md)
{
  .modal-dialog{
    top:unset !important;
  }
}
.update-success {

  height: auto;
  min-height: 80vh;
  padding-bottom: 55px;

  .status-container {
    min-height: 40px;
  }

  .header-parent-appear {
    margin-top: -42px;
  }

  .header-parent-appear.header-parent-appear-active {
    margin-top: 0px;
    transition: margin-top 0.5s ease-in;
  }

  .update-success-button {
    margin-bottom: 16px;

    @include media-breakpoint-up(md) {
      width: 255px;
      margin-right: 22px;
    }
  }

  .margin-bottom {
    margin-bottom: 25px !important;
  }

  .status {
    width: 100%;
    min-height: 42px;
  }

  .fa-check-circle {
    font-size: 28px;
  }

  .status-info {
    font-size: 18px;
    font-weight: 700;
    display:inline-block;
    padding-left: 0px;
  }

  .status-text {
    font-size: 18px;
    color: white;
    padding-left: 8px;
    font-weight: 400;
  }

  .status-description {
    font-weight: 400;
    display: block;
    color: $dark;
    font-size: 14px;
    margin-bottom: 5px;
    text-decoration: none !important;
    // word-break: break-all;

    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
  }

  h4 {
    color: $dark;
    margin-bottom: 0;
  }

  .margin-bottom {
    margin-bottom: 20px;
  }

  .content {

    h1 {
      min-height: 50px;
      color: $header-Color;
      margin: 0;
      display: flex;
      align-items: center;

      @include media-breakpoint-up(md) {
        min-height: 60px;
      }

      @include media-breakpoint-up(lg) {
        min-height: 77px;
      }
    }

    .padding {
      padding: 15px;
    }

    .success-container {
      height: auto;
      border-width: 2px;
      border-style: solid;
      border-color: $light;
      border-radius: 8px;
      margin-bottom: 25px;
    }
  }


}
.update-success .status-container{
  height:unset !important; 
}
.status-header-container #status-header{
  height:unset !important;
}
.update-success .status{
  height:unset !important;
}
.update-success .status-text{
  padding:2px 10px;
}

.status-text .status-info{
  padding-left:0px !important;
}