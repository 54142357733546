.text-color-violet {
  color: #6B61F2 !important;
}

#file-upload-component {
  /* Upload */
  /* AddFiles */
  /* SelectionView */
}
#file-upload-component #file-upload {
  position: relative;
}
@media (min-width: 576px) {
  #file-upload-component #file-upload {
    padding: 15px;
    padding-top: 25px;
  }
}
#file-upload-component #file-upload .preview-fallback {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
#file-upload-component #file-upload .download-icon {
  color: #FF5B0B;
}
#file-upload-component .button-disabled {
  background-color: #CCCCCC !important;
  pointer-events: none !important;
}
@media (max-width: 991.98px) {
  #file-upload-component .mobile-zoomed-image-container {
    width: 100%;
  }
}
#file-upload-component .mobile-zoomed-image-view::after {
  display: block;
  position: absolute;
  background-image: -webkit-linear-gradient(top, white 0, #646262 100%);
  background-image: linear-gradient(to bottom, white 0, #646262 100%);
  margin-top: 148px;
  height: 15%;
  width: 100%;
  content: "";
  bottom: 0;
  left: 0;
  opacity: 0.8;
}
#file-upload-component .zoom-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 38px;
  left: 38px;
  transform: translate(-50%, -50%);
}
@media (min-width: 576px) {
  #file-upload-component .zoom-icon {
    top: 53px;
    left: 50px;
    width: 30px;
    height: 30px;
  }
}
#file-upload-component .upload-error-exclam {
  color: #ff2f6d;
}
#file-upload-component .zoom-out-icon {
  font-size: 23px;
  padding: 10px;
  position: absolute;
  color: white;
  left: 18px;
  bottom: -20px;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#file-upload-component .file-upload-overlay {
  /*background: rgba(0, 0, 0, 0.5);*/
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
#file-upload-component h4 {
  font-weight: 800;
  padding: 2px;
  font-size: 16px;
  margin-bottom: 0px;
  margin-left: 10px;
  color: #038062 !important;
}
@media (min-width: 576px) {
  #file-upload-component h4 {
    font-weight: 700;
    padding: 5px;
    color: #038062 !important;
  }
}
#file-upload-component .row {
  margin-left: 0px;
  margin-right: 0px;
}
#file-upload-component .upload {
  width: 100%;
  border: 2px solid #00c885;
  padding: 10px;
  border-radius: 8px;
}
@media (min-width: 576px) {
  #file-upload-component .upload {
    border: 2px solid #00c885;
    padding: 25px;
    text-align: center;
  }
}
#file-upload-component .upload-icon {
  height: 60px;
  width: 60px;
}
@media (min-width: 768px) {
  #file-upload-component .upload-icon {
    margin-bottom: 10px;
  }
}
#file-upload-component .drag-text-header {
  font-size: 16px;
  color: #038062 !important;
  font-weight: bold;
}
@media (min-width: 768px) {
  #file-upload-component .drag-text-header {
    font-size: 20px;
  }
}
#file-upload-component .drag-text {
  font-weight: 400;
  font-size: 20px;
  color: #038062;
}
#file-upload-component .btn-select {
  background-color: #4aa574;
  color: white;
  border-radius: 8px;
  width: 100%;
  border: 0px;
  font-weight: 700;
  font-size: 16px;
  padding: 8px;
}
@media (min-width: 576px) {
  #file-upload-component .btn-select {
    width: 164px;
  }
}
#file-upload-component .btn-select:hover {
  background-color: #4aa574 !important;
}
#file-upload-component .btn-add-files {
  width: 73px;
  height: 73px;
  text-align: center;
  margin: 0 auto;
  font-size: 12px;
  border-radius: 8px;
  border: 2px solid #038062;
  position: relative;
  margin: 0px 20px 20px 0px;
}
@media (min-width: 576px) {
  #file-upload-component .btn-add-files {
    width: 100px;
    height: 100px;
    font-size: 16px;
  }
}
#file-upload-component .upload-edit {
  width: 100%;
  border-radius: 8px;
}
@media (min-width: 576px) {
  #file-upload-component .upload-edit {
    border: 2px solid #00c885;
    padding: 25px;
  }
}
#file-upload-component .selection-container {
  position: relative;
  margin: 0px 20px 20px 0px;
  border: 3px solid #34A350;
}
#file-upload-component .file-upload-loader {
  position: absolute;
  bottom: 10px;
  text-align: center;
  color: white;
  font-weight: 700;
}
#file-upload-component .upload-error {
  border: 3px solid #ff2f6d !important;
}
#file-upload-component .preview-selected {
  width: 266px;
  height: 355px;
  margin: 10px auto;
}
@media (min-width: 576px) {
  #file-upload-component .preview-selected {
    width: 100%;
  }
}
#file-upload-component .prev-img {
  display: flex;
  align-items: center;
  height: 73px;
  width: 73px;
  background: #333333;
  filter: brightness(80%);
}
#file-upload-component .prev-img img {
  max-height: 73px;
  max-width: 73px;
}
@media (min-width: 576px) {
  #file-upload-component .prev-img img {
    max-height: 100px;
    max-width: 100px;
  }
}
@media (min-width: 576px) {
  #file-upload-component .prev-img {
    height: 100px;
    width: 100px;
  }
}
#file-upload-component .zoomed-image {
  height: 73px;
  width: 73px;
  background: #333333;
}
@media (min-width: 576px) {
  #file-upload-component .zoomed-image {
    height: 100px;
    width: 100px;
  }
}
#file-upload-component .zoomed-pdf {
  height: 100%;
  width: 100%;
  background: #333333;
}
#file-upload-component .image-number {
  min-height: 18px;
  min-width: 18px;
  top: 7px;
  left: 8px;
  font-size: 12px;
  padding: 0px 4px;
  font-weight: bold;
  color: white;
  background-color: #34A350;
  position: absolute;
  transform: translate(-50%, -50%);
}
@media (min-width: 576px) {
  #file-upload-component .image-number {
    min-height: 23px;
    min-width: 23px;
    top: 10px;
    left: 11px;
    padding: 0px 5px;
    font-size: 16px;
  }
}
#file-upload-component .file-upload-error {
  background-color: #ff2f6d;
}
#file-upload-component .delete-icon {
  width: 10px;
  height: 10px;
  position: absolute;
  top: 7px;
  left: 66px;
  transform: translate(-50%, -50%);
}
@media (min-width: 576px) {
  #file-upload-component .delete-icon {
    left: 88px;
    width: 15px;
    height: 15px;
    top: 11px;
  }
}
#file-upload-component .garpage-icon {
  font-size: 23px;
  padding: 10px;
  position: absolute;
  bottom: -20px;
  color: white;
  right: -20px;
  transform: translate(-50%, -50%);
}
#file-upload-component .note-text {
  font-size: 12px !important;
  font-weight: bold !important;
  line-height: 1 !important;
}
#file-upload-component .note-subtext {
  font-size: 12px !important;
  line-height: 1 !important;
}

.btn-close {
  margin-top: 20px !important;
  width: 50% !important;
}

.file-upload-modal-button {
  width: 150px;
  margin: 10px;
}

.file-info {
  font-size: 10px;
  font-weight: 400;
}
@media (min-width: 576px) {
  .file-info {
    font-size: 12px;
  }
}

