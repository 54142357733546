.pet-detail-container {
  height: auto;
  min-height: 100vh;
  margin-bottom: 15px;
  /* ==================================== Accordian & its Title CSS ====================================*/
  /* ==================================== Accordian Detail Part CSS ====================================*/
}
.pet-detail-container a {
  text-decoration: none !important;
}
.pet-detail-container .right-column a:hover {
  color: #FF5B0B !important;
  text-decoration: underline !important;
}
.pet-detail-container .right-column .info-body-padding {
  padding-top: 5px !important;
}
.pet-detail-container .right-column .billing-info {
  margin-bottom: 30px;
  overflow-wrap: break-word;
}
.pet-detail-container .add-pet-button-mobile {
  margin-top: 20px;
  margin-bottom: 10px;
}
.pet-detail-container .accordion__body {
  display: block;
}
.pet-detail-container .accordion {
  border-left: 2px solid #E4E4E4;
  border-right: 2px solid #E4E4E4;
  border-bottom: 2px solid #E4E4E4;
  border-radius: 8px;
  height: auto;
}
.pet-detail-container .accordion__body--hidden {
  display: none;
}
.pet-detail-container .accordian-title {
  height: 50px;
  cursor: pointer;
  font-weight: 600;
  font-size: 22px;
  display: table-cell;
  vertical-align: middle;
}
@media (min-width: 768px) {
  .pet-detail-container .accordian-title {
    height: 50px;
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 15px;
    font-weight: 700;
    font-size: 24px;
    color: #333333;
  }
}
@media (min-width: 768px) {
  .pet-detail-container .accordian-title-start {
    padding-top: 20px;
    padding-left: 30px;
  }
}
.pet-detail-container .accordian-mobile-name {
  font-weight: 600;
  font-size: 22px;
  margin-top: 10px;
}
.pet-detail-container .accordian-title .accordian-sub-title {
  font-weight: 400;
  font-size: 16px;
  color: #333333;
  margin-top: 5px;
  align-items: flex-end;
}
.pet-detail-container .accordian-container {
  height: auto;
}
@media (min-width: 1200px) {
  .pet-detail-container .accordian-container {
    flex-direction: column;
  }
}
.pet-detail-container .avatar-editor {
  margin-top: 15px;
}
@media (min-width: 768px) {
  .pet-detail-container .avatar-editor {
    margin-top: 10px;
  }
}
.pet-detail-container .avatar-editor .avatar-container {
  width: 100px;
  height: 100px;
}
@media (min-width: 768px) {
  .pet-detail-container .avatar-editor .avatar-container {
    width: 165px;
    height: 165px;
  }
}
.pet-detail-container .avatar-editor .edit-container {
  top: 0;
}
.pet-detail-container .accordian_detail_section {
  margin-top: 15px;
}
.pet-detail-container .accordion_sub {
  border-top: 2px solid #E4E4E4;
  position: relative;
}
.pet-detail-container .accordion_sub .show-view {
  position: absolute;
  top: 0px;
  right: 15px;
  cursor: pointer;
  height: 50px;
  font-weight: 400;
  font-size: 16px;
  color: #6b61f2;
}
@media (min-width: 768px) {
  .pet-detail-container .accordion_sub .show-view {
    right: 25px;
  }
}
@media (min-width: 992px) {
  .pet-detail-container .accordion_sub .show-view {
    right: 15px;
  }
}
.pet-detail-container .accordion_sub .show-view span {
  height: 20px;
}
.pet-detail-container .accordion_sub .show-view .show-icon {
  font-size: 45px;
  color: #28373a;
  margin-left: 10px;
}
@media (min-width: 768px) {
  .pet-detail-container .accordion_sub .show-view .show-icon {
    font-size: 30px;
  }
}
.pet-detail-container .detail-table {
  width: 75%;
}
.pet-detail-container table {
  width: "100%";
}
.pet-detail-container .accordian_button_container {
  background-color: rgb(242, 242, 242);
  padding: 28px;
}
.pet-detail-container .upload_medical_record_button {
  width: 251px;
}
.pet-detail-container .upload_medical_record_button button {
  padding: 0;
  height: 40px;
}
.pet-detail-container .submit-a-claim-buttom {
  width: 251px;
  margin-left: 15px;
}
.pet-detail-container .submit-a-claim-buttom-mobile {
  margin: 0px 15px 20px 15px;
}
.pet-detail-container .tos-privacy-link {
  font-size: 12px;
  text-decoration: underline !important;
  color: #666 !important;
}

.form-content {
  padding: 15px 30px;
}
@media (max-width: 991.98px) {
  .form-content {
    padding: 15px;
  }
}
.form-content .description {
  font-size: 16px;
  color: #333;
  font-weight: 600;
}
.form-content .required {
  font-size: 12px;
}
.form-content .form-group {
  position: relative;
}
.form-content .form-group input, .form-content .form-group select {
  border-radius: 0px;
  height: 55px;
  border: 1.5px solid;
  border-color: #8d8d8d;
}
.form-content .form-group input:focus, .form-content .form-group select:focus {
  border-color: #0070c9;
  box-shadow: none;
}
.form-content .form-group select {
  font-size: 20px;
  color: #999;
}
.form-content .form-group label {
  position: absolute;
  font-size: 20px;
  color: #999;
  top: 20%;
  left: 2%;
  overflow: hidden;
  text-wrap: nowrap;
  width: 95%;
}
.form-content .form-group input[class="form-control filled"] + label {
  font-size: 12px;
  color: #0070c9;
  top: 10%;
}
.form-content .form-group input[class="form-control filled"] {
  border-color: #0070c9;
}
.form-content .form-group:focus-within label {
  font-size: 12px;
  color: #0070c9;
  top: 10%;
}
.form-content .form-group .submit {
  font-size: 18px;
  color: #fff;
  background-color: #d3d3d3;
  font-weight: 700;
  padding: 5px 50px;
  border-radius: 5px;
  border: none;
  width: max-content;
  display: inline-block;
  margin-right: 20px;
  height: fit-content;
  pointer-events: none;
  cursor: not-allowed;
}
.form-content .form-group .submit.enable {
  pointer-events: all;
  background-color: #ff5b0b;
  cursor: pointer;
}
.form-content .form-group .skip {
  border: none;
  color: #ff5b0b;
  width: max-content;
  display: inline-block;
}
@media (max-width: 991.98px) {
  .form-content .form-group .submit, .form-content .form-group .skip {
    width: 100%;
  }
}
.form-content .form-group.width50 {
  width: 50%;
}
@media (max-width: 991.98px) {
  .form-content .form-group.width50 {
    width: 100%;
  }
}

.successMessage {
  text-align: right;
}
.successMessage p {
  padding-left: 15px;
  font-size: 16px;
  color: #333;
  font-weight: 600;
  text-align: left;
}
.successMessage button {
  padding: 5px 0px;
  width: 200px;
  border-radius: 5px;
  border: 2px solid #ff5b0b;
  margin: 15px;
  margin-right: 10px;
  background-color: transparent;
  font-weight: 700;
}
.successMessage .done {
  color: #fff;
  background-color: #ff5b0b;
  order: 1;
}
.successMessage .addMore {
  color: #ff5b0b;
  border: 2px solid #ff5b0b;
}

.failureMessage {
  color: red;
}

.petDetails .CTA {
  border: none;
  background-color: transparent;
  box-shadow: none;
  color: #ff5b0b;
  text-decoration: underline;
}

@media (max-width: 991.98px) {
  .accordian_button_container {
    background-color: transparent !important;
  }
  .accordian_button_container .upload_medical_record_button {
    display: none !important;
  }
  .accordian_button_container .submit-a-claim-buttom {
    margin-left: 0px;
  }
}

