.cancel-component {
  display: block;
}
.cancel-component .title-container {
  height: 50px;
  padding: 15px 0;
}
@media (min-width: 768px) {
  .cancel-component .title-container {
    height: 79px !important;
  }
}
@media (min-width: 992px) {
  .cancel-component .title-container {
    height: 97px !important;
  }
}
.cancel-component a {
  text-decoration: none;
  font-weight: 700;
}
.cancel-component .dropdown-container {
  width: 100%;
}
@media (min-width: 992px) {
  .cancel-component .dropdown-container {
    width: 770px;
  }
}
.cancel-component .button-container {
  width: 100%;
}
@media (min-width: 768px) {
  .cancel-component .button-container {
    width: 300px;
  }
}
.cancel-component .date-container {
  width: 100%;
}
@media (min-width: 768px) {
  .cancel-component .date-container {
    width: 370px;
  }
}
@media (min-width: 768px) {
  .cancel-component {
    margin-bottom: 100px;
  }
}
@media (min-width: 992px) {
  .cancel-component {
    margin-top: 0;
    margin-bottom: 20px;
  }
}
@media (min-width: 1200px) {
  .cancel-component {
    margin-bottom: 100px;
  }
}

