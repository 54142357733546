@import "../../../styles/variables";
@import "../../../styles/mixins";

.change-policy-options {
  padding: 30px 15px;

  .rate-text-large {
    font-size: 32px;
    font-weight: bold !important;
    font-family: "ArialMT", "Arial", sans-serif;

    small {
      font-size: 12px;
      font-weight: normal;
    }
  }

  h1,h2,h4{
    color: $header-Color !important;
    font-weight: 400 !important;
  }

  .subDescription{
    color:$header-Color;
  }
  textarea {
    font-size: 10px;
    color:$gray-4;
    font-weight: 400;
    border-color: $gray-3;
  }
 .labelStyle{
  font-family: "ArialMT", "Arial", sans-serif;
  color: $header-Color;
 }
 .button-link{
  color:$text-Blue !important;
 }
 .btn-outline-secondary {
    color:$text-Blue !important;
    border: none;
 }
  .pet-icon {
    width: 43px;
    height: 36px;
  }

  .pet-policy-rates-content {
    border: 2px solid rgba(228, 228, 228, 1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .monthly-rate-container {
    border: 2px solid $gray-1;
    margin-top: -2px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 30px;

    .calendar-icon {
      font-size: 24px;
      color: #333333;
      padding-right: 15px;
    }

    .rate-container {
      padding: 15px;
    }

    .effective-details {
      background-color: #f7f7f7;
      padding: 15px;

      .effective-date {
        font-size: 14px;
      }

      ul {
        padding-inline-start: 16px;
        font-size: 14px;
      }
    }

    .CAFraudWarning {
      background-color: #f7f7f7;
      padding: 0px 15px 15px 15px;
      font-size: 12px;
      color: $header-Color;
    }
  }

  .new-policy-effect {
    p {
      font-size: 14px;
    }
  }
}

@include media-breakpoint-up(md) {
  .change-policy-options {
    .monthly-rate-container {
      .rate-container {
        padding: 25px;
      }

      .effective-details {
        padding: 25px;
        background-color: $light;
        padding: 15px;
      }

      .CAFraudWarning {
        background-color: $light;
        padding: 0px 15px 15px 15px;
        font-size: 12px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .change-policy-options {
    .pet-policy-rates-content {
      border: none;
    }

    .monthly-rate-container {
      border: 2px solid $button_Green;
      border-radius: 8px;
      padding: 25px;

      .rate-container {
        padding: 0;
      }

      .effective-details {
        padding: 0;
        background-color: transparent;
      }

      .CAFraudWarning {
        background-color: transparent;
        padding: 0px;
        font-size: 12px;
      }
    }
    padding-top: 0 !important;
  }
}
