@import "../../../styles/variables";
@import "../../../styles/mixins";

.upload-medical-records {
    margin: 20px 0;
    .header {
        margin-bottom: 20px;
        font-weight: 600;

        @include media-breakpoint-up(md) {
            font-weight: 400
        }
    }

    .upload-mr-content {
        a {
            text-decoration: none;
        }
        .links {
            font-weight: 650;
        }
        .mr-collapse-box {
            border: 2px solid $light;
            border-radius: 10px;
            margin-bottom: 15px;
        
            .mr-box-item {
              padding: 10px;
              border-bottom: 2px solid $light;
            }
        
            .mr-box-item:last-of-type {
              border-bottom: none !important;
            }

            .selected {
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                background: $gray-1;
            }
          
            .disable {
                color: $gray-2;
                pointer-events: none;
          
                i {
                  display: none;
                }
            }
          
            .box-title {
                font-weight: 700;
                @include media-breakpoint-up(sm) {
                    margin-left: 14px;
                    margin-top: 10px;
                    // color:$header-Color;
                    font-size: 18px;
                  }
                span{
                    font-weight: normal;
                }
                text-2 {
                    color: $gray-2;
                }

                i {
                    font-size: 22px !important;
                }
            }

            .tc-box {
                @include media-breakpoint-up(sm) {
                  margin-left: 14px;
                  margin-right: 14px;
                }
              }

            textarea {
                font-size: 12px;
                font-weight: normal;
                width: 100%;
                margin: 10px 0;
                font-size: 10px;
                height: 90px;
          
                @include media-breakpoint-up(md) {
                  font-size: 12px;
                  height: 45px;
                }
            }
          
            .btn-secondary {
                font-weight: 700;
                padding: 12px;
                width: 100%;
                border-radius: 5px;
                border: none;
                background-color: $button_Green;
                @include media-breakpoint-up(md) {
                  width: 65%;
                }
            }
          
            .btn-secondary:hover {
                background-color: $button_Green !important;
                color: #ffffff !important;
            }
          
            .disabled-btn {
                background: $gray-3 !important;
                pointer-events: none;
            }
          
            .cancel-btn {
                font-weight: 700;
                width: 25%;
                text-align: center;
                text-decoration: underline;
                border: none;
                background: none;
                color: $text-Blue !important;
            }

            .upload-file-edit {
                color: $text-Blue;
                font-weight: 700;
                text-decoration: underline;
                cursor: pointer;
            }

            .problem-upload-toggler{
                font-weight: 700;
                font-size: 12px;
                margin-bottom: 10px;
                cursor: pointer;
                color: $text-Blue !important; 
            }
        }    

        .faq-toggle {
            font-weight: 700;
        }
    }
    .d-flex {
        .button-secondary {
            border-radius: 20px;
            border: 2px solid #5ac48a;
            color: #5ac48a;
        }    
    }
    
}