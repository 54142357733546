.content-container {
  padding-top: 80px;
}
@media (min-width: 576px) {
  .content-container {
    padding-top: 76px;
  }
}
@media (min-width: 992px) {
  .content-container {
    padding-top: 0px;
  }
}

#chatButtons {
  display: none;
}

