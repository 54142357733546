@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";

.homeBanner{
    
    .main-content{
        background-position-x: 85%;
        background-size: contain;
        background-repeat: no-repeat;
        padding: 60px 0px;
        .heading{
            font-size:44px;
            color:#333;
            font-weight: normal;
        }
        .subHeading{
            font-size:24px;
            color:#333;
            line-height: 50px;
        }
        .CTA{
            font-size:18px;
            color:#fff !important;
            padding:8px 50px;
            text-decoration: none;
            background-color: #ff5b0b;
            border-radius: 50px;
            display: block;
            width: max-content;
            font-weight: bold;
            &:hover{
                text-decoration: none !important;
            }
        }

    }
    @include media-breakpoint-only(md){
        .main-content{
            background-position-x: 100%;
            .heading{
                font-size:30px;
            }
            .subHeading{
                font-size:18px;
                line-height:26px;
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .main-content{
            padding-left: 20px;
            padding-right: 20px;
            background-image: none !important;
            .heading{
                font-size:30px;
            }
            .subHeading{
                font-size:18px;
                line-height:26px;
            }
            .CTA{
                font-size:16px;
                width:100%;
                text-align:center;
            }
            .AddVetClinicImg{width:100%;}
        }
    }
}