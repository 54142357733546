.pop-over {
  border: 1px solid #fefefe;
  box-sizing: border-box;
  background-color: white;
  transform: scaleY(0);
  transform-origin: top;
  transition: all 250ms ease-in-out;
  border: 1px solid #d3d3d3;
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19) !important;
  z-index: 70;
}

.pop-over:after {
  border-style: solid;
  border-width: 0px 8px 12px 8px;
  border-color: transparent transparent white transparent;
  position: absolute;
  left: 50%;
  top: -12px;
  margin-left: -8px;
  content: "";
}

.pop-over:before {
  border-style: solid;
  border-width: 0px 10px 14px 10px;
  border-color: transparent transparent lightgray transparent;
  position: absolute;
  left: 50%;
  top: -14px;
  margin-left: -10px;
  content: "";
}

.left:before {
  left: 80%;
}

.left:after {
  left: 80%;
}

.custom-message:before {
  left: 68%;
}

.custom-message:after {
  left: 68%;
}

.open {
  /* this is blocking fixed positioning of chat buttons */
  transform: scaleY(1);
}

