@import "../../../src/styles/_variables.scss";
@import "../../../src/styles/_mixins.scss";

.contact-container {
  min-height: 60vh;

  a {
    text-decoration: none !important;
  }

  .title {
    height: 45px;
    margin-top: 10px;

    @include media-breakpoint-up(md) {
      height: 79px !important;
      margin-top: 0px;
    }

    @include media-breakpoint-up(lg) {
      height: 97px !important;
      margin-top: 0px;
    }
  }
  .info-body {
    color: $gray-4;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
}

  .margin-bottom {
    margin-bottom: 15px;
    display: inline-block;
  }

}

.home-button-thank-you {
  margin-top: 15px;
  margin-bottom: 30px;
  border-radius: 20px;
  width: 203px;
  height: 40px;

  @include media-breakpoint-up(md) {
    margin-top: 20px;
    margin-bottom: 40px;
    max-width: 255px;
  }
}