.status .icon {
  font-size: 22px !important;
  margin-right: -1px;
}
.status .fillin {
  width: 7px;
  height: 5px;
  margin-bottom: 4px;
  margin-right: -1px;
}
.status .danger {
  background: #ff2f6d;
}
.status .dark {
  background: #666666;
}
.status .light {
  background: #E4E4E4;
}
.status .success {
  background: #34A350;
}
.status .text-dark {
  color: #666666 !important;
}
.status p {
  text-decoration: underline;
  cursor: pointer;
}
.status .fa-stack {
  width: fit-content;
  height: fit-content;
  line-height: 1.5 !important;
}
.status .number-circle {
  border-radius: 50%;
  padding: 2px;
  height: 19px;
  width: 19px;
  line-height: 0;
  line-height: 0.5;
  margin-bottom: 3px;
}
.status .border-red {
  border: 3px solid #ff2f6d;
}
.status .border-grey {
  border: 3px solid #E4E4E4;
}

.primary-text {
  color: #038062;
}

@media only screen and (max-width: 374px) {
  .alignStatus {
    margin-left: -35px;
    margin-right: -25px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 375px) {
  .alignStatus {
    margin-left: -25px;
    margin-right: -25px;
  }
}

