@import "./../../../../../styles/_variables.scss";
@import "./../../../../../styles/_mixins.scss";

html.noscroll {
  overflow: hidden;
}

body.noscroll {
  overflow: visible;
  height: 100%;
  position: fixed; //To fix Iphone scroll issue
  width: 100%;
}

.side-drawer-container.closed {
  position: static;

  .overlay {
    display: none;
  }

  #right-side-drawer {
    width: 0px;
    position: static;
  }
}

.side-drawer-container {
  height:100%;
  width: 100%;
  // max-width: 360px;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: absolute;
  // float: right;
  top: $header-height-mobile + $header-line-height;
  z-index: 200; 
  display: flex;
  justify-content: space-between;

  /* These 2 below for menu scroll handling: */
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;

  @include media-breakpoint-up(md) {
    top: $header-height-tablet + $header-line-height;
    //z-index: 80;
  }

  .overlay {
    position: absolute;
    left: 0px;
    z-index: -1;

    width: 100%;
    height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  #left-side-drawer {
    height: 100%;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
    width: 100%;
    // float: left;

    .left-content-holder {
      min-width: 280px;
      height: 100%;
      width: 95%;
      max-width: 380px;
      overflow-y: scroll;
      background: white;
      padding: 0px 0px;
    }

    .left-drawer-close-button {
      padding: 5px 10px;
      min-width: 40px;
      color: white;
      font-size: 28px;
      font-weight: 400;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  #right-side-drawer {
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0px;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
    // float: right;

    .right-content-holder {
      min-width: 280px;
      width: 95%;
      max-width: 380px;
      height: 100%;
      overflow-y: scroll;
      background: white;
      padding: 0px 0px;
    }

    .right-drawer-close-button {
      padding: 5px 10px;
      color: white;
      font-size: 28px;
      min-width: 40px;
      font-weight: 400;
      font-family: Arial, Helvetica, sans-serif;
    }
  }
}

.side-drawer-container.left-open {
  position: absolute;

  #left-side-drawer {
    transform: translateX(0);
  }

  .overlay {
    opacity: 1;
  }
}

.side-drawer-container.right-open {
  position: absolute;

  #right-side-drawer {
    transform: translateX(0);
  }

  .overlay {
    opacity: 1;
  }
}
