.upload-icon-green {
  height: 70px !important;
  width: 53px !important;
}

.text-color-grey1 {
  color: #657493 !important;
}

.text-color-solidgreen3 {
  color: #038062 !important;
}

.background-color-light-bluegrey {
  background-color: rgb(239, 243, 245);
}

.border-color-solidgreen2 {
  border-color: rgb(0, 200, 133) !important;
  border-style: solid !important;
}

@media (max-width: 575.98px) {
  .status p {
    font-size: 13px !important;
  }
  .tab-list {
    padding-left: 20px !important;
  }
  .tab-list-item {
    padding: 0.5rem 0.5rem !important;
  }
  .scrollable-div {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .case-doc {
    margin-left: 5px !important;
  }
}
@media (max-width: 767.98px) {
  .scrollable-div {
    height: 61px !important;
  }
}
@media (max-width: 460px) {
  .tab-list-item {
    padding: 0.3rem 0.3rem !important;
    font-size: 9px;
  }
}
.status p {
  font-size: 14px;
  font-weight: 600;
}

.tabs {
  margin-top: 5px;
}

.tab-list {
  clear: both;
  content: "";
  display: table;
  padding-left: 30px;
}

.tab-list-item {
  background-color: #fff;
  border: 2px solid #dae3e8;
  border-right: none;
  border-width: 2px;
  color: #657493;
  cursor: pointer;
  display: inline-block;
  padding: 0.625rem 1.25rem;
  position: relative;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
.tab-list-item:last-of-type {
  border-right: 2px solid #dae3e8;
}

.tab-list-active {
  color: #038062;
  border: 2px solid #038062;
  font-weight: 600;
}

.scrollable-div {
  font-family: "Source Sans Pro", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  margin-left: 30px;
  margin-right: 30px;
  border: 1px solid #dae3e8;
  background-color: #fff;
  padding-top: 10px;
  line-height: 1.7;
  overflow-y: scroll;
  height: 70px;
  scroll-behavior: smooth;
}

.case-doc {
  color: black !important;
  margin-left: 15px;
}

.icons {
  font-size: 28px;
}

