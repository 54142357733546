// Override Bootstrap Theme...

$primary:     #34A350;
$secondary:   #FF5B0B;
$dark:        #333333;
$light:       #E4E4E4;
$info:        #0097ab;
$danger:      #ff2f6d;

$gray-1:      #F0F0EE;
$gray-2:      #CCCCCC;
$gray-3:      #dae3e8;
$gray-4:      #657493;

$button_Green:#4aa574;
$text-Blue:#6b61f2;
$status-Header:#00a870;
$header-Color:#28373a;
$alert:#ff2f6d;
$notification:#0097ab;
$header-active:#eff3f5;
$box-border: #5ac48a;
$home-button: #038062;


//light bluegrey
$light-bluegrey: rgba(239, 243, 245, 1);
//new solid medium light green
$solidgreen-2: rgba(0, 200, 133, 1);
//new solid dark green
$solidgreen-3:  #038062;
$solidgreen-4: rgba(0, 168, 112, 1);
//base solid green
$solidgreen: rgb(52, 163, 80);
//new violet
$violet-1: #6B61F2;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  // "success":    $success,
  "info":       $info,
  // "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);


$header-height-mobile: 80px;
$header-height-tablet: 76px;
$header-line-height: 0px;

// Bootstrap Default        (healthypaws styleguide equivalent)
// $grid-breakpoints: (
//   xs: 0,     // Portrait Phone     
//   sm: 576px, // > Landscape Phones   (320)
//   md: 768px, // > Tablets            (768)
//   lg: 992px, // > Desktops           (960)
//   xl: 1200px // > Large Desktops     (1170)
// ) !default;
