.status .fa-check-circle {
  font-size: 28px;
}
.status .status-info {
  font-size: 18px;
  font-weight: 700;
  padding-left: 8px;
}
.status .status-text {
  font-size: 18px;
  color: white;
  font-weight: 400;
}

.review-policy {
  padding: 30px 15px;
}
.review-policy a {
  text-decoration: none;
  font-weight: 700;
}
.review-policy a:hover {
  text-decoration: underline;
}
.review-policy .veterinary-exam, .review-policy .new-pet {
  border: 2px solid rgb(228, 228, 228);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 25px;
}
.review-policy .refer-friend {
  margin-top: 0;
  margin-bottom: 60px;
}
.review-policy .add-another-pet-btn {
  height: 40px;
  margin-bottom: 20px;
}
.review-policy .new-pet .pet-name {
  font-size: 24px;
  font-weight: bold;
}
.review-policy .new-pet .tag {
  background-color: #34A350;
  padding: 2px 8px 2px 5px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 13px;
  margin: 0;
  margin-left: 15px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 5px;
  position: relative;
}
.review-policy .new-pet .tag:before {
  content: "";
  float: left;
  position: absolute;
  top: 0;
  left: -7px;
  width: 0;
  height: 0;
  border-color: transparent #34A350 transparent transparent;
  border-style: solid;
  border-width: 12px 10px 11px 0;
}
.review-policy .new-pet .table-borderless th, .review-policy .new-pet .table-borderless td {
  padding: 2px;
}
.review-policy .new-pet ul {
  padding-inline-start: 16px;
  font-size: 14px;
}
@media (min-width: 992px) {
  .review-policy {
    padding-top: 0 !important;
  }
}

