@import "../../../styles/variables";
@import "../../../styles/mixins";

.refer-friend {
    border: 2px solid $light;
    background: $gray-1;
    border-radius: 8px;
    
    img {
        cursor: pointer;
    }

    //TODO: Check in other pages.
    // margin: 30px;
    .header {
        padding: 15px;
        font-size: 23px;
        font-weight: 400;
    }
    .body {
        background: #ffffff;
        padding: 12px;
    }
    .footer {
        padding: 15px;
    }
}