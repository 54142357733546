@import "../../../styles/variables";
@import "../../../styles/mixins";


 
    .modal-dialog {
        top: 0px !important;
    }
 

.pet-form {




    .why-important-mobile-text-container {
        width: 100%;
        float: left;
        padding-bottom: 55px;
    }

    .why-important-mobile-text {
        min-height: 20px;
        float: left;
    }

    .submit-button-container {
        width: 100%;
    }

    .submit-button-text {
        float: left;
    }

    .next-arrow-circle-right-custom {
        height: 25px;
        margin-left: 10px;
        padding-bottom: 1px;
    }

    .fa-arrow-circle-right {
        background-color: transparent;
        margin-left: 10px;
    }

    .fa-question-circle-o {
        font-size: 20px !important;
        float: left;
        margin-left: 10px;
    }

    h1 {
        font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
        font-weight: 400 !important;
        font-style: normal !important;
        font-size: 36px !important;
        color: #28373A !important;
        @include media-breakpoint-down(md){
            font-size:21px !important;
        }
    }
    h4{
        font-size:18px;
    }
    .dropdown-container{
        color:#999;
    }

    padding: 30px 15px;

    .why-important {
        font-size: 13px;
        color: #6B61F2 !important;
    }

    .error {
        margin: -10px 0 10px 0;
        font-size: 12px;
        font-weight: 650;
    }

    .dropdown-disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    .noQuoteMessage {
        font-size: 18px;
        font-weight: 650;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 0 !important;
    }
}