.status-header-container {
  background-color: #00a870;
}
.status-header-container #status-header {
  height: 42px;
  font-size: 14px;
  color: white;
  font-weight: 400;
  margin: auto;
}

