@import "./../../../../styles/_variables.scss";

.radio_container {
  display: flex;
  position: relative;
  padding-left: 40px;
  margin-right: 30px;
  height: 28px;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  color: $dark;
  line-height: normal;
}

input[type="radio"] {
  display: none;
}

/* Create a custom radio button */

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 32px;
  width: 32px;
  background-color: #FFFFFF;
  border-radius: 50%;
  border-width: 3px;
  border-style: solid;
  border-color: #D3D3D3;
}

/* On mouse-over, add a grey background color */

.radio_container:hover input~.checkmark {
  // background-color: #CCCCCC;
}

/* When the radio button is checked, add a blue background */

.radio_container input:checked~.checkmark {
  background-color: #FFFFFF;
}

/* Create the indicator (the dot/circle - hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */

.radio_container input:checked~.checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */

.radio_container .checkmark:after {
  top: 4px;
  left: 4px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: $primary;
}