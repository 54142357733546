@import "../../../styles/variables";
@import "../../../styles/mixins";

#chatButtons {
    display:none;
}

@include media-breakpoint-up(md) { 
    #chatButtons {
        display:block;
        position: fixed;
        bottom: 5px;
        right: 5px;
        z-index: 9999;
    }
}