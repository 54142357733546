#chatButtons {
  display: none;
}

@media (min-width: 768px) {
  #chatButtons {
    display: block;
    position: fixed;
    bottom: 5px;
    right: 5px;
    z-index: 9999;
  }
}

