@import "../../../styles/variables";
@import "../../../styles/mixins";

.modal-header-close-button {
    display: none !important;
}

.confirm-charges {

    .fa-pencil {
        font-size: 28px;
        float: right;
    }

    .pencil-edit-custom {
        height: 28px;
        float: right;
    }

    .next-arrow-circle-right-custom {
        height: 25px;
        margin-left: 10px;
        padding-bottom: 1px;
    }

    color: #28373A !important;


    h1, h2 {
        font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
        font-weight: 400;
        font-style: normal !important;
        font-size: 36px !important;
        color: $header-Color !important;
        @include media-breakpoint-down(md){
            font-size:21px !important;
        }
    }
    .terms{
        font-size:24px !important;
        @include media-breakpoint-down(md){
            font-size: 14px !important;;
        }
    }

    .text-dark {
        color: #28373A !important;
    }

    .text-medium {
        color: #8D8D8D !important;
    }

    

    padding: 30px 15px 0 15px;

    .summary-content {
        font-weight: normal;
    }

    .billing-summary {
        border: 2px solid rgba(228, 228, 228, 1);
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 25px;

        .summary-list {
            padding-inline-start: 20px;
        }
    }

    .terms-conditions {
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 25px;
        height: 88px;
    }

    .agreement {
        margin-bottom: 25px;
    }

    .disabled-btn {
        background: $gray-3;
        pointer-events: none;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 0 !important;
    }
}

@include media-breakpoint-up(md) {
    .confirm-charges {
        padding: 30px 15px;
        
        .terms-conditions {
            height: 44px;
        };
    }
}