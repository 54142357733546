@import "../../../styles/variables";
@import "../../../styles/mixins";

.pet-policy-rate {
  .rate-content {
    border: 2px solid $gray-3;
    border-top-width: 0;
    margin: -2px !important;
  }

  .text-primary{
    color: $home-button !important;
    font-family: "OpenSans-SemiBold", "Open Sans SemiBold", "Open Sans", sans-serif;
  }
  .text-secondary{
    color: $text-Blue !important;
    font-family: "OpenSans-SemiBold", "Open Sans SemiBold", "Open Sans", sans-serif;
    font-weight: 650;
  }
  .text-dark{
    color:$header-Color !important;
    font-weight: 700;
  }
  .breedAgeText{
    color:$header-Color !important;
    font-weight: 400;
  }
  .rate-sub-content {
    padding: 20px 15px;
    color: $dark;

    .segment {
      min-width: 33.33%;
      max-width: 33.33%;
    }

    &.gray {
      background-color: #f7f7f7;
    }

    .limited-options-toggler {
      font-size: 12px;
      p {
        font-weight: 400;
      }
    }

    .notice {
      font-weight: 700;
      color: $alert;
    }
  }
}

@include media-breakpoint-up(md) {
  .pet-policy-rate {
    .rate-content {
      border-top-width: 2px;
    }

    .rate-sub-content {
      padding: 20px;
      .arrow_box {
        position: relative;
        background: transparent;
        border-right: 2px solid $gray-3;
        font-size: 14px;

        &:after,
        &:before {
          left: 100%;
          top: 50%;
          border: solid $gray-3;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(136, 183, 213, 0);
          border-left-color: #f7f7f7;
          border-width: 9px;
          margin-top: -9px;
          margin-left: -1px;
        }
        &:before {
          border-color: rgba(0, 0, 0, 0);
          border-left-color: $gray-3;
          border-width: 11px;
          margin-top: -11px;
        }
      }
      .segment {
        min-width: unset;
        max-width: 80px;
        max-height: 40px;
      }
      .link-text,
      .options-label {
        font-size: 14px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .pet-policy-rate {
    margin-bottom: 25px;

    .rate-content {
      border-radius: 8px;
    }

    .segment {
      max-width: 80px;
      min-width: unset;
    }
  }
}
