@import "../../../styles/variables";
@import "../../../styles/mixins";

.no-enrollments {

    .sorry {
        margin-top:15px;
    }

    @include media-breakpoint-up(lg) {
        padding-top:0 !important;
    }

    .back-to-home {
        margin-bottom: 16px;
        width:255px;
    }
}