@import "../../../styles/variables";
@import "../../../styles/_mixins.scss";

.pet-selector {
  flex-direction: row;
  align-items: center;
  margin: 10px 0;

  .truncate {
    display: block;
    max-width: 85px;

    @include media-breakpoint-up(md) {
      max-width: 150px;
    }
  }

  .pet {
    position: relative;
    margin: 10px;
    .pet-img-container {
      border: 4px solid $primary;
      border-radius: 50%;
      height: 80px;
      width: 80px;
      background: $gray-1;

      @include media-breakpoint-up(md) {
        height: 150px;
        width: 150px;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: inherit;
      }
    }

    p {
      text-align: center;
      font-weight: 700;
      text-transform: capitalize;
      margin: 0;
    }
  }

  .disabled {
    .pet-img-container {
      filter: opacity(0.2);
    }

    p {
      color: $gray-3;
    }
  }

  .image-selected-tick {
    position: absolute;
    bottom: 25px;
    right: 2px;
    background-color: #34A350;
    height: 21px;
    width: 21px;
    border-radius: 14px;
    text-align: center;

    @include media-breakpoint-up(md) {
      bottom: 41px;
      right: 9px;
      height: 23px;
      width: 22px;
    }
  }

  .pet-chip {
    height: 30px;
    border-radius: 80px;
    background: #ffffff;
    border: 2px solid $primary;
    font-weight: 700;
    margin: 5px;
    position: relative;
    font-size: 16px;

    .pet-name {
      width: 60px;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin: 0 15px;
      
      @include media-breakpoint-up(md) {
        width: 105px;
        margin: 0 26px;
      }

    }

    @include media-breakpoint-up(md) {
      font-size: 22px;
      width: 165px;
      height: 40px;
      padding: 0 4px;
      margin: 10px;
    }
  }

  .chip-selected {
    background: $primary;
    color: #ffffff;
  }

  .chip-selected-tick {
    display: none;

    @include media-breakpoint-up(md) {
      display: inline-block;
      position: absolute;
      width: 30px;
      height: 30px;
      top: 0px;
      right: -4px;
    }
  }
}
