@import "./../../../../styles/_mixins.scss";
@import "./../../../../styles/_variables.scss";
.segment-control {
    .segment {
        flex: 1;
        background-color: rgba(255, 255, 255, 1);
        box-sizing: border-box;
        border-width: 2px;
        border-style: solid;
        border-color: #00c885;
        box-shadow: none;
        font-weight: 700;
        font-size: 16px;
        color: $home-button;
        cursor: pointer;
    }

    .segment-content {
        width: 100%;
        height: 44px;
        position: relative;
        text-align: center;
    }

    .segment-icon {
        width: 34px;
        height: 27px;
        margin-right: 5px;
    }

    .segment-info {
        font-size: 9px;
        line-height: normal;
        margin: 0;
        position: absolute;
        top: 2px;
        font-weight: 600;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    .segment-label {
        font-size: 20px;
        line-height: 45px;
        margin: 2px;
    }

    .active {
        background-color: $button_Green;
        border-width: 2px;
        color: #ffffff;
        border-color: #4aa574 !important;
    }

    .disabled {
        background-color: #fff;
        border-width: 1px;
        border-color: #cccccc;
        box-shadow: none;
        color: #cccccc;
    }

    .invisible {
        visibility: hidden;
    }

    .segment:first-child {
        /* border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;*/

        border-width: 2px;
        border-style: solid;
        border-color: #00c885;
        border-radius: 20px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
    }

    .segment:last-child {
        border-width: 2px;
        border-style: solid;
        border-color: #00c885;
        border-radius: 20px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
}

@include media-breakpoint-down(sm) {
  .invisible {
    display: none !important;
  }
  .segment-control {
    &.triplet{
      flex-wrap: wrap;
      margin-top: 1px;

      .segment {
        border-radius: 0;
        margin-top: -1px;
        margin-left: -1px;
        flex: 1 0 33.33%;

        &:first-child{
          border-top-left-radius: 8px;
        }
        &:last-child{
          border-bottom-right-radius: 8px;
        }
        &.lbr{
          border-bottom-left-radius: 8px;
        }
        &.rbr{
          border-bottom-right-radius: 8px;
        }
        &.rtr{
          border-top-right-radius: 8px;
        }
      }
    }
  }
}
