.text-color-grey1 {
  color: #657493 !important;
}

h4.text-color-solidgreen3 {
  color: #038062 !important;
  font-size: 16px !important;
  font-weight: 700 !important;
}

.text-color-violet1 {
  color: #6B61F2 !important;
}

.claim-submit-success-container .reimbrusement-info {
  font-weight: 400;
  font-style: italic;
}
.claim-submit-success-container .clam-success-margin-bottom {
  margin-bottom: 0px;
}
@media (min-width: 768px) {
  .claim-submit-success-container .clam-success-margin-bottom {
    margin-bottom: 25px;
  }
}

