.retry-modal-footer-btn {
  width: 100%;
}
@media (min-width: 768px) {
  .retry-modal-footer-btn {
    width: 40%;
  }
}

@media (min-width: 768px) {
  .retry-billing-button {
    margin-left: 10px;
  }
}

