.view-profile {
  padding: 20px 15px;
  overflow-wrap: break-word;
}
.view-profile .page-title {
  font-size: 24px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .view-profile .page-title {
    font-size: 30px;
    margin-bottom: 25px;
  }
}
@media (min-width: 992px) {
  .view-profile .page-title {
    margin-bottom: 0px;
    font-size: 36px;
  }
}
.view-profile .justify-content-between .text-secondary {
  content: url(../../../public/assets/images/edit_icon.svg);
}
.view-profile .view-profile button {
  font-weight: 700;
}
.view-profile .card-wrapper {
  padding: 0px 10px 10px 10px;
  border: 2px solid #E4E4E4;
  margin-bottom: 10px;
  border-radius: 10px;
  overflow-wrap: break-word;
  flex-grow: 1;
}
.view-profile .card-wrapper a:hover {
  color: #FF5B0B !important;
}
.view-profile .card-wrapper .alert-box {
  border: 1px solid #ff2f6d;
  font-size: 12px;
}
@media (min-width: 768px) {
  .view-profile .card-wrapper {
    padding: 25px;
  }
}
@media (min-width: 992px) {
  .view-profile {
    padding-top: 0 !important;
  }
}
.view-profile .mobile-section-head {
  width: 100%;
}
.view-profile .mobile-section-head h2 {
  color: #28373a;
}
.view-profile .profile-font-icons {
  font-size: 40px;
}
.view-profile .section-header {
  margin-bottom: 15px;
}
.view-profile .section-header h2, .view-profile .section-header i {
  color: #28373a !important;
}
.view-profile a {
  text-decoration: none;
}

