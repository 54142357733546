@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";

.success-view{
    min-height: 60vh;
    .unBoldv2{
        color:  #657493 !important;
        font-weight: 100;
        padding-bottom: 4px;
    }
    .h1_v2{
        font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
        color: #28373a !important;
        line-height: normal;
        font-size: 21px;
        font-weight: 400;
        margin-bottom: 0;
        margin-top: -70px;
      }
      .mobileTextV2{
        font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
        color: #657493 !important;
        font-size: 14px;
      }
    .content-wrapper {
        padding: 22px 20px;
        border: 2px solid #E4E4E4;
        border-radius: 10px;
        overflow-wrap: break-word;
    }
    .fs-12{
        font-size: 12px;
    }

    @include media-breakpoint-up(md) {
        .update-success{
            padding-bottom: 0;
            min-height: 60vh;
        }
      }

      @include media-breakpoint-up(lg) {
        .update-success{
            padding-bottom: 50px;
            min-height: 50vh;
        }
      }
    
      @include media-breakpoint-up(xl) {
        .update-success{
            padding-bottom: 50px;
            min-height: 70vh;
        }
      }

    span.keep-in-mind {
        font-size: 13px;
        font-weight:400;
    }
    h4.keep-in-mind{
        font-size:13px;
        font-weight:600;
        text-transform: unset;
    }

    .margin-bottom {
        margin-bottom: 20px;
    }
    h4{
        font-size:12px;
        font-weight: 400;
        text-transform: uppercase;
    }
    span,li {
        font-size: 14px;
        font-weight:600;
        

        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
    }
}