.refer-friend {
  border: 2px solid #E4E4E4;
  background: #F0F0EE;
  border-radius: 8px;
}
.refer-friend img {
  cursor: pointer;
}
.refer-friend .header {
  padding: 15px;
  font-size: 23px;
  font-weight: 400;
}
.refer-friend .body {
  background: #ffffff;
  padding: 12px;
}
.refer-friend .footer {
  padding: 15px;
}

