@import "./../../../../styles/_variables.scss";
.edit-text-containerV2 {
    .demo-form-group {
        position: relative;
        height: 55px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid #8D8D8D;
        margin-bottom: 0;
        border-width: 2px;
        border-style: solid;
        border-color: rgba(101, 116, 147, 1);
        border-radius: 16px;
    }

    .demo-form-group:focus-within {
        border: 2px solid rgba(74, 165, 116, 1);
    }


    .secure-field {
        margin-right: 40px !important;
        text-overflow: unset;
    }

    .demo-form-group-normal {
        input {
            width: 100%;
            height: 100%;
            margin-left: 10px;
            margin-right: 10px;
            box-sizing: border-box;
            box-shadow: none;
            outline: none;
            border: none;
            background-color: transparent;
            font-weight: 400;
            font-size: 20px;
            color: $dark;

        }

        input::-webkit-input-placeholder {
            font-weight: 400;
            font-size: 20px;
            color: rgb(153, 153, 153);
        }

        input:-moz-placeholder {
            /* Firefox 18- */
            font-weight: 400;
            font-size: 20px;
            color: rgb(153, 153, 153);
        }

        input::-moz-placeholder {
            /* Firefox 19+ */
            font-weight: 400;
            font-size: 20px;
            color: rgb(153, 153, 153);
        }

        input:-ms-input-placeholder {
            font-weight: 400;
            font-size: 20px;
            color: rgb(153, 153, 153);
        }

        input:focus::-webkit-input-placeholder {
            color: transparent;
        }

        input:focus:-moz-placeholder {
            color: transparent;
        }
        /* FF 4-18 */
        input:focus::-moz-placeholder {
            color: transparent;
        }
        /* FF 19+ */
        input:focus:-ms-input-placeholder {
            color: transparent;
        }
        /* IE 10+ */
    }

    .error {
        font-weight: 700 !important;
        font-size: 12px !important;
        color: $danger;
        text-align: left;
        margin: 1px 0px 0px 2px !important;
    }

    .red-label {
        color: $danger !important;
        font-weight: 700;
    }

    [placeholder] {
        text-overflow: ellipsis;
    }

    .floating-label {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        padding-right: 20px;
    }

    .demo-form-group-float {

        input[type="text"],
        input[type="password"] {
            width: 100%;
            height: 45px;
            margin-top: -5px;
            padding-top: 13px;
            padding-left: 10px;
            padding-right: 10px;
            box-sizing: border-box;
            box-shadow: none;
            outline: none;
            border: none;
            background-color: transparent;
            font-weight: 400;
            font-size: 20px;
            color: $dark;

        }
        // <label> should Follow <input>
        input + label {
            position: absolute;
            left: 10px;
            top: 13px;
            pointer-events: none;
            font-weight: 400;
            font-size: 20px;
            color: rgb(153, 153, 153);
            transition: 0.5s;
            
        }

        .floating-label-with-value,
        input:focus ~ label,
        input:focus ~ label {
            position: absolute;
            left: 10px;
            top: -10px;
            pointer-events: none;
            font-weight: 700;
            font-size: 12px;
            /*color: #0070C9;*/
            color: $gray-3;
            transition: 0.5s;
            background-color: #FFFFFF;
            width:auto;
            padding-left:5px;
            padding-right:5px;
            padding-top: 1px;
            padding-bottom: 1px;
        }

        .floating-label-with-value-font {
         /*font-weight: 400;
            font-size: 12px;
            color: #000000;*/
        }
    }

    .demo-form-group:focus-within ~ div {
        display: flex !important;
    }

    .note-view {
        width: 100%;
        height: auto;
        margin-top: 5px;
        padding: 10px;
        background-color: #00000000;
        box-sizing: border-box;
        border-width: 2px;
        border-style: solid;
        border-color: rgba(0, 112, 201, 1);
        border-radius: 0px;
        font-weight: 400;
        font-size: 14px;
        color: #0070C9;
    }

    .note-icon {
        margin-right: 10px;
        font-size: 18px;
    }
}