.approve-address {
  display: block;
}
.approve-address .title-container {
  color: #28373a;
}
.approve-address .editButton {
  margin-left: 10px;
  vertical-align: top;
  width: 23px;
}
.approve-address .h1_v2 {
  font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
  color: #28373a !important;
  line-height: normal;
  font-size: 21px;
  font-weight: 400;
  margin-bottom: 0;
}
.approve-address .h3_v2 {
  font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
  color: #28373a !important;
  line-height: normal;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0;
}
.approve-address .mobileTextV2 {
  font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
  color: #657493 !important;
  font-size: 14px;
}
@media (min-width: 992px) {
  .approve-address {
    min-height: 60vh;
  }
}
.approve-address .content-wrapper, .approve-address .content-wrapper-bottom {
  padding: 15px 15px;
  border: 2px solid #E4E4E4;
  height: auto;
  border-radius: 10px;
  overflow-wrap: break-word;
}
.approve-address .margin {
  margin: 15px;
}
.approve-address .button-secondary {
  border-width: 1px;
}
.approve-address .fs-12 {
  font-size: 12px;
}
.approve-address .fw-300 {
  font-weight: 300;
}
.approve-address h3 {
  color: #28373a;
}
.approve-address h4 {
  margin-bottom: 0px;
  line-height: 1;
  color: #657493;
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
}
.approve-address p {
  margin-bottom: 0px;
}
.approve-address .calendar-icon {
  font-size: 22px;
}
.approve-address .term-text {
  font-size: 14px;
  font-weight: 700;
  color: #28373a;
}
@media (min-width: 768px) {
  .approve-address .term-text {
    font-size: 24px;
  }
}
.approve-address .textarea-border {
  border: 1px solid #333333;
  height: 54px;
}
@media (min-width: 768px) {
  .approve-address .textarea-border {
    height: 46px;
  }
}
.approve-address .rate-table-head-row {
  height: 45px;
  margin-right: 0px;
  margin-left: 0px;
}
@media (min-width: 768px) {
  .approve-address .rate-table-head-row {
    height: 40px;
  }
}
.approve-address .rate-table-row {
  height: 60px;
  margin-right: 0px;
  margin-left: 0px;
}
@media (min-width: 768px) {
  .approve-address .rate-table-row {
    height: 50px;
  }
}
.approve-address .calendar-text {
  font-size: 12px;
}
@media (min-width: 768px) {
  .approve-address .calendar-text {
    font-size: 14px;
  }
}
.approve-address .rate-table-column {
  padding-right: 0px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.approve-address .content-wrapper-top {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.approve-address .content-wrapper-bottom {
  border-top: none;
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding: 0;
}
.approve-address .bg-row {
  background-color: #f5fafb;
  border-bottom: 3px solid #e4e4e4;
  text-align: center;
}
.approve-address .bg-rowv2 {
  background-color: #f5fafb;
  border-bottom: 3px solid #e4e4e4;
  text-align: center;
}
.approve-address .bg-dark-row {
  background-color: #dae3e8;
}
.approve-address .bg-dark-rowv2 {
  background-color: #dae3e8;
}
.approve-address .bg-row-success {
  background-color: #dae3e8;
  text-align: center;
}
.approve-address .bg-dark-row-success {
  background-color: rgba(25, 166, 74, 0.1490196078);
}
.approve-address .flex-1 {
  flex: 1;
}
@media (min-width: 768px) {
  .approve-address .flex-1 {
    flex: unset;
  }
}
.approve-address .btn-w-14 {
  width: auto;
  height: 30px;
}
@media (min-width: 768px) {
  .approve-address th {
    text-align: center;
  }
  .approve-address table {
    margin-bottom: 0;
  }
  .approve-address .bg-row-success {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  .approve-address .pl-22 {
    padding-left: 22px;
  }
  .approve-address #legalContents {
    overflow: auto;
    resize: vertical;
    font-size: 12px;
  }
}
.approve-address #legalContents {
  overflow: auto;
  resize: vertical;
}
@media (min-width: 992px) {
  .approve-address .content-wrapper-top, .approve-address .content-wrapper-bottom {
    border: 2px solid #E4E4E4;
    border-radius: 10px;
  }
  .approve-address .content-wrapper-bottom {
    padding-top: 22px;
    height: 270px;
  }
}
@media (min-width: 992px) and (min-width: 768px) {
  .approve-address .content-wrapper-bottom {
    padding-top: 15px;
    height: auto;
  }
}
@media (min-width: 992px) {
  .approve-address .bg-row {
    border-bottom: 3px solid #ffffff;
  }
}
.approve-address .address-text {
  font-size: 16px;
  font-weight: 600;
  color: #28373a;
}

