@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";


#table-component {
  margin: 5px; 

  img {
    height: 100%;
    width: auto;
  }

  .table-component-header {
    display: flex;
    flex-direction: row;
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 5px;

    @include media-breakpoint-up(md) {
      font-size: 14px;
    }
  }

  .pagination-container{
    padding:15px 0px;
    height:32px;   
    user-select: none;
  }


  .pagination-text-span {
    cursor: pointer;
  }

  .pagination-text-span-disabled {
    pointer-events: none;
    color:$gray-2;
  }

  .no-padding-cell-content-mobile {
    padding-right: 0px !important;
    padding-left: 0px !important;
    @include media-breakpoint-up(lg) {
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
  }

  .table-component-striped-table {
    border: 2px solid $light;

    .table-component-item {
      border-bottom: 2px solid $light;
      padding: 10px 0;

      .slideup,
      .slidedown {
        max-height: 0;
        overflow-y: hidden;
        -webkit-transition: max-height 0.5s ease-in-out;
        -moz-transition: max-height 0.5s ease-in-out;
        -o-transition: max-height 0.5s ease-in-out;
        transition: max-height 0.5s ease-in-out;
      }
      .slidedown {
        max-height: 60px;
      }
    }
    .table-component-item:last-child {
      border-bottom: none;
    }
    .table-component-item:nth-child(odd) {
      background-color: #f2f2f2;
    }
    .table-component-item:nth-child(even) {
      background-color: #ffffff;
    }
  }
}
