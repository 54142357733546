@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";

.status-header-container {
    background-color: $status-Header;

    #status-header {
        height: 42px;
        // padding-left: 20px;
        font-size: 14px;
        color: white;
        font-weight: 400;
        margin: auto;
    }
}