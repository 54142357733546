@import "../../../styles/variables";
@import "../../../styles/mixins";

.pet-form {
    padding: 30px 15px;

    .segment-label{
        img{
            @include media-breakpoint-down(md){
                display:none;
            }
        }
    }
    
    .why-important{
        font-size: 13px;
    }

    .error {
        margin: -10px 0 10px 0;
        font-size: 12px;
        font-weight: 650;
    }

    .dropdown-disabled {
        opacity: 0.3;
        pointer-events: none;
    }

    .dropdown-container{
        color:#333 !important;
        
    }
    .noQuoteMessage {
        font-size: 18px;
        font-weight: 650;
    }

    @include media-breakpoint-up(lg) {
        padding-top:0 !important;
    }
}