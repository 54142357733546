@import "../../../styles/variables";
@import "../../../styles/mixins";

.update-pet-joined-date {
    display: block;
    position: relative;

    .btn {
        background-color: $button_Green;
    }
}