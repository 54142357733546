@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.claim-form-box {
    border: 2px solid $gray-3;
    border-radius: 10px;
    background: #f5fafb;
    margin-bottom: 25px;
    width: 100%;

    .claim-form-header {
        color: $header-Color;
        padding: 15px 15px 10px 15px;
        font-size: 23px;
    }

    .claim-form-body {
        padding: 15px;
        background: #ffffff;
        .form-links {
            border-bottom: 1px solid $light;

            i {
                font-size: 20px;
                color: $header-Color;
            }

            a {
                padding-left: 10px;
                color: $secondary;
                font-weight: 700;
                text-decoration: none;
                word-break: break-all;
                word-break: break-word;
                max-width: inherit;
            }
    
            a:hover {
                // color: $secondary !important;
                text-decoration: underline;
                cursor: pointer;

                .truncate {
                    text-decoration: underline;
                }
            }
        }

        .form-info {
            margin-bottom: 22px;
            .subtitle {
                font-weight: 700;
                color: $gray-4;
                font-size: 12px;
            }
            .subtitle-heading {
                font-weight: 700;
                color: $header-Color;
            }
            a {
                color: $secondary;
                font-weight: 700;
                text-decoration: none;
            }
            a:hover {
                // color: $secondary !important;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .claim-form-footer {
        padding: 20px;
    }
}