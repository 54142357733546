@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";

.success-container {

    h4 {
        color: $gray-4;        
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 400;
        text-transform: uppercase;
    }

    .margin-bottom {
        margin-bottom: 20px;
        color: $dark;
        font-weight: 600;
    }

    span {
        font-size: 14px;

        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
    }
}