
@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";

.SpeedyClaims{
    padding:30px 0px;
    .main-content{
        .sub-content{
            width:100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position-x:80% ;
        }
        .heading{
            font-size:44px;
            color:#333;
            font-weight:normal;
        }
        .content{
            margin:40px 0px;
            
            .sub-Heading{
                font-size:24px;
                color:#333;
                margin-bottom:5px;
                span{
                    margin-right: 5px;
                }
            }
            .desc{
                font-size:16px;
                padding-left:25px;
            }
            .cta{
                background-color: #ff5b0b;
                border-radius:15px;
                color:#fff !important;
                display:block;
                padding:10px;
                font-weight: 700;
                width:50%;
                text-align:center;
                text-decoration: none;
                margin-left: 25px;
                cursor: pointer;

                &.TellMeMore{
                    background-color: transparent;
                    border:1px solid #ff5b0b;
                    color:#ff5b0b !important;
                }

                &:hover{
                    text-decoration: none !important;
                }
            }
        }
        
        
    }

    @include media-breakpoint-down(md){
        .main-content{
            padding-left:20px;
            padding-right:20px;
            .heading{
                font-size:30px;
                padding:0px 15px;
            }
            .sub-content{

                background-image: none !important;
                .content{
                    margin:15px;
                    .sub-Heading{
                        font-size:18px;
                        font-weight:bold;
                    }
                    .desc{
                        font-size:16px;
                    }
                    .cta{
                        width:100%;
                        font-size:16px;
                        margin-left:0px;
                    }
                }
                .imgDiv{
                    text-align: center;
                    .speedyClaimsImg{
                        width:70%;
                        margin:30px 0px;
                    }
                }
                
            }
        }
    }
}

.tellMeMoreModal{
    color: #303030;
    .row{
        margin-top: 15px;
    }
    .title, .bold{
        font-size: 18px;
        font-weight:bold;
    }
    .content{
        font-size:18px;
    
    }
    .modal-dialog.message-modal{
        max-width: 40% !important;
    }
    .message-header-container{
        color:#303030 !important;
    }
    .modal-header .close{
        margin-right:0px;
    }
    .modal-body{
        padding-right: 30px !important;;
    }
}

