@import "./../../../../styles/_mixins.scss";
@import "./input-range.scss";
.photo-cropper {
  background-color: #fff;

  .cropper-image-container {
    background-color: #000;
  }

  .range-container {
    width: 90%;
  }

  .zoom {
    line-height: 40px;
    padding: 10px;

    &.zoom-max {
      font-size: 24px;
    }
  }
}

@include media-breakpoint-up(md) {
  .range-container {
    width: 50% !important;
  }
}
