.raf-bottom-banner {
  background-color: rgb(239, 239, 237);
  width: 100%;
  position: fixed;
  bottom: -1px;
  z-index: 6;
}
.raf-bottom-banner #extole-banner-refer, .raf-bottom-banner #extole-banner-refer-mobile {
  cursor: pointer;
}
.raf-bottom-banner .collapse-icon {
  position: absolute;
  right: 15px;
  top: -8px;
}
.raf-bottom-banner .collapse-icon i {
  font-size: 30px;
}
.raf-bottom-banner .collapse-link {
  background-color: #34A350;
  text-align: center;
  padding: 8px;
}
.raf-bottom-banner .collapse-link .link-title {
  text-decoration: underline;
  color: #fff;
}
.raf-bottom-banner .collapse-link .collapse-icon {
  color: #fff;
}
.raf-bottom-banner .banner-dog {
  float: left;
  width: 190px;
}
.raf-bottom-banner .banner-dog-container {
  position: absolute;
  height: 175px;
  width: 190px;
  bottom: 0;
}
.raf-bottom-banner .banner-dog-container.collapsing {
  height: 0;
}
.raf-bottom-banner .collapse-content .container {
  padding: 16px 23px;
}
.raf-bottom-banner .collapse-content .container .collapse-icon {
  color: #767676;
  right: 23px;
  top: 10px;
}
.raf-bottom-banner .collapse-content .container .banner-dog {
  float: left;
  margin-top: -70px;
  width: 190px;
}
.raf-bottom-banner .collapse-content .container .copy-input {
  border: 1px solid #000;
  border-radius: 0;
  background-color: #fff;
}

@media (max-width: 767.98px) {
  .raf-bottom-banner {
    background-color: rgba(239, 239, 237, 0.9);
  }
  .raf-bottom-banner .collapse-content {
    border-top: 1px solid #FF5B0B;
    box-shadow: 0 -3px 0px 0px rgb(194, 194, 194);
    padding: 8px 23px;
  }
  .raf-bottom-banner .collapse-content .collapse-icon {
    color: #767676;
    right: 13px;
    top: 0;
    padding: 0 10px;
  }
}

