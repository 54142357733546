.status .fa-check-circle {
  font-size: 28px;
}
.status .status-info {
  font-size: 18px;
  font-weight: 700;
  padding-left: 8px;
}
.status .status-text {
  font-size: 18px;
  color: white;
  font-weight: 400;
}

.secondar-button {
  height: 40px !important;
}

.review-policy {
  padding: 30px 15px;
}
.review-policy h1.text-primary, .review-policy h2.font-weight-bold {
  color: #28373a !important;
}
.review-policy .text-blue {
  color: #6b61f2;
  font-size: 14px;
}
.review-policy a {
  text-decoration: none;
  font-weight: 700;
}
.review-policy a:hover {
  text-decoration: underline;
}
.review-policy .new-pet {
  border: 2px solid #dae3e8;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 25px;
  color: #28373a;
}
.review-policy .veterinary-exam {
  border: 2px solid #4aa574;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 25px;
  color: #4aa574;
}
.review-policy .refer-friend {
  margin-top: 0;
  margin-bottom: 60px;
}
.review-policy .add-another-pet-btn {
  height: 40px;
  margin-bottom: 20px;
}
.review-policy .new-pet .table-view {
  max-width: 75%;
}
@media (max-width: 991.98px) {
  .review-policy .new-pet .table-view {
    max-width: 100%;
  }
}
.review-policy .new-pet .table-view .title {
  font-size: 12px;
  color: #657493;
  text-transform: capitalize;
}
.review-policy .new-pet .table-view .content {
  font-size: 16px;
  color: #28373a;
  font-weight: 650;
}
.review-policy .new-pet .pet-name {
  font-size: 24px;
  font-weight: bold;
}
.review-policy .new-pet .tag {
  background-color: #34A350;
  padding: 2px 8px 2px 5px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  font-size: 13px;
  margin: 0;
  margin-left: 15px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 5px;
  position: relative;
}
.review-policy .new-pet .tag:before {
  content: "";
  float: left;
  position: absolute;
  top: 0;
  left: -7px;
  width: 0;
  height: 0;
  border-color: transparent #34A350 transparent transparent;
  border-style: solid;
  border-width: 12px 10px 11px 0;
}
.review-policy .new-pet .table-borderless th, .review-policy .new-pet .table-borderless td {
  padding: 2px;
}
.review-policy .new-pet ul {
  padding-inline-start: 16px;
  font-size: 14px;
}
@media (min-width: 992px) {
  .review-policy {
    padding-top: 0 !important;
  }
}

