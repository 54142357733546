.pet-selector {
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}
.pet-selector .truncate {
  display: block;
  max-width: 85px;
}
@media (min-width: 768px) {
  .pet-selector .truncate {
    max-width: 150px;
  }
}
.pet-selector .pet {
  position: relative;
  margin: 10px;
}
.pet-selector .pet .pet-img-container {
  border: 4px solid #34A350;
  border-radius: 50%;
  height: 80px;
  width: 80px;
  background: #F0F0EE;
}
@media (min-width: 768px) {
  .pet-selector .pet .pet-img-container {
    height: 150px;
    width: 150px;
  }
}
.pet-selector .pet .pet-img-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: inherit;
}
.pet-selector .pet p {
  text-align: center;
  font-weight: 700;
  text-transform: capitalize;
  margin: 0;
}
.pet-selector .disabled .pet-img-container {
  filter: opacity(0.2);
}
.pet-selector .disabled p {
  color: #dae3e8;
}
.pet-selector .image-selected-tick {
  position: absolute;
  bottom: 25px;
  right: 2px;
  background-color: #34A350;
  height: 21px;
  width: 21px;
  border-radius: 14px;
  text-align: center;
}
@media (min-width: 768px) {
  .pet-selector .image-selected-tick {
    bottom: 41px;
    right: 9px;
    height: 23px;
    width: 22px;
  }
}
.pet-selector .pet-chip {
  height: 30px;
  border-radius: 80px;
  background: #ffffff;
  border: 2px solid #34A350;
  font-weight: 700;
  margin: 5px;
  position: relative;
  font-size: 16px;
}
.pet-selector .pet-chip .pet-name {
  width: 60px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0 15px;
}
@media (min-width: 768px) {
  .pet-selector .pet-chip .pet-name {
    width: 105px;
    margin: 0 26px;
  }
}
@media (min-width: 768px) {
  .pet-selector .pet-chip {
    font-size: 22px;
    width: 165px;
    height: 40px;
    padding: 0 4px;
    margin: 10px;
  }
}
.pet-selector .chip-selected {
  background: #34A350;
  color: #ffffff;
}
.pet-selector .chip-selected-tick {
  display: none;
}
@media (min-width: 768px) {
  .pet-selector .chip-selected-tick {
    display: inline-block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0px;
    right: -4px;
  }
}

