.getmobileapp {
  margin-bottom: 30px;
}
.getmobileapp .mob-img {
  height: 300px;
  overflow: hidden;
  text-align: center;
}
.getmobileapp .video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.getmobileapp .video-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-height: 450px;
}
.getmobileapp p {
  margin-left: 15px;
  color: #28373a;
}
.getmobileapp .img-fluid {
  margin-left: 15px;
}
.getmobileapp .consent-text {
  margin-left: 15px;
}
.getmobileapp h3 {
  color: #28373a;
  font-weight: 800;
  font-size: 22px;
  margin-left: 15px;
}
.getmobileapp h4 {
  color: #28373a;
  font-weight: 800;
  font-size: 22px;
}
@media (min-width: 768px) {
  .getmobileapp h4 {
    margin-left: -15px;
    margin-bottom: 15px;
    margin-top: 35px;
  }
}
.getmobileapp .video-img {
  margin-left: 15px;
  margin-bottom: 60px;
  width: 770px;
  height: 432px;
}
.getmobileapp .pl-40 {
  padding-left: 40px;
}
.getmobileapp .gray-box {
  background-color: #F5F5F5;
  border-radius: 12px;
  width: 100%;
}
.getmobileapp .app-benefits-list {
  margin-bottom: 0px;
  padding-inline-start: 17px;
}
.getmobileapp .app-benefits-item {
  vertical-align: top;
  padding-left: 10px;
  margin-bottom: 20px;
  color: #28373a;
}
.getmobileapp .app-benefits-item strong {
  color: #28373a;
  font-size: 16px;
  font-weight: 600;
}
@media (min-width: 768px) {
  .getmobileapp .app-benefits-list {
    margin-left: -15px;
  }
}
.getmobileapp .app-benefits-item::marker {
  font-family: FontAwesome;
  content: "\f058";
  font-size: 1.33333333em;
  line-height: 0.75em;
  color: #34A350 !important;
}
@media (max-width: 1199.98px) {
  .getmobileapp .video-container {
    padding-top: 56.25%;
  }
}

