@import "./../../styles/_variables.scss";
@import "./../../styles/_mixins.scss";

.pet-detail-container {
    height: auto;
    min-height: 100vh;
    margin-bottom: 15px;

    a {
        text-decoration: none !important;
    }

    .right-column {
        a:hover {
            color: $secondary !important;
            text-decoration: underline !important;
        }

        .info-body-padding {
            padding-top: 5px !important;
        }

        .billing-info {
            margin-bottom: 30px;
            overflow-wrap: break-word;
        }
    }

    .add-pet-button-mobile {
        margin-top: 20px;
        margin-bottom: 10px;
    }
    /* ==================================== Accordian & its Title CSS ====================================*/
    .accordion__body {
        display: block;
    }


    .accordion {
        border-left: 2px solid $light;
        border-right: 2px solid $light;
        border-bottom: 2px solid $light;
        border-radius: 8px;
        height: auto;
    }

    .accordion__body--hidden {
        display: none;
    }

    .accordian-title {
        height: 50px;
        cursor: pointer;
        font-weight: 600;
        font-size: 22px;
        display: table-cell;
        vertical-align: middle;

        @include media-breakpoint-up(md) {
            height: 50px;
            padding-left: 20px;
            padding-top: 10px;
            padding-bottom: 15px;
            font-weight: 700;
            font-size: 24px;
            color: $dark;
        }
    }

    .accordian-title-start {
        @include media-breakpoint-up(md) {
            padding-top: 20px;
            padding-left: 30px;
        }
    }

    .accordian-mobile-name {
        font-weight: 600;
        font-size: 22px;
        margin-top: 10px;
    }

    .accordian-title .accordian-sub-title {
        font-weight: 400;
        font-size: 16px;
        color: $dark;
        margin-top: 5px;
        align-items: flex-end;
    }
    /* ==================================== Accordian Detail Part CSS ====================================*/
    .accordian-container {
        height: auto;

        @include media-breakpoint-up(xl) {
            flex-direction: column;
        }
    }

    .avatar-editor {
        margin-top: 15px;

        @include media-breakpoint-up(md) {
            margin-top: 10px;
        }

        .avatar-container {
            width: 100px;
            height: 100px;

            @include media-breakpoint-up(md) {
                width: 165px;
                height: 165px;
            }
        }

        .edit-container {
            top: 0;
        }
    }

    .accordian_detail_section {
        margin-top: 15px;
    }

    .accordion_sub {
        border-top: 2px solid $light;
        position: relative;

        .show-view {
            position: absolute;
            top: 0px;
            right: 15px;
            cursor: pointer;

            @include media-breakpoint-up(md) {
                right: 25px;
            }

            @include media-breakpoint-up(lg) {
                right: 15px;
            }

            height: 50px;
            font-weight: 400;
            font-size: 16px;
            color: $text-Blue;

            span {
                height: 20px;
            }

            .show-icon {
                font-size: 45px;
                color: $header-Color;
                margin-left: 10px;

                @include media-breakpoint-up(md) {
                    font-size: 30px;
                }
            }
        }
    }


    .detail-table {
        width: 75%;
    }

    table {
        width: "100%";
    }


    .accordian_button_container {
        background-color: rgba(242, 242, 242, 1);
        padding: 28px;
    }

    .upload_medical_record_button {
        width: 251px;

        button {
            padding: 0;
            height: 40px;
        }
    }

    .submit-a-claim-buttom {
        width: 251px;
        margin-left: 15px;
    }

    .submit-a-claim-buttom-mobile {
        margin: 0px 15px 20px 15px;
    }

    .tos-privacy-link {
        font-size: 12px;
        text-decoration: underline !important;
        color: #666 !important;
    }
}

.form-content{
    padding:15px 30px;
    @include media-breakpoint-down(md)
    {
        padding:15px;
    }
    .description{
        font-size:16px;
        color:#333;
        font-weight: 600;
    }
    .required{
        font-size:12px;
    }
    .form-group{
        position:relative;

        
        input, select{
            border-radius: 0px;
            height:55px;
            border:1.5px solid;
            border-color: #8d8d8d;

            &:focus{
                border-color: #0070c9;
                box-shadow: none;
                
            }
        }
        
        select{
            font-size:20px;
            color:#999;
        }
        label{
            position: absolute;
            font-size:20px;
            color:#999;
            top:20%;
            left:2%;
            overflow: hidden;
            text-wrap: nowrap;
            width: 95%;
        }
        input[class="form-control filled"] + label{
            font-size: 12px;
            color:#0070c9;
            top:10%;
            
        }
        input[class="form-control filled"]{
            border-color:#0070c9;
        }
        &:focus-within label {
            font-size: 12px;
            color:#0070c9;
            top:10%;
        }
        .submit{
            font-size:18px;
            color:#fff;
            background-color:  #d3d3d3;
            font-weight:700;
            padding:5px 50px;
            border-radius:5px;
            border:none;
            width:max-content;
            display:inline-block;
            margin-right: 20px;
            height: fit-content;
            pointer-events:none;
            cursor: not-allowed;
        }
        .submit.enable{
            pointer-events:all;
            background-color: #ff5b0b;
            cursor: pointer;
        }
        .skip{
            border:none;
            color: #ff5b0b;
            width:max-content;
            display: inline-block;
        }

        @include media-breakpoint-down(md)
        {
            .submit, .skip{
                width:100%;
            }
        }
        
        &.width50{
            width:50%;
            @include media-breakpoint-down(md)
            {
                width:100%;
            }
        }
    }
    
}
.successMessage{
    
    text-align:right;
    p{
        padding-left: 15px;
        font-size:16px;
        color:#333;
        font-weight: 600;
        text-align: left;
    }
    button{
        padding:5px 0px;
        width:200px;
        border-radius:5px;
        border: 2px solid #ff5b0b;
        margin:15px;
        margin-right:10px;
        background-color: transparent;
        font-weight: 700;
       
    }
    .done{
        color:#fff;
        background-color: #ff5b0b;
        order:1;
        
    }
    .addMore{
        color:#ff5b0b;
        border: 2px solid #ff5b0b;
        
    }
}
.failureMessage{
    color:red;
}

.petDetails{
    .CTA{
        border:none;
        background-color: transparent;
        box-shadow: none;
        color:#ff5b0b;
        text-decoration: underline;
    }
}
.accordian_button_container
{
    
    @include media-breakpoint-down(md){
        background-color: transparent !important;
       .upload_medical_record_button{
        display: none !important;
       }
        .submit-a-claim-buttom{
            margin-left:0px;
        }
    }
}
