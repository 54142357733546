.error-border {
  box-sizing: border-box;
  border-width: 2px;
  border-style: solid;
  border-color: #ff2f6d;
  border-radius: 3px;
  margin-top: 12px;
  margin-bottom: 12px;
}

.icon-color {
  background-color: #ff2f6d;
  width: 48px;
  padding: 12px;
  height: 100%;
}

.error-icon {
  font-size: 25px;
  color: #FFFFFF;
}

.error-text {
  font-size: 14px;
  color: #ff2f6d;
  padding: 12px;
}
.error-text a {
  font-size: 14px;
  font-weight: bold;
  color: #ff2f6d !important;
  text-decoration: none;
}
.error-text a:hover {
  color: #ff2f6d !important;
}

