@import "./../../styles/_variables.scss";
@import "./../../styles/_mixins.scss";

.login-main {
  background-image: url("../../../public/assets/images/login/login_mobile.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  padding: 15px 0;

  .logo {
    width: 175px;
    height: 45px;
    margin-bottom: 30px;
  }

  .main-content {
    padding: 30px 0;
  }

  @include media-breakpoint-up(lg) {
    .logo {
      position: absolute;
      left: 15%;
      top: 17px;
    }
    padding: 12% 0;
  }

  @media screen and (min-width: 2000px) {
    padding: 20% 0;
  }

  @include media-breakpoint-up(xl) {
    padding: 13% 0;
  }

  @include media-breakpoint-up(md) {
    .logo {
      width: 200px;
      height: 50px;
    }
  }

  @include media-breakpoint-up(sm) {
    background-image: url("../../../public/assets/images/login/login_bg2.jpg");
  }

  .title {
    font-weight: 300;
    font-size: 32px;
    color: #ffffff;
    text-align: center;
  }

  @include media-breakpoint-up(sm) {
    .title {
      font-size: 44px;
    }
  }

  .sub-title {
    font-weight: 300;
    font-size: 22px;
    color: #ffffff;
    margin-top: -10px;
    margin-bottom: 10px;
    text-align: center;
  }

  @include media-breakpoint-up(sm) {
    .sub-title {
      font-size: 28px;
    }
  }
}
