@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.view-claims-info {
    .header {
        padding: 22px 20px 15px 20px;
        color: $header-Color !important;
    }

    .subtitle {
        font-weight: 700;
        padding: 0 20px;
    }

    .no-claims {
        background-color: #f2f2f2;
        padding: 5px;
        height: 200px;
        font-size: 14px;
    }

    .body {
        position: relative;

        .claims-table {
            margin-top: 10px;
            a {
                font-size: 14px;
                text-decoration: none !important;
                color: #000000 !important;
            }
    
            a:hover {
                color: #000000 !important;
                text-decoration: none !important;
            }
            
            .claims-striped-table {
    
                .claim-item {
                    padding: 10px 0;
                }
                .stripes:last-child {
                    border-bottom: none;
                }
                .stripes:nth-child(odd) {
                    .claim-item {
                        background-color: #f5fafb;
                    }
                }
                .stripes:nth-child(even) {
                    .claim-item {
                        background-color: #ffffff;
                    }
                }
            }
        }
    }

    .footer {
        padding: 20px;
        text-align: center;

        a {
            text-decoration: none;
        }

        a:hover {
            text-decoration: underline;
        }
    }
}