.billing-info {
  background: #f5fafb;
  border: 2px solid #dae3e8;
  border-radius: 10px;
}
.billing-info .info-header {
  color: #28373a !important;
  padding: 15px;
  font-size: 24px;
  font-weight: 400;
  color: #28373a !important;
}
.billing-info .info-body {
  background: #ffffff;
  padding: 20px 15px;
  overflow-wrap: break-word;
}
.billing-info .info-body .subtitle {
  color: #657493;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}
.billing-info .info-body .text-primary {
  color: #038062 !important;
}
.billing-info .info-body span {
  color: #333333;
  font-weight: 600;
}
.billing-info .info-body button {
  color: #6b61f2 !important;
  border: none;
  background: none;
  font-weight: 650;
}
.billing-info .info-body button:hover {
  text-decoration: underline;
}
.billing-info .info-body i {
  font-style: normal;
  font-style: italic;
  font-weight: 200;
}
.billing-info .info-footer {
  padding: 15px;
}

