@import "../../../styles/variables";
@import "../../../styles/mixins";

.status {
  .icon {
    font-size: 22px !important;
    margin-right: -1px;
  }
  .fillin {
    width: 7px;
    height: 5px;
    margin-bottom: 4px;
    margin-right: -1px;
  }
  .danger {
    background: $danger;
  }
  .dark {
    background: #666666;
  }
  .light {
    background: $light;
  }
  .success {
    background: $primary;
  }
  .text-dark {
    color: #666666 !important;
  }

  p {
    text-decoration: underline;
    cursor: pointer;
  }

  .fa-stack {
    width: fit-content;
    height: fit-content;
    line-height: 1.5 !important;
  }

  .number-circle {
    border-radius: 50%;
    padding: 2px;
    height: 19px;
    width: 19px;
    line-height: 0;
    line-height: 0.5;
    margin-bottom: 3px;
  }

  .border-red {
    border: 3px solid $danger;
  }

  .border-grey {
    border: 3px solid $light;
  }
}

.primary-text{
  color:$home-button;
}

@media only screen and (max-width: 374px) {
  .alignStatus {
    margin-left: -35px;
    margin-right: -25px;
  }
}
@media only screen and (max-width: 767px) and (min-width: 375px) {
  .alignStatus {
    margin-left: -25px;
    margin-right: -25px;
  }
}
