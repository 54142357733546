@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";

.dont-cancel-yet{
    margin-top: 20px;
    a{
        font-weight:700;
    }
    .title-container{
        @include media-breakpoint-down(md){
            margin-bottom:15px;
        }
    }
    .content-wrapper{
        padding: 22px 20px;
        border: 2px solid $home-button;
        height: 100%;
        border-radius: 10px;
        overflow-wrap: break-word;
        color:$home-button;
    }
    .content-links a{
        text-decoration: unset;
    }
    @include media-breakpoint-up(lg) {
        margin-bottom: 40px;
        margin-top: 0;
    }

    .button-container {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: 300px;
        }
    }

}