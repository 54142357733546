.post-cancellation-container .header-group {
  background-color: #f0f0ee;
}
.post-cancellation-container .header-group .header-top {
  background-color: #f0f0ee;
  min-height: 126px;
}
.post-cancellation-container .header-group .header-top .logo {
  border-width: 0px;
  width: 235px;
  height: 60px;
}
.post-cancellation-container .title-container {
  height: 50px;
  margin: 10px 0 10px 0;
}
@media (min-width: 768px) {
  .post-cancellation-container .title-container {
    margin: 0;
    height: 79px;
  }
}
@media (min-width: 992px) {
  .post-cancellation-container .title-container {
    height: 97px;
  }
}
.post-cancellation-container .status-bar {
  height: 37px;
  background-color: #34A350;
}
.post-cancellation-container .form-box {
  width: 100%;
  height: 1400px;
  overflow: hidden;
}
@media (min-width: 768px) {
  .post-cancellation-container .form-box {
    height: 1225px;
  }
}
@media (min-width: 992px) {
  .post-cancellation-container .form-box {
    width: 700px;
  }
}
.post-cancellation-container li {
  position: relative;
  left: -20px;
}
.post-cancellation-container .sign-out-button {
  width: 100%;
}
@media (min-width: 768px) {
  .post-cancellation-container .sign-out-button {
    width: 470px;
  }
}

