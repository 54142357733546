@import "./../../../styles/_variables.scss";
.stepper {
    .stepper-header {
        background-color: $status-Header;
        padding: 10px 0;
        font-size: 14px;
        color: #fff;
        
        .step-details {
            font-size: 18px;
        }
    }
}