@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";

.approve-address{
    display: block;
    .title-container{
        color:$header-Color;
    }
    .editButton{
        margin-left:10px;
        vertical-align: top;
        width:23px;
    }
    .h1_v2{
        font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
        color: #28373a !important;
        line-height: normal;
        font-size: 21px;
        font-weight: 400;
        margin-bottom: 0;
      }
    .h3_v2{
        font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
        color: #28373a !important;
        line-height: normal;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
      }

    .mobileTextV2{
        font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
        color: #657493 !important;
        font-size: 14px;
    }

    @include media-breakpoint-up(lg) {
        min-height: 60vh;
    }
    .content-wrapper {
        padding: 15px 15px;
        border: 2px solid #E4E4E4;
        height: auto;
        border-radius: 10px;
        overflow-wrap: break-word;
    }
    .margin {
        margin: 15px;
    }
    .button-secondary{
        border-width: 1px;
    }
    .fs-12{
        font-size: 12px;
    }
    .fw-300{
        font-weight: 300;
    }
    h3{
        color: $header-Color;
    }
    h4{
        margin-bottom: 0px;
        line-height: 1;
        color:$gray-4;
        font-size:12px;
        font-weight: 400;
        text-transform: uppercase;
    }
    p{
        margin-bottom: 0px;
    }
    .calendar-icon{
        font-size: 22px;
    }
    .term-text {
        font-size: 14px;
        font-weight: 700;
        color:$header-Color;
        
        @include media-breakpoint-up(md) {
            font-size: 24px;
        }
    }
    .textarea-border {
        border: 1px solid $dark;
        height: 54px;
        
        @include media-breakpoint-up(md) {
            height: 46px;
        }
    }
    .rate-table-head-row {
        height: 45px;
        margin-right: 0px; 
        margin-left: 0px; 
        @include media-breakpoint-up(md) {
            height: 40px;
        }
    }
    .rate-table-row {
        height: 60px;
        margin-right: 0px; 
        margin-left: 0px; 
        @include media-breakpoint-up(md) {
            height: 50px;
        }
    }
    .calendar-text {
        font-size: 12px;
        @include media-breakpoint-up(md) {
            font-size: 14px;
        }
    }
    .rate-table-column {
        padding-right: 0px;
        padding-left:  0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .content-wrapper-top{
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
    .content-wrapper-bottom{
        @extend .content-wrapper;
        border-top: none;
        border-top-right-radius: 0;
        border-top-left-radius: 0;
        padding:0;
    }
    .bg-row{
        background-color: #f5fafb;
        border-bottom: 3px solid #e4e4e4;
        text-align: center;
    }
    .bg-rowv2{
        background-color:  #f5fafb;
        border-bottom: 3px solid #e4e4e4;
        text-align: center;
    }
    .bg-dark-row{
        background-color: $gray-3;
    }
    .bg-dark-rowv2
    {
        background-color: $gray-3; 
    }
    .bg-row-success{
        background-color: $gray-3;
        text-align: center;
    }
    .bg-dark-row-success{
        background-color: rgba(25, 166, 74, 0.149019607843137);
    }
    .flex-1{
        flex: 1;
        @include media-breakpoint-up(md){
            flex:unset;
        }
    }
    .btn-w-14{
        width: auto;
        height: 30px;
    }

    @include media-breakpoint-up(md) {
        th{
            text-align: center;
        }
        table{
            margin-bottom: 0;
        }
        .bg-row-success{
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
        }
        .pl-22{
            padding-left: 22px;
        }
        // textarea{
        //     font-size: 12px;
        // }
        #legalContents{
            overflow: auto;
            resize: vertical;
            font-size: 12px;
        }    
    }

    #legalContents{
        overflow: auto;
        resize: vertical;
    }

    @include media-breakpoint-up(lg) {
        .content-wrapper-top,.content-wrapper-bottom {
            border: 2px solid #E4E4E4;
            border-radius: 10px;
        }
        .content-wrapper-bottom {
            padding-top: 22px;
            height: 270px;

        @include media-breakpoint-up(md) {
            padding-top: 15px;
            height: auto;
        }
        }
        .bg-row{
            border-bottom:3px solid #ffffff;
        }
        
    }

    .address-text {
        font-size: 16px;
        font-weight: 600;
        color:$header-Color;
    }
}

