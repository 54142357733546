@import "../../../styles/variables";
#message-pagination {
  text-align: center;
  padding: 20px;
}

.pagination-arrows {
  height: 30px;
  width: 25px;
  color: white;
  background-color: $text-Blue;
  font-size: 32px;
  font-weight: 700;
  line-height: 0px;
  cursor: pointer;
}

#page-edit-text {
  border: solid 2px $text-Blue;
  margin: 0px 10px;
}

#page-edit-text input {
  height: 25px;
  width: 25px;
  text-align: center;
  color: $text-Blue;
  border: none;
}

#message-pagination span , #message-pagination span > a {
  margin: 0px;
  font-size: 12px !important;
  color: $text-Blue !important;
  cursor: pointer;
}

#messages-status-change {
  padding: 10px;
}

#page-numbers {
  padding-right: 10px;
  align-self: center;
  color:$text-Blue;
}
