.pet-policy-rate .rate-content {
  border: 2px solid #dae3e8;
  border-top-width: 0;
  margin: -2px !important;
}
.pet-policy-rate .text-primary {
  color: #038062 !important;
  font-family: "OpenSans-SemiBold", "Open Sans SemiBold", "Open Sans", sans-serif;
}
.pet-policy-rate .text-secondary {
  color: #6b61f2 !important;
  font-family: "OpenSans-SemiBold", "Open Sans SemiBold", "Open Sans", sans-serif;
  font-weight: 650;
}
.pet-policy-rate .text-dark {
  color: #28373a !important;
  font-weight: 700;
}
.pet-policy-rate .breedAgeText {
  color: #28373a !important;
  font-weight: 400;
}
.pet-policy-rate .rate-sub-content {
  padding: 20px 15px;
  color: #333333;
}
.pet-policy-rate .rate-sub-content .segment {
  min-width: 33.33%;
  max-width: 33.33%;
}
.pet-policy-rate .rate-sub-content.gray {
  background-color: #f7f7f7;
}
.pet-policy-rate .rate-sub-content .limited-options-toggler {
  font-size: 12px;
}
.pet-policy-rate .rate-sub-content .limited-options-toggler p {
  font-weight: 400;
}
.pet-policy-rate .rate-sub-content .notice {
  font-weight: 700;
  color: #ff2f6d;
}

@media (min-width: 768px) {
  .pet-policy-rate .rate-content {
    border-top-width: 2px;
  }
  .pet-policy-rate .rate-sub-content {
    padding: 20px;
  }
  .pet-policy-rate .rate-sub-content .arrow_box {
    position: relative;
    background: transparent;
    border-right: 2px solid #dae3e8;
    font-size: 14px;
  }
  .pet-policy-rate .rate-sub-content .arrow_box:after, .pet-policy-rate .rate-sub-content .arrow_box:before {
    left: 100%;
    top: 50%;
    border: solid #dae3e8;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  .pet-policy-rate .rate-sub-content .arrow_box:after {
    border-color: rgba(136, 183, 213, 0);
    border-left-color: #f7f7f7;
    border-width: 9px;
    margin-top: -9px;
    margin-left: -1px;
  }
  .pet-policy-rate .rate-sub-content .arrow_box:before {
    border-color: rgba(0, 0, 0, 0);
    border-left-color: #dae3e8;
    border-width: 11px;
    margin-top: -11px;
  }
  .pet-policy-rate .rate-sub-content .segment {
    min-width: unset;
    max-width: 80px;
    max-height: 40px;
  }
  .pet-policy-rate .rate-sub-content .link-text,
  .pet-policy-rate .rate-sub-content .options-label {
    font-size: 14px;
  }
}
@media (min-width: 992px) {
  .pet-policy-rate {
    margin-bottom: 25px;
  }
  .pet-policy-rate .rate-content {
    border-radius: 8px;
  }
  .pet-policy-rate .segment {
    max-width: 80px;
    min-width: unset;
  }
}

