.change-password {
  min-height: 60vh;
}
.change-password .title-container {
  height: 50px;
}
@media (min-width: 768px) {
  .change-password .title-container {
    height: 79px;
  }
}
@media (min-width: 992px) {
  .change-password .title-container {
    height: 97px;
  }
}
@media (min-width: 768px) {
  .change-password {
    height: 75vh;
  }
}
.change-password .field-width {
  width: 100%;
}
@media (min-width: 768px) {
  .change-password .field-width {
    width: 470px;
  }
}
.change-password #change_password_field {
  margin: 10px 0px 10px 0px;
}
.change-password .button-container-margin {
  margin: 20px 0px 0px 0px;
}
.change-password .button-margin {
  margin: 0px 0px 12px 0px;
}
.change-password .fa-question-circle {
  font-size: 13px;
  color: #ff5b0b;
}
.change-password .password-note {
  font-size: 14px;
}
.change-password .password-note .fa-question-circle-o {
  font-size: 24px;
  margin-right: 10px;
}
.change-password .password-note + i {
  margin: 10px;
}
.change-password .password-note .fa-info-circle {
  color: #8e8e8e;
}
.change-password .password-note .fa-check-circle {
  color: #34A350;
}

@media (min-width: 768px) {
  .password-change-success {
    height: 75vh;
  }
  .password-change-success .password-change-success-home-button {
    max-width: 255px;
  }
}
.password-change-success .status {
  height: 42px;
}
.password-change-success .button-secondary {
  height: 40px;
}
.password-change-success .fa-check-circle {
  font-size: 28px;
}
.password-change-success .status-info {
  font-size: 18px;
  font-weight: 700;
  padding-left: 8px;
}
.password-change-success .status-text {
  font-size: 18px;
  color: white;
  font-weight: 400;
}
.password-change-success .content h1 {
  color: #28373a;
  font-weight: 400;
}

