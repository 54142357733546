@import "./../../styles/_mixins.scss";
@import "./../../styles/_variables.scss";

.getmobileapp{
    margin-bottom: 30px;
    .mob-img{
        height: 300px;
        overflow: hidden;
        text-align: center;
    }
    .video-container {
        position: relative;
        overflow: hidden;
        width: 100%;
    }
    .video-iframe {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        max-height:450px;
    }
    p {
        margin-left: 15px;
        color: #28373a;
    }
    .img-fluid {
        margin-left: 15px;
    }
    .consent-text {
        margin-left: 15px;
    }
    h3 {
        color: $header-Color;
        font-weight: 800;
        font-size: 22px;
        margin-left: 15px;
    }
    h4 {
        color: $header-Color;
        font-weight: 800;
        font-size: 22px;
        @include media-breakpoint-up(md) {
            margin-left: -15px;
            margin-bottom: 15px;
            margin-top: 35px;
        }
    }
    .video-img {
        margin-left: 15px;
        margin-bottom: 60px;
        width: 770px;
        height: 432px;
    }
   .pl-40{
       padding-left: 40px;
    }
    
    .gray-box {
        background-color: #F5F5F5;
        border-radius: 12px;
        width:100%;
    }
    .app-benefits-list {
        margin-bottom: 0px;
        padding-inline-start: 17px;
    }
    .app-benefits-item {
        vertical-align: top;
        padding-left: 10px;
        margin-bottom: 20px;
        color: $header-Color;
        strong {
            color: $header-Color;
            font-size: 16px;
            font-weight: 600;
        }
    }
    @include media-breakpoint-up(md) {
        .app-benefits-list {
            margin-left: -15px;
        }
    }
    .app-benefits-item::marker {
        font-family: FontAwesome;
        content: "\f058";
        font-size: 1.33333333em;
        line-height: .75em;
        color: #34A350 !important;
   }

    @include media-breakpoint-down(lg) {

        .video-container {
            padding-top: 56.25%;
        }
    }
}