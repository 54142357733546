.mobile-menu {
  background: #ffffff;
  margin-bottom: 50px;
}
.mobile-menu .mobile-sidebar-welcome {
  padding: 20px;
  border-bottom: 2px solid #E4E4E4;
}
.mobile-menu .side-drawer-header {
  padding: 5px 20px;
  font-weight: 700;
  border-bottom: 2px solid #E4E4E4;
  margin: auto;
  font-size: 16px;
}
.mobile-menu .side-drawer-header #paws-icon-home {
  width: 25px;
}
.mobile-menu .mobile-sidedrawer-icons {
  padding-right: 5px;
  font-size: 24px;
}
.mobile-menu .sidedrawer-arrow {
  color: #28373a;
  font-size: 30px;
}
.mobile-menu .active-header {
  background-color: #eff3f5;
}
.mobile-menu .mobile-sidedrawer-submenu {
  display: none;
}
.mobile-menu .mobile-sidedrawer-submenu-active {
  display: block;
}
.mobile-menu .mobile-sidedrawer-submenu-active li:last-child {
  border-bottom: 2px solid #E4E4E4;
}
.mobile-menu p {
  margin-bottom: 0px;
}
.mobile-menu li {
  padding: 5px 20px !important;
}
.mobile-menu ul {
  list-style: none;
  padding: 0px 0px !important;
  margin: 0px;
}
.mobile-menu a {
  text-decoration: none !important;
  color: #333333;
  font-size: 16px;
  font-weight: normal;
}
.mobile-menu a:hover {
  color: #333333 !important;
}
.mobile-menu .bottom-spacer {
  height: 40px;
  background: #F0F0EE;
}

