.topAlertBanner {
  position: fixed;
  top: 0px;
  display: none;
  z-index: 100000;
  width: 100%;
  font-size: 13px;
  background-color: #666666;
  color: #FFFFFF;
  padding: 12px;
  padding-top: 6px;
  padding-bottom: 0px;
  vertical-align: middle;
}
.topAlertBanner.open {
  display: block;
}

.right {
  float: right;
  bottom: 15px;
  left: 27px;
}

.zeromargin {
  margin-top: 20px;
}

.lighterWeight {
  font-weight: lighter !important;
}

.topAlertBannerContainer {
  position: relative;
}
.topAlertBannerContainer div.topAlertBannerText {
  display: inline-block;
}
@media (min-width: 768px) {
  .topAlertBannerContainer div.topAlertBannerText {
    width: 80%;
  }
}
.topAlertBannerContainer div.topAlertBannerButton {
  display: inline-block;
  padding: 0px 8px;
  position: relative;
}
.topAlertBannerContainer div.topAlertBannerButton .xButton {
  font-size: 24px;
  margin: auto;
  cursor: pointer;
}
.topAlertBannerContainer p.nomargin {
  margin-bottom: -16px !important;
}
.topAlertBannerContainer .dismissButton {
  font-size: 16px;
  display: inline-block;
  padding: 10px 15px;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #FFFFFF;
  border: 1px solid #FFFFFF;
  background-color: transparent;
  outline: none;
  border-radius: 5px;
  float: right;
}
@media (max-width: 767.98px) {
  .topAlertBannerContainer .dismissButton {
    width: 100%;
  }
}
.topAlertBannerContainer a {
  font-size: 12px;
  font-weight: 600;
  color: #FFFFFF !important;
  text-decoration: underline;
}

.top-banner-more-details {
  font-weight: normal;
  font-size: 12px;
  text-decoration: underline !important;
  color: #FFFFFF !important;
}

.hidden {
  display: none;
}

