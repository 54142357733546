@import "../../../src/styles/_mixins.scss";
@import "../../../src/styles/_variables.scss";

.contact-form-container {

    .margin-bottom {
        margin-bottom: 15px !important;
    }

    .contact-form {
        margin-top: 15px;
        margin-bottom: 40px;
        
        h4 {
            color:$header-Color;
        }

        .input-container {
            width: 100%;

            @include media-breakpoint-up(md) {
                width: 470px;
            }
        }

        .phone-input-container {
            width: auto !important;

            @include media-breakpoint-up(md) {
                width: 200px !important;
            }
        }

        .address-input-container {
            width: 100% !important;

            @include media-breakpoint-up(md) {
                width: 570px !important;
            }
        }

        .text-area-input {

            box-sizing: border-box;
            border: 1px solid #8D8D8D;

            font-weight: 400;
            font-size: 20px;
            color: $dark;

            padding: 15px 0px 0px 15px;
        }

        .text-area-input:focus {
            border: 2px solid $info;
            outline: none;
        }

        .related-faq {
            font-weight: 700;
            font-size: 14px;
            color: $header-Color;

            @include media-breakpoint-up(md) {
                font-size: 24px;
            }
        }

        .faqQuestion {
            height: auto;
            color: $text-Blue;
            cursor: pointer;
            margin-bottom: 15px;
            margin-top: 15px;
            font-weight: 600;
        }

        .faq-container {
            margin-left: 20px;
        }

        hr {
            height: 2px;
            background: $light;
            margin: 0px;
            border-top: 0;
        }
    }

    .rightColumn {

        .info-body-padding {
            padding-top: 5px !important;
        }

        .billing-info {
            margin-bottom: 30px;
        }

    }


}

h4{
    color: $header-Color;
}
.contact-image-item{
    display:inline-block;
    vertical-align: top;
    margin-top:-4px;
}
.text-container{
    display:inline-block;
    margin-left:5px;
    p, strong{
        margin-bottom:0px;
    }
}

.margin-bottom {
    margin-bottom: 20px;
    
    strong {
        color:$gray-4;
        font-weight: 400;
        text-transform: uppercase;
        font-size: 12px;
        display: block;
        margin-bottom: 5px;
    }
    div {
        color:$header-Color;
        font-weight: 600;
    }
}