.contact-container {
  min-height: 60vh;
}
.contact-container a {
  text-decoration: none !important;
}
.contact-container .title {
  height: 45px;
  margin-top: 10px;
}
@media (min-width: 768px) {
  .contact-container .title {
    height: 79px !important;
    margin-top: 0px;
  }
}
@media (min-width: 992px) {
  .contact-container .title {
    height: 97px !important;
    margin-top: 0px;
  }
}
.contact-container .info-body {
  color: #657493;
  font-weight: 400;
  font-size: 12px;
  text-transform: uppercase;
}
.contact-container .margin-bottom {
  margin-bottom: 15px;
  display: inline-block;
}

.home-button-thank-you {
  margin-top: 15px;
  margin-bottom: 30px;
  border-radius: 20px;
  width: 203px;
  height: 40px;
}
@media (min-width: 768px) {
  .home-button-thank-you {
    margin-top: 20px;
    margin-bottom: 40px;
    max-width: 255px;
  }
}

