@import "../../../styles/variables";
@import "../../../styles/mixins";

.confirm-charges {
    padding: 30px 15px 0 15px;
    .link-text{
        color:$text-Blue;
        font-weight:bold;
    }
    .summary-content {
        font-weight:normal;
    }
    .billing-summary {
        border: 2px solid rgba(228, 228, 228, 1);
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 25px;

        .summary-list {
            padding-inline-start: 20px;
        }
    }
    .terms-conditions {
        width: 100%;
        font-size: 12px;
        font-weight: 400;
        margin-bottom: 25px;
        height: 88px;
    }
    .agreement {
        margin-bottom: 25px;
    }
    .disabled-btn {
        background: $gray-3;
        pointer-events: none;
    }
    @include media-breakpoint-up(lg) {
        padding-top: 0 !important;
    }
}

@include media-breakpoint-up(md) {
    .confirm-charges {
        padding: 30px 15px;
        .summary-content {
            font-weight: bold;
        }
        .terms-conditions {
            height: 44px;
        };
    }
}