#dropdown-navmenu {
  position: relative;
  width: 100%;
  background-color: #fcfaf4;
  padding: 15px 0px;
}
#dropdown-navmenu .menu-container {
  margin: 0px auto;
}
#dropdown-navmenu .menu-container .nav-item {
  display: inline-block;
}
#dropdown-navmenu .logo-container {
  display: flex;
  align-items: center; /* horizontal */
  justify-content: center; /* vertical */
}
#dropdown-navmenu #logo {
  width: 194px;
}
#dropdown-navmenu .dropdown-navmenu-header {
  color: #28373a;
  font-size: 16px;
  padding: 15px 20px;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  border-bottom: 3px solid transparent;
}
#dropdown-navmenu .dropdown-navmenu-header .fa-caret-down {
  margin-left: 5px;
  font-size: 12px;
}
#dropdown-navmenu .dropdown-navmenu-header .active {
  background-color: #ff2f6d;
}
#dropdown-navmenu .dropdown-navmenu-group .nav-link {
  display: inline;
}
#dropdown-navmenu .dropdown-navmenu-group a {
  color: white;
}
#dropdown-navmenu .dropdown-navmenu-items {
  background-color: white;
  display: none;
  line-height: 1.7;
  min-width: 250px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 30px;
  z-index: 90;
  position: absolute;
  margin-top: 5px;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.5);
  border-top: 4px solid #34A350;
}
#dropdown-navmenu .dropdown-navmenu-items:after {
  content: "";
  position: absolute;
  left: 35px;
  top: -12px;
  border-bottom: 10px solid #34A350;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
}
#dropdown-navmenu .dropdown-navmenu-items .active {
  display: block;
}
#dropdown-navmenu .dropdown-navmenu-items a {
  color: #333333 !important;
  font-weight: 400;
  text-decoration: none;
  font-size: 14px;
}
#dropdown-navmenu .dropdown-navmenu-items a:hover {
  color: #107232 !important;
  text-decoration: underline !important;
}
#dropdown-navmenu .dropdown-navmenu-header a {
  color: white !important;
  font-weight: 700;
  text-decoration: none;
}
#dropdown-navmenu .dropdown-navmenu-header > a:hover {
  color: green !important;
}
#dropdown-navmenu .dropdown-navmenu-header:hover {
  color: green !important;
}
#dropdown-navmenu .header-menu-active {
  display: block;
}
#dropdown-navmenu .header-active {
  margin-bottom: -5px;
  padding: 13px 20px 15px 20px;
  border-bottom: none;
}
#dropdown-navmenu .header-navmenu-icons {
  font-size: 20px;
}

