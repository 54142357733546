@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";

#upload-missing-docs {
  .upload-file-edit {
    color: $text-Blue;
    font-weight: 700;
    
    cursor: pointer;
  }
  .text-danger{
    color:$text-Blue !important;
  }
  .text-secondary, .text-blue{
    color:$text-Blue;
  }
  
  .problem-upload-toggler {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }

  @include media-breakpoint-up(lg) {
    width: 70%;
  }

  .upload-claim-info{
    font-size: 14px;
    span{
      font-weight:bold;
    }
    @include media-breakpoint-up(md) {
      font-size: 16px;
    }

  }

  .claim-collapse-box {
    border: 2px solid $light;
    border-radius: 10px;
    margin-bottom: 15px;
    margin-top:15px;

    .collapse-box-item {
      padding: 10px;
      
      border-bottom: 2px solid $light;
    }

    .collapse-box-item:last-of-type {
      border-bottom: none !important;
    }

    .selected {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    .disable {
      color: $gray-2;
      pointer-events: none;

      i {
        display: none;
      }
    }

    .box-title {
      font-weight: 700;
      @include media-breakpoint-up(sm) {
        margin-left: 14px;
        margin-top: 10px;
      }

      span {
        font-weight: normal;
      }

      i {
        font-size: 22px !important;
      }
    }

    .tc-box {
      @include media-breakpoint-up(sm) {
        margin-left: 14px;
        margin-right: 14px;
      }
    }

    
    textarea {
      font-size: 12px;
      font-weight: normal;
      width: 100%;
      margin: 10px 0;
      font-size: 10px;
      height: 90px;

      @include media-breakpoint-up(md) {
        font-size: 12px;
        height: 45px;
      }
    }

    .btn-secondary {
      font-weight: 700;
      padding: 12px;
      width: 100%;
      border-radius: 30px;
      border: none;
      background-color: $button_Green;
      @include media-breakpoint-up(md) {
        width: 65%;
      }
    }

    .btn-secondary:hover {
      background: $button_Green !important;
      color: #ffffff !important;
    }

    .disabled-btn {
      background: $gray-3 !important;
      pointer-events: none;
    }

    .cancel-btn {
      font-weight: 700;
      width: 25%;
      text-align: center;
      border: none;
      background: none;
    }
  }

  .faq-toggle {
    font-weight: 700;
  }

  .missing-doc-info-container {
    border: 2px solid $danger;
    border-radius: 10px;
    padding: 10px 20px;
    color: $danger;
    font-weight: 400;
  }

}

.missing-doc-faq-box {
    border: 2px solid $light;
    border-radius: 8px;
    margin-top: 5px;
    background: $gray-1;

    .missing-doc-faq-header {
        color: $primary;
        font-size: 23px;
        padding: 15px 15px 10px 15px;
        border-bottom: 2px solid $light;
    }

    .missing-doc-faq-body {
        background: #ffffff;
        .missing-doc-faq {
            font-weight: 700;
            padding: 10px;
            border-bottom: 1px solid $light;
        }
    
        .missing-doc-faq:last-of-type {
            border-bottom: none;
        }
    }

    .missing-doc-faq-footer {
        padding: 20px;
        border-top: 2px solid $light;
    }
}
.h-400{
  min-height: 400px;
}