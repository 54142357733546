@import "./../../../../styles/_variables";
@import "./../../../../styles/_mixins.scss";
.photo-filter {
  background-color: #fff;

  .image-container {
    display: flex;
    justify-content: center;
    background-color: black;

    .original-image {
      width: 200px;
      height: 200px;
    }
  }

  .filters-holder {
    overflow-x: scroll;
    padding: 15px 0 0 15px;
    background-color: #000;
    border-top: 1px solid #272626;

    .image-filters {
      height: 110px;
      display: flex;
      min-width: 595px; // 85px per filter options (85 * 7 = 595)
      justify-content: center;

      .filter-image-container {
        padding-right: 15px;

        &.active {
          svg {
            border: 2px solid #fff;
          }

          .filter-name {
            color: #fff;
          }
        }

        .filter-image {
          width: 70px;
          height: 70px;
          margin-bottom: 5px;

          svg {
            border-radius: 50%;
          }
        }

        .filter-name {
          user-select: none;
          color: $gray-3;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }
}
