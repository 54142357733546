.text-color-violet1 {
  color: #6B61F2 !important;
}

.claim-details-form {
  font-size: 14px;
  padding: 10px 0;
}
@media (min-width: 576px) {
  .claim-details-form {
    margin-left: 14px;
  }
}
.claim-details-form a {
  color: #FF5B0B;
  font-size: 12px;
  font-weight: 700;
  text-decoration: none !important;
  vertical-align: bottom;
}
.claim-details-form .vet-clinic-dropdown-disable {
  pointer-events: none;
  opacity: 0.5;
}
.claim-details-form a:hover {
  color: #FF5B0B !important;
  text-decoration: underline !important;
}
.claim-details-form .icon {
  font-size: 12px !important;
}
.claim-details-form .add-claim-next-button-block {
  position: relative;
}
.claim-details-form .add-claim-next-button-block .add-details-next-step-btn {
  width: 200px !important;
}
.claim-details-form .sub-title-file-claim {
  font-size: 13px;
}
@media (min-width: 576px) {
  .claim-details-form .sub-title-file-claim {
    font-size: 18px;
  }
}
.claim-details-form .add-details-skip-now-btn {
  float: none;
  width: 100% !important;
  padding-top: 10px;
  position: absolute;
  right: 0;
}
@media (min-width: 576px) {
  .claim-details-form .add-details-skip-now-btn {
    float: right;
    padding: 15px;
    margin-top: 18px;
    width: 150px !important;
  }
}
.claim-details-form form {
  width: 100%;
}
.claim-details-form form .form-field {
  margin-bottom: 12px;
}
.claim-details-form form .btn-secondary {
  margin-top: 20px;
}
@media (min-width: 768px) {
  .claim-details-form form {
    margin: auto !important;
  }
  .claim-details-form form .btn-secondary {
    width: 50% !important;
  }
}
.claim-details-form form .form-elements-container {
  width: 100%;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .claim-details-form form .form-elements-container {
    width: 60%;
  }
}
.claim-details-form .claim-question-toggle {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
}
.claim-details-form .claim-question-toggle p {
  font-weight: normal;
}
.claim-details-form .claim-question-toggle i {
  font-size: 12px;
}

.modal-body {
  padding-top: 0 !important;
}
.modal-body .btn-secondary:hover {
  color: #ffffff !important;
  background: #FF5B0B !important;
}

