.view-claim-status-header {
  padding: 0px 0px;
}
.view-claim-status-header .text-primary {
  margin: 15px 0px;
  color: #28373a !important;
}
.view-claim-status-header .button-secondary {
  padding: 5px 30px;
  height: auto;
  width: auto;
  font-weight: 600;
}

.view-claim-status {
  padding: 0px 0px 30px 0px;
}
.view-claim-status a {
  text-decoration: none;
}
.view-claim-status a:hover {
  text-decoration: underline;
}
.view-claim-status #claim-search-edit-text .informed-text-field {
  margin-right: 35px;
}
.view-claim-status #claim-search-edit-text .floating-label {
  padding-right: 50px;
}
.view-claim-status .claim-status .btn-outline-secondary {
  border-width: 2px;
  font-weight: 700;
  width: 100%;
}
.view-claim-status .claim-status .btn-outline-secondary:hover {
  background: transparent !important;
}
.view-claim-status .claim-status .search-container {
  position: relative;
}
.view-claim-status .claim-status .search-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #CCCCCC;
}
.view-claim-status .claim-status .claims-table {
  margin-top: 20px;
}
.view-claim-status .claim-status .claims-table .claims-table-header {
  display: flex;
  flex-direction: row;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 5px;
}
@media (min-width: 768px) {
  .view-claim-status .claim-status .claims-table .claims-table-header {
    font-size: 14px;
  }
}
.view-claim-status .claim-status .claims-table .no-claims {
  border: 2px solid #E4E4E4;
  padding: 25px 10px;
  background-color: #f2f2f2;
}
.view-claim-status .claim-status .claims-table .claims-striped-table {
  border: 2px solid #E4E4E4;
}
.view-claim-status .claim-status .claims-table .claims-striped-table .claim-item {
  border-bottom: 2px solid #E4E4E4;
  padding: 10px 0;
}
.view-claim-status .claim-status .claims-table .claims-striped-table .claim-item .slideup,
.view-claim-status .claim-status .claims-table .claims-striped-table .claim-item .slidedown {
  max-height: 0;
  overflow-y: hidden;
  -webkit-transition: max-height 0.5s ease-in-out;
  -moz-transition: max-height 0.5s ease-in-out;
  -o-transition: max-height 0.5s ease-in-out;
  transition: max-height 0.5s ease-in-out;
}
.view-claim-status .claim-status .claims-table .claims-striped-table .claim-item .slidedown {
  max-height: 60px;
}
.view-claim-status .claim-status .claims-table .claims-striped-table .claim-item:last-child {
  border-bottom: none;
}
.view-claim-status .claim-status .claims-table .claims-striped-table .claim-item:nth-child(odd) {
  background-color: #f5fafb;
}
.view-claim-status .claim-status .claims-table .claims-striped-table .claim-item:nth-child(even) {
  background-color: #ffffff;
}
.view-claim-status .refer-friend {
  margin-bottom: 24px;
}

