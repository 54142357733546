.policy-docs{
    .content-wrapper{
        padding: 22px 20px;
        border: 2px solid #E4E4E4;
        height: 100%;
        border-radius: 10px;
        word-break: break-word; 
        p, p a, strong{
            font-size: 13px;
        }
    }
    .faq-text {
        font-weight: 600;
    }
    a{
        text-decoration: none;
        font-weight: 600;
    }
}