@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";

#alerts-header-container {
  color: white;
  font-size: 14px;
  transform-origin: top;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .alert-header-ellipse {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    .read-more{
      text-decoration: underline;
      font-weight:600;
    }
  }

  &.alerts-color {
    background-color: $alert;
  }

  &.notifications-color {
    background-color: $notification;
  }

  &.alerts-error-color {
    background-color: $gray-3;
  }

  .drop-angle-icon {
    font-size: 28px;
    font-weight: 500;
    padding-left: 5px;
    color: white;
    cursor: pointer;
  }
}

#alerts-content-container {
  position: absolute;
  color: white;
  width: 100%;
  z-index: 40;
  font-size: 14px;

  .alerts-color {
    background-color: $alert;
  }

  .notifications-color {
    background-color: $notification;
  }

  .alerts-error-color {
    background-color: $gray-3;
  }

  .alerts-content {
    transform: scaleY(0);
    transform-origin: top;
    transition: all 250ms ease-in-out;
    padding-bottom: 5px;
  }

  a {
    color: white !important;
  }

  ul {
    padding-left: 20px;
    margin-bottom: 0px;
  }

  .action-button {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: 44%;
      max-width: 300px;
      margin-right: 10px;
    }
    @include media-breakpoint-up(lg) {
      min-width: 17%;
    }
    background-color: white;
    color: $alert;
    height: 40px;
    margin: 7px 0px;
    border-radius: 7px;
    font-size: 14px;
    padding: 5px;
    box-shadow: none !important;
    font-weight: 650;
  }

  .action-button-notification {
    color: $notification;
    margin-left: 0px;
    padding: 5px 5px;
  }

  .action-button-notification:hover {
    color: blue !important;
  }

  .open {
    /* this is blocking fixed positioning of chat buttons */
    transform: scaleY(1);
  }
}
