@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";

.text-color-grey1 {
    color: $gray-4 !important;
}

h4.text-color-solidgreen3 {
    color: $solidgreen-3 !important;
    font-size:16px !important;
    font-weight:700 !important;
}

.text-color-violet1 {
    color: $violet-1 !important;
}

.claim-submit-success-container {
    .reimbrusement-info {
        font-weight: 400;
        font-style: italic;
    }
    .clam-success-margin-bottom {
        margin-bottom: 0px;
        
        @include media-breakpoint-up(md) {
            margin-bottom: 25px;
        }
    }
}