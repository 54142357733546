.mobile-home a {
  text-decoration: none !important;
}
.mobile-home .header {
  background: #F0F0EE;
  border-bottom: 2px solid #E4E4E4;
  text-align: left;
  padding: 10px 20px;
  font-weight: 700;
  font-size: 16px;
  min-height: 32px;
  color: #28373a;
}
.mobile-home .list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #E4E4E4;
  padding: 10px 20px;
}
.mobile-home .list .list-fa-icons {
  font-size: 30px;
  color: #28373a !important;
}
.mobile-home .list .list-image {
  height: 35px;
  width: 30px;
}
.mobile-home .list .list-title {
  font-size: 16px;
  font-weight: 400;
  color: #28373a !important;
  font-weight: normal !important;
}
.mobile-home .list .list-fa-arrow {
  font-size: 32px;
  color: #28373a !important;
}

