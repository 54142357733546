@import "./../../styles/_mixins.scss";
@import "./../../styles/_variables.scss";

.oops{
    margin-top: 20px;
    margin-bottom: 20px;
    .m-block{
        display: block;
    }
    .link{
        text-decoration: none;
        line-height: 2;
        color:$text-Blue !important;
        font-size: 16px !important;
        font-weight: 600;
        white-space: nowrap;
    }
    .subHeadline{
        font-family: "OpenSans-Regular", "Open Sans", sans-serif;
        font-size: 16px !important;
        color: $header-Color !important;
        text-align: left;
        line-height: normal;
    }
    .oopsHeading{
        color:$header-Color;
        font-size: 36px;
    }
    @include media-breakpoint-up(md) {
        .m-block{
            display: inline;
        }
    }
    @include media-breakpoint-down(md) {
        .oopsHeading{
            font-size: 21px;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-only(md) {
        .oopsHeading{
            font-size: 30px;
            font-weight: 400;
            margin-bottom: 0;
        }
    }
}