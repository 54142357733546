.upload-medical-records {
  margin: 20px 0;
}
.upload-medical-records .header {
  margin-bottom: 20px;
  font-weight: 600;
}
@media (min-width: 768px) {
  .upload-medical-records .header {
    font-weight: 400;
  }
}
.upload-medical-records .upload-mr-content a {
  text-decoration: none;
}
.upload-medical-records .upload-mr-content .links {
  font-weight: 650;
}
.upload-medical-records .upload-mr-content .mr-collapse-box {
  border: 2px solid #E4E4E4;
  border-radius: 10px;
  margin-bottom: 15px;
}
.upload-medical-records .upload-mr-content .mr-collapse-box .mr-box-item {
  padding: 10px;
  border-bottom: 2px solid #E4E4E4;
}
.upload-medical-records .upload-mr-content .mr-collapse-box .mr-box-item:last-of-type {
  border-bottom: none !important;
}
.upload-medical-records .upload-mr-content .mr-collapse-box .selected {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #F0F0EE;
}
.upload-medical-records .upload-mr-content .mr-collapse-box .disable {
  color: #CCCCCC;
  pointer-events: none;
}
.upload-medical-records .upload-mr-content .mr-collapse-box .disable i {
  display: none;
}
.upload-medical-records .upload-mr-content .mr-collapse-box .box-title {
  font-weight: 700;
}
@media (min-width: 576px) {
  .upload-medical-records .upload-mr-content .mr-collapse-box .box-title {
    margin-left: 14px;
    margin-top: 10px;
    font-size: 18px;
  }
}
.upload-medical-records .upload-mr-content .mr-collapse-box .box-title span {
  font-weight: normal;
}
.upload-medical-records .upload-mr-content .mr-collapse-box .box-title text-2 {
  color: #CCCCCC;
}
.upload-medical-records .upload-mr-content .mr-collapse-box .box-title i {
  font-size: 22px !important;
}
@media (min-width: 576px) {
  .upload-medical-records .upload-mr-content .mr-collapse-box .tc-box {
    margin-left: 14px;
    margin-right: 14px;
  }
}
.upload-medical-records .upload-mr-content .mr-collapse-box textarea {
  font-size: 12px;
  font-weight: normal;
  width: 100%;
  margin: 10px 0;
  font-size: 10px;
  height: 90px;
}
@media (min-width: 768px) {
  .upload-medical-records .upload-mr-content .mr-collapse-box textarea {
    font-size: 12px;
    height: 45px;
  }
}
.upload-medical-records .upload-mr-content .mr-collapse-box .btn-secondary {
  font-weight: 700;
  padding: 12px;
  width: 100%;
  border-radius: 5px;
  border: none;
  background-color: #4aa574;
}
@media (min-width: 768px) {
  .upload-medical-records .upload-mr-content .mr-collapse-box .btn-secondary {
    width: 65%;
  }
}
.upload-medical-records .upload-mr-content .mr-collapse-box .btn-secondary:hover {
  background-color: #4aa574 !important;
  color: #ffffff !important;
}
.upload-medical-records .upload-mr-content .mr-collapse-box .disabled-btn {
  background: #dae3e8 !important;
  pointer-events: none;
}
.upload-medical-records .upload-mr-content .mr-collapse-box .cancel-btn {
  font-weight: 700;
  width: 25%;
  text-align: center;
  text-decoration: underline;
  border: none;
  background: none;
  color: #6b61f2 !important;
}
.upload-medical-records .upload-mr-content .mr-collapse-box .upload-file-edit {
  color: #6b61f2;
  font-weight: 700;
  text-decoration: underline;
  cursor: pointer;
}
.upload-medical-records .upload-mr-content .mr-collapse-box .problem-upload-toggler {
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 10px;
  cursor: pointer;
  color: #6b61f2 !important;
}
.upload-medical-records .upload-mr-content .faq-toggle {
  font-weight: 700;
}
.upload-medical-records .d-flex .button-secondary {
  border-radius: 20px;
  border: 2px solid #5ac48a;
  color: #5ac48a;
}

