@import "./../../../../../styles/_variables.scss";


.mobile-menu {
  background: #ffffff;
  margin-bottom:50px;
  .mobile-sidebar-welcome {
    padding: 20px;
    border-bottom: 2px solid $light;
    
  }

  .side-drawer-header {
    padding: 5px 20px;
    font-weight: 700;
    border-bottom: 2px solid $light;
    margin: auto;
    font-size: 16px;

    #paws-icon-home{
      width:25px;
      
    }
  }

  .mobile-sidedrawer-icons {
    padding-right: 5px;
    font-size: 24px;
  }

  .sidedrawer-arrow {
    color: $header-Color;
    font-size: 30px;
  }

  .active-header {
    background-color: $header-active
  }

  .mobile-sidedrawer-submenu {
    display: none;
  }
  
  .mobile-sidedrawer-submenu-active {
    display: block;
  }
  
  .mobile-sidedrawer-submenu-active li:last-child {
    border-bottom: 2px solid $light;
  }

  p {
    margin-bottom: 0px;
  }

  li {
    // border-top: 2px solid $light;
    padding: 5px 20px !important;
  }

  ul {
    list-style: none;
    padding: 0px 0px !important;
    margin: 0px;
  }

  a {
    text-decoration: none !important;
    color: $dark;
    font-size: 16px;
    font-weight: normal;
  }

  a:hover {
    color: $dark !important;
  }

  .bottom-spacer {
    height: 40px;
    background: $gray-1;
  }
  
}
