.background-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  height: 210px;
  width: 100%;
}
@media (min-width: 576px) {
  .background-container {
    height: 282px;
  }
}
.background-container a {
  text-decoration: none !important;
}
.background-container .image-slider {
  justify-content: center;
  flex-direction: row;
  text-align: center;
  height: 100%;
  padding: 15px;
}
.background-container .carousal-image-item {
  height: 73px;
  width: 73px;
  margin: 10px;
  margin-bottom: 0px;
  margin-top: 50px;
  border-radius: 70px;
  border: 2px solid #34A350;
  overflow: hidden;
  align-self: center;
  cursor: pointer;
}
@media (min-width: 576px) {
  .background-container .carousal-image-item {
    height: 118px;
    width: 118px;
  }
}
@media (min-width: 576px) {
  .background-container .carousal-image-item {
    border: 4px solid #34A350;
  }
}
.background-container .carousal-image-edit {
  height: 25px;
  width: 25px;
  border-radius: 12px;
  background-color: #7db67b;
  color: white;
  position: absolute;
  top: 0;
  right: 20px;
}
@media (min-width: 576px) {
  .background-container .carousal-image-edit {
    height: 35px;
    width: 35px;
    border-radius: 17px;
    top: 17px;
    right: 5px;
  }
}
.background-container .edit-icon {
  cursor: pointer;
}
@media (min-width: 576px) {
  .background-container .edit-icon {
    font-size: 24px;
    padding-top: 5px;
  }
}
.background-container .no-image {
  background-color: #F2F2F3;
  padding: 10px;
}
.background-container .carousal-image-item-active {
  height: 133px;
  width: 133px;
  background-color: rgb(233, 230, 230);
  color: grey;
  border-radius: 133px;
  border: 2px solid #34A350;
  overflow: hidden;
  align-self: center;
}
@media (min-width: 576px) {
  .background-container .carousal-image-item-active {
    height: 191px;
    width: 191px;
    border: 4px solid #34A350;
  }
}
.background-container .carousal-image-item-active img {
  width: 100%;
  height: 100%;
}
.background-container .carousal-image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.background-container .carousal-image {
  max-height: 100%;
  max-width: 100%;
}
.background-container .corousal-bottom {
  text-align: center;
}
.background-container .corousal-controls {
  color: white;
  display: inline-block;
  height: 35px;
  margin: 0 auto;
  margin-top: 10px;
}
@media (min-width: 576px) {
  .background-container .corousal-controls {
    height: 45px;
  }
}
.background-container .pet-name-carousal {
  height: 35px;
  border-radius: 50px;
  width: 200px;
  padding-top: 5px;
  overflow: hidden;
  cursor: pointer;
  background-color: #4aa574;
}
@media (min-width: 576px) {
  .background-container .pet-name-carousal {
    padding-top: 8px;
    width: 300px;
    height: 45px;
    padding-top: 10px;
  }
}
.background-container .corousal-controls span {
  font-weight: 700;
  margin: 3px;
  opacity: 1;
}
.background-container .icons {
  font-size: 35px;
  padding: 10px 20px;
  font-weight: 700;
  color: #00c885;
  cursor: pointer;
}
@media (min-width: 576px) {
  .background-container .icons {
    font-size: 45px;
  }
}
.background-container .add-photo-span {
  padding-top: 20px;
}
.background-container .active-no-image {
  cursor: pointer;
}
.background-container .add-pet-button {
  background-color: #fbfbfb;
  color: #038062;
  border: 2px solid #00c885;
  padding: 2px;
  padding-top: 20px;
  font-size: 12px;
  font-weight: 700;
  height: 100px;
  width: 100px;
  font-size: 14px;
  margin-top: 0px;
  border-radius: 70px;
  cursor: pointer;
  align-self: center;
  position: relative;
  top: -11px;
}
.background-container .add-pet-button-one {
  margin-left: -30px !important;
}
.background-container .add-pet-button-two {
  margin-left: 10px !important;
  top: 60px;
}
.background-container .add-pet-button-text {
  margin: 10px;
}
.background-container .carousal-images-div {
  padding-top: 0px;
}
@media (min-width: 576px) {
  .background-container .carousal-images-div {
    padding-top: 10px;
  }
}
@media (min-width: 576px) {
  .background-container .center-align-images {
    margin-left: 100px;
  }
}
.background-container .pet-image-edit {
  color: #34A350;
  font-size: 12px;
}
.background-container .center-carousal-image {
  width: 53%;
  margin: 0 auto;
}

