@import "../../../styles/variables";
@import "../../../styles/mixins";

.raf-bottom-banner {
  background-color: rgb(239, 239, 237);
  width: 100%;
  position: fixed;
  bottom: -1px;
  z-index: 6;

  #extole-banner-refer, #extole-banner-refer-mobile {
    cursor: pointer;
  }

  .collapse-icon {
    position: absolute;
    right: 15px;
    top: -8px;

    i {
      font-size: 30px;
    }
  }

  .collapse-link {
    background-color: $primary;
    text-align: center;
    padding: 8px;

    .link-title {
      text-decoration: underline;
      color: #fff;
    }

    .collapse-icon {
      color: #fff;
    }
  }

  .banner-dog {
    float: left;
    width: 190px;
  }
  .banner-dog-container {
    position: absolute;
    height: 175px;
    width: 190px;
    bottom: 0;

    &.collapsing {
      height: 0;
    }
  }

  .collapse-content {
    .container {
      padding: 16px 23px;

      .collapse-icon {
        color: #767676;
        right: 23px;
        top: 10px;
      }

      .banner-dog {
        float: left;
        margin-top: -70px;
        width: 190px;
      }

      .copy-input {
        border: 1px solid #000;
        border-radius: 0;
        background-color: #fff;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .raf-bottom-banner {
    background-color: rgba(239, 239, 237, 0.9);

    .collapse-content {
      border-top: 1px solid $secondary;
      box-shadow: 0 -3px 0px 0px rgba(194, 194, 194, 1);
      padding: 8px 23px;

      .collapse-icon {
        color: #767676;
        right: 13px;
        top: 0;
        padding: 0 10px;
      }
    }
  }
}
