[type=range] {
  -webkit-appearance: none;
  background: transparent;
  margin: 9px 0;
  width: 100%;
}
[type=range]::-moz-focus-outer {
  border: 0;
}
[type=range]:focus {
  outline: 0;
}
[type=range]:focus::-webkit-slider-runnable-track {
  background: #dee4e7;
}
[type=range]:focus::-ms-fill-lower {
  background: #cfd8dc;
}
[type=range]:focus::-ms-fill-upper {
  background: #dee4e7;
}
[type=range]::-webkit-slider-runnable-track {
  cursor: default;
  height: 2px;
  transition: all 0.2s ease;
  width: 100%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
  background: #cfd8dc;
  border: 0 solid #cfd8dc;
  border-radius: 5px;
}
[type=range]::-webkit-slider-thumb {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0), 0 0 2px rgba(13, 13, 13, 0);
  background: #fff;
  border: 2px solid #c0d6fb;
  border-radius: 9px;
  cursor: default;
  height: 18px;
  width: 18px;
  -webkit-appearance: none;
  margin-top: -8px;
}
[type=range]::-moz-range-track {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
  cursor: default;
  height: 2px;
  transition: all 0.2s ease;
  width: 100%;
  background: #cfd8dc;
  border: 0 solid #cfd8dc;
  border-radius: 5px;
  height: 1px;
}
[type=range]::-moz-range-thumb {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0), 0 0 2px rgba(13, 13, 13, 0);
  background: #fff;
  border: 2px solid #c0d6fb;
  border-radius: 9px;
  cursor: default;
  height: 14px;
  width: 14px;
}
[type=range]::-ms-track {
  cursor: default;
  height: 2px;
  transition: all 0.2s ease;
  width: 100%;
  background: transparent;
  border-color: transparent;
  border-width: 9px 0;
  color: transparent;
}
[type=range]::-ms-fill-lower {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
  background: #c0ccd1;
  border: 0 solid #cfd8dc;
  border-radius: 10px;
}
[type=range]::-ms-fill-upper {
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2), 0 0 1px rgba(13, 13, 13, 0.2);
  background: #cfd8dc;
  border: 0 solid #cfd8dc;
  border-radius: 10px;
}
[type=range]::-ms-thumb {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0), 0 0 2px rgba(13, 13, 13, 0);
  background: #fff;
  border: 2px solid #c0d6fb;
  border-radius: 9px;
  cursor: default;
  height: 14px;
  width: 14px;
  margin-top: 0.5px;
}
[type=range]:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}
[type=range]:disabled::-moz-range-thumb {
  cursor: not-allowed;
}
[type=range]:disabled::-ms-thumb {
  cursor: not-allowed;
}
[type=range]:disabled::-webkit-slider-runnable-track {
  cursor: not-allowed;
}
[type=range]:disabled::-ms-fill-lower {
  cursor: not-allowed;
}
[type=range]:disabled::-ms-fill-upper {
  cursor: not-allowed;
}

.photo-cropper {
  background-color: #fff;
}
.photo-cropper .cropper-image-container {
  background-color: #000;
}
.photo-cropper .range-container {
  width: 90%;
}
.photo-cropper .zoom {
  line-height: 40px;
  padding: 10px;
}
.photo-cropper .zoom.zoom-max {
  font-size: 24px;
}

@media (min-width: 768px) {
  .range-container {
    width: 50% !important;
  }
}

