@import "../../../../styles/variables";

.dropdown-breed-select-container {
    border:none !important;
}

.dropdown-containerV2 {
    height: 55px;
    width: 100%;
    background-color: transparent;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(101, 116, 147);
    border-radius: 16px;
    font-weight: 400;
    font-style: normal;
    font-size: 20px;
    text-decoration: none;
    color: $dark;
    text-indent: 5px;
}

.dropdown-body {
  background: #DDDDDD !important;
  opacity: .95;
}

.dropdown-item {
  padding-left: 12px !important;
}

.no-padding {
  padding: 0px;
  margin: 0px;
  background: #ffffff
}
.top-padding {
  margin-top: -25px;
}
.top-padding-double {
  margin-top: -55px;
}
