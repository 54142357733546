@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";

#messages {
  position: relative;
  margin-top: 5px;
  img {
    height: 30px;
  }
}

#messages-container {
  min-width: 280px;
  @include media-breakpoint-up(md) {
    min-width: 300px;
  }
}

.unread-message {
  font-weight: 800;
}

#messages-empty {
  height: 80vh;
  width: 100%;
  background-color: white;
  @include media-breakpoint-up(lg) {
    height: 300px;
    width: 300px;
  }
}

#message-count {
  background-color: $danger;
}

.message-count-small {
  position: absolute;
  width: 18px;
  height: 18px;
  top: -8px;
  border-radius: 10px;
  right: 8px;
}


.message-header{
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-count-large {
  position: absolute;
  width: 25px;
  height: 25px;
  top: -13px;
  border-radius: 15px;
  right: -5px;
}

.message-error {
  height: 300px;
  width: 280px;
  padding: 15px;

  @include media-breakpoint-up(lg) {
    width: 100%;
  }
}
.message-error-view {
  width: 270px;
}

#message-text {
  color: white;
  position: absolute;
  left: 0px;
  top: 0px;
  font-size: 12px;
  width: 100%;
}

.mobile-header-messages {
  width: 50px;
}

.modal-body-messsage-header {
  font-weight: 700;
  font-size: 24px;
}





.modal-body-message-header::first-letter {
  text-transform: uppercase;
}

.modal-body-message-datetime {
  font-weight: normal;
  color: $gray-3;
  font-size: 14px;
}

.modal-body-message {
  font-size: 18px;
  font-weight: normal;
  padding: 20px 0px;
}

.messages-area {
  width: 90%;
  background: white;
  min-height: 800px;
  padding: 0px 0px;
}

.message-modal-title {
  color: $danger;
  font-weight: 700;
  display: block;
  flex-grow: 1;
  //width: 250px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-modal-title:first-letter {
  text-transform: uppercase;
}

.message-text {
  font-size: 14px;
  padding-left: 10px;
  @include media-breakpoint-up(lg) {
    padding: 5px 9px;
    padding-top: 2px;
  }
  padding-bottom: 10px;
  overflow: hidden;
  white-space: normal;
}

.message-action-btn {
  height: 44px;
}

.expanded {
  padding-bottom: 0px !important;
}

.message-content {
  padding: 10px;
  padding-top: 12px;
  padding-left: 16px;
  border-bottom: 1px solid $gray-1;
  border-top: 1px solid $gray-1;
  cursor: pointer;
}

.message-status-green {
  height: 7px;
  width: 7px;
  margin-top: 20px;
  margin-right: 8px;
  margin-left: 9px;
  border-radius: 100%;
  float: left;
}

.read {
  background-color: white;
}

.un-read {
  background-color: $primary;
}

.message-end {
  margin: 0px;
}

#message-box {
  max-width: 300px;
  display: block;
}

.message-date-time {
  color: $gray-3;
  font-size: 12px;
  white-space: nowrap;
  //padding: 3px 5px;
}

.mobile-message-content {
  border-top: 1px solid $gray-1;
  border-bottom: 1px solid $gray-1;
  // padding-bottom: 10px;
  // padding-top: 5px;
  padding: 10px;
}

.trash-icon {
  color: $gray-2;
  font-size: 24px;
  margin-bottom: -5px;
  cursor: pointer;
}

.mobile-down-arrow {
  font-size: 25px;
  font-weight: 700;
  line-height: 0px;
}

#message-modal-body-container {
  padding-top: 10px;
  padding-bottom: 20px;
  border-top: 1px solid $gray-1;
  border-bottom: 0.5px solid $gray-1;
}

.message-alert-modal-title {
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
}
