@media (max-width: 991.98px) {
  .modal-dialog {
    top: unset !important;
  }
}
.update-success {
  height: auto;
  min-height: 80vh;
  padding-bottom: 55px;
}
.update-success .status-container {
  min-height: 40px;
}
.update-success .header-parent-appear {
  margin-top: -42px;
}
.update-success .header-parent-appear.header-parent-appear-active {
  margin-top: 0px;
  transition: margin-top 0.5s ease-in;
}
.update-success .update-success-button {
  margin-bottom: 16px;
}
@media (min-width: 768px) {
  .update-success .update-success-button {
    width: 255px;
    margin-right: 22px;
  }
}
.update-success .margin-bottom {
  margin-bottom: 25px !important;
}
.update-success .status {
  width: 100%;
  min-height: 42px;
}
.update-success .fa-check-circle {
  font-size: 28px;
}
.update-success .status-info {
  font-size: 18px;
  font-weight: 700;
  display: inline-block;
  padding-left: 0px;
}
.update-success .status-text {
  font-size: 18px;
  color: white;
  padding-left: 8px;
  font-weight: 400;
}
.update-success .status-description {
  font-weight: 400;
  display: block;
  color: #333333;
  font-size: 14px;
  margin-bottom: 5px;
  text-decoration: none !important;
}
@media (min-width: 768px) {
  .update-success .status-description {
    font-size: 16px;
  }
}
.update-success h4 {
  color: #333333;
  margin-bottom: 0;
}
.update-success .margin-bottom {
  margin-bottom: 20px;
}
.update-success .content h1 {
  min-height: 50px;
  color: #28373a;
  margin: 0;
  display: flex;
  align-items: center;
}
@media (min-width: 768px) {
  .update-success .content h1 {
    min-height: 60px;
  }
}
@media (min-width: 992px) {
  .update-success .content h1 {
    min-height: 77px;
  }
}
.update-success .content .padding {
  padding: 15px;
}
.update-success .content .success-container {
  height: auto;
  border-width: 2px;
  border-style: solid;
  border-color: #E4E4E4;
  border-radius: 8px;
  margin-bottom: 25px;
}

.update-success .status-container {
  height: unset !important;
}

.status-header-container #status-header {
  height: unset !important;
}

.update-success .status {
  height: unset !important;
}

.update-success .status-text {
  padding: 2px 10px;
}

.status-text .status-info {
  padding-left: 0px !important;
}

