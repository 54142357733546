.pet-form {
  padding: 30px 15px;
}
@media (max-width: 991.98px) {
  .pet-form .segment-label img {
    display: none;
  }
}
.pet-form .why-important {
  font-size: 13px;
}
.pet-form .error {
  margin: -10px 0 10px 0;
  font-size: 12px;
  font-weight: 650;
}
.pet-form .dropdown-disabled {
  opacity: 0.3;
  pointer-events: none;
}
.pet-form .dropdown-container {
  color: #333 !important;
}
.pet-form .noQuoteMessage {
  font-size: 18px;
  font-weight: 650;
}
@media (min-width: 992px) {
  .pet-form {
    padding-top: 0 !important;
  }
}

