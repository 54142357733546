.background {
  height: auto;
  width: 100%;
  padding: 0px;
}
.background .desktopView {
  display: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 100%;
  height: 580px;
}
.background .mobileView {
  height: auto;
  width: 100%;
  position: relative;
  font-family: "OpenSans-LightItalic", "Open Sans Light Italic", "Open Sans";
  font-weight: lighter;
  font-style: italic;
  font-size: 17px;
  color: #373737;
}
.background .mobileView .padding {
  padding: 15px 15px 15px 15px;
}
.background .mobileView .padding i {
  color: #FF5E0F;
}
.background .mobileView p {
  margin-bottom: 0px !important;
}
.background .mobileView .bolder {
  font-weight: 600;
}
.background .mobileView .greenText {
  font-family: "OpenSans-Regular", "Open Sans";
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: #19A64A;
}
.background .mobileView .mobNavtext {
  position: relative;
  border-color: #e4e4e4;
  border-width: 2px 0px 0px 0px;
  border-style: solid;
  padding: 0px;
}
.background .mobileView .mobNavtext .numbers {
  position: absolute;
  top: 11px;
  padding-left: 15px;
  font-weight: 700;
  font-style: normal;
  font-size: 24px;
  color: #FF5E0F;
}
.background .mobileView .mobNavtext .number2Content {
  margin-left: 50px;
  display: inline-flex;
  padding: 16px 10px;
  color: black !important;
  text-decoration: none;
  font-family: "OpenSans-SemiBold", "Open Sans SemiBold", "Open Sans";
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
}
.background .mobileView .mobNavtext i {
  float: right;
  padding: 10px 20px 0px 0px;
  font-size: 32px;
}
.background .mobileView .mobNavtext .greenPadding {
  padding-bottom: 30px;
  border-style: solid;
  border-color: #34A350;
  border-width: 0px 0px 4px 0px;
}
@media (max-width: 767.98px) {
  .background {
    background-image: none !important;
  }
}
@media (min-width: 1200px) {
  .background .gif {
    padding-left: 255px !important;
  }
  .background .arrowUp {
    left: 310px !important;
  }
  .background .appGoogle {
    left: 795px !important;
  }
  .background .appStore {
    left: 960px !important;
  }
}
@media (min-width: 992px) {
  .background {
    height: 640px;
  }
  .background .mobileView {
    display: none;
  }
  .background .desktopView {
    display: block;
  }
  .background .desktopView .display {
    display: none;
  }
  .background .desktopView .modal {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.568627451);
    z-index: 1001;
  }
  .background .desktopView .modalBox {
    background-color: white;
    height: 340px;
    width: 721px;
    margin: 16% auto;
    z-index: 1003;
  }
  .background .desktopView .modalBox .modalHeading {
    font-size: 30px;
    color: #35A34E;
    padding: 5px 15px;
  }
  .background .desktopView .modalBox .modalHeading i {
    float: right;
    color: black;
    cursor: pointer;
  }
  .background .desktopView .modalBox .modalContent {
    color: #666666;
    padding: 5px 15px;
  }
  .background .desktopView .modalBox .closeButton {
    width: 240px;
    height: 40px;
    font-family: "OpenSans-SemiBold", "Open Sans SemiBold", "Open Sans";
    font-weight: 650;
    font-style: normal;
    font-size: 18px;
    background-color: rgb(255, 90, 10);
    color: #FBFBFB;
    text-align: center;
    padding: 6px;
    margin-left: 15px;
    cursor: pointer;
  }
  .background .desktopView .gif {
    padding-top: 62px;
    padding-left: 90px;
    height: 550px;
    width: 80%;
  }
  .background .desktopView .gif .gifImg {
    width: 248px;
    height: 545px;
  }
  .background .desktopView .gif .orangeIcon {
    color: #FF5E0F;
  }
  .background .desktopView .gif .text1 {
    position: relative;
    bottom: 515px;
    left: 310px;
    font-family: "OpenSans-LightItalic", "Open Sans Light Italic", "Open Sans";
    font-weight: lighter;
    font-style: italic;
    font-size: 22px;
    color: #373737;
  }
  .background .desktopView .gif .text1 .bolder {
    font-weight: 600;
  }
  .background .desktopView .gif .text1 .greenText {
    font-family: "OpenSans-Regular", "Open Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 30px;
    color: #19A64A;
  }
  .background .desktopView .thirdMargin {
    margin-top: 90px !important;
  }
  .background .desktopView .text2 {
    margin-top: 10px;
    position: relative;
    bottom: 515px;
    left: 310px;
  }
  .background .desktopView .text2 .numbers {
    position: absolute;
    top: 15px;
    font-weight: 700;
    font-style: normal;
    font-size: 28px;
    color: #FF5E0F;
  }
  .background .desktopView .text2 .number1Content {
    margin-left: 50px;
    font-family: "OpenSans-Regular", "Open Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #4F4F4F;
  }
  .background .desktopView .text2 .number1Content p {
    margin-bottom: 0px;
  }
  .background .desktopView .text2 .number1Content .specialP {
    display: flex;
    font-family: "OpenSans-Regular", "Open Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    text-decoration: underline;
    color: #E17440;
    cursor: pointer;
  }
  .background .desktopView .text2 .number2Content {
    margin-left: 50px;
    display: inline-flex;
    border: 2px solid #E4E4E4;
    padding: 16px 10px;
    width: 290px;
    height: 63px;
    color: black !important;
    text-decoration: none;
    border-radius: 10px;
    position: absolute;
    top: 7px;
    font-family: "OpenSans-Bold", "Open Sans Bold", "Open Sans";
    font-weight: 700;
    font-style: normal;
  }
  .background .desktopView .text2 .number2Content i {
    position: absolute;
    left: 255px;
    bottom: 15px;
  }
  .background .desktopView .text2 .number2Content:hover {
    text-decoration: none;
  }
  .background .desktopView .footer {
    position: absolute;
    bottom: inherit;
    width: 100%;
    height: 93px;
    background-color: #717171;
  }
  .background .desktopView .footer .arrowUp {
    position: absolute;
    left: 210px;
    top: 34px;
    width: 475px;
    height: 24px;
  }
  .background .desktopView .footer .arrowUp i {
    color: white;
    font-size: 30px;
  }
  .background .desktopView .footer .arrowUp .arrowText {
    position: absolute;
    display: inline;
    font-style: inherit;
    font-size: large;
    font-weight: 500;
    margin-left: 10px;
    color: white;
  }
  .background .desktopView .footer .appStore {
    border-width: 0px;
    display: inline;
    left: 700px;
    top: 25px;
    position: absolute;
  }
  .background .desktopView .footer .appStore .appStoreImg {
    width: 149px;
    height: 44px;
  }
  .background .desktopView .footer .appGoogle {
    border-width: 0px;
    display: inline;
    left: 860px;
    top: 25px;
    position: absolute;
  }
  .background .desktopView .footer .appGoogle .appGoogleImg {
    width: 149px;
    height: 44px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .background {
    height: 575px;
  }
  .background .mobileView {
    display: none;
  }
  .background .desktopView {
    background-size: 1200px;
    display: block;
  }
  .background .desktopView .display {
    display: none;
  }
  .background .desktopView .modal {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.568627451);
    z-index: 1001;
  }
  .background .desktopView .modalBox {
    background-color: white;
    height: 340px;
    width: 721px;
    margin: 16% auto;
    z-index: 1003;
  }
  .background .desktopView .modalBox .modalHeading {
    font-size: 30px;
    color: #35A34E;
    padding: 5px 15px;
  }
  .background .desktopView .modalBox .modalHeading i {
    float: right;
    color: black;
    cursor: pointer;
  }
  .background .desktopView .modalBox .modalContent {
    color: #666666;
    padding: 5px 15px;
  }
  .background .desktopView .modalBox .closeButton {
    width: 240px;
    height: 40px;
    font-family: "OpenSans-SemiBold", "Open Sans SemiBold", "Open Sans";
    font-weight: 650;
    font-style: normal;
    font-size: 18px;
    background-color: rgb(255, 90, 10);
    color: #FBFBFB;
    text-align: center;
    padding: 6px;
    margin-left: 15px;
    cursor: pointer;
  }
  .background .desktopView .gif {
    padding-top: 62px;
    padding-left: 90px;
    height: 490px;
    width: 100%;
  }
  .background .desktopView .gif .gifImg {
    display: none;
  }
  .background .desktopView .gif .orangeIcon {
    color: #FF5E0F;
  }
  .background .desktopView .gif .text1 {
    bottom: 515px;
    left: 310px;
    font-family: "OpenSans-LightItalic", "Open Sans Light Italic", "Open Sans";
    font-weight: lighter;
    font-style: italic;
    font-size: 22px;
    color: #373737;
  }
  .background .desktopView .gif .text1 .bolder {
    font-weight: 600;
  }
  .background .desktopView .gif .text1 .greenText {
    font-family: "OpenSans-Regular", "Open Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 30px;
    color: #19A64A;
  }
  .background .desktopView .thirdMargin {
    margin-top: 90px !important;
  }
  .background .desktopView .text2 {
    margin-top: 10px;
  }
  .background .desktopView .text2 .numbers {
    position: absolute;
    font-weight: 700;
    margin-top: 10px;
    font-style: normal;
    font-size: 28px;
    color: #FF5E0F;
  }
  .background .desktopView .text2 .number1Content {
    margin-left: 50px;
    font-family: "OpenSans-Regular", "Open Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
    color: #4F4F4F;
  }
  .background .desktopView .text2 .number1Content p {
    margin-bottom: 0px;
  }
  .background .desktopView .text2 .number1Content .specialP {
    display: flex;
    font-family: "OpenSans-Regular", "Open Sans";
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    text-decoration: underline;
    color: #E17440;
    cursor: pointer;
  }
  .background .desktopView .text2 .number2Content {
    margin-left: 50px;
    display: inline-flex;
    border: 2px solid #E4E4E4;
    padding: 16px 10px;
    width: 290px;
    height: 63px;
    color: black !important;
    text-decoration: none;
    border-radius: 10px;
    position: absolute;
    font-family: "OpenSans-Bold", "Open Sans Bold", "Open Sans";
    font-weight: 700;
    font-style: normal;
  }
  .background .desktopView .text2 .number2Content i {
    position: absolute;
    left: 255px;
    bottom: 15px;
  }
  .background .desktopView .text2 .number2Content:hover {
    text-decoration: none;
  }
  .background .desktopView .footer {
    position: absolute;
    bottom: inherit;
    width: 100%;
    height: 93px;
    background-color: #717171;
  }
  .background .desktopView .footer .arrowUp {
    position: absolute;
    left: 1%;
    top: 34px;
    width: 475px;
    height: 24px;
  }
  .background .desktopView .footer .arrowUp i {
    color: white;
    font-size: 30px;
  }
  .background .desktopView .footer .arrowUp .arrowText {
    position: absolute;
    display: inline;
    font-style: inherit;
    font-size: large;
    font-weight: 500;
    margin-left: 10px;
    color: white;
  }
  .background .desktopView .footer .appStore {
    border-width: 0px;
    display: inline;
    left: 482px;
    top: 25px;
    position: absolute;
  }
  .background .desktopView .footer .appStore .appStoreImg {
    width: 130px;
    height: 44px;
  }
  .background .desktopView .footer .appGoogle {
    border-width: 0px;
    display: inline;
    left: 630px;
    top: 25px;
    position: absolute;
  }
  .background .desktopView .footer .appGoogle .appGoogleImg {
    width: 130px;
    height: 44px;
  }
}

