.new-address-container {
  display: block;
}
@media (min-width: 992px) {
  .new-address-container {
    min-height: 60vh;
  }
}
.new-address-container #changeAddress-zipCode .note-view {
  width: 100%;
}
@media (min-width: 768px) {
  .new-address-container #changeAddress-zipCode .note-view {
    width: 470px;
  }
}
.new-address-container .note-icon-size {
  font-size: 24px;
}
.new-address-container .title-container {
  padding: 15px 0;
  color: #28373a;
}
@media (min-width: 768px) {
  .new-address-container .title-container {
    height: 79px !important;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
@media (min-width: 992px) {
  .new-address-container .title-container {
    height: 97px !important;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
.new-address-container .address-container {
  width: 100%;
}
@media (min-width: 768px) {
  .new-address-container .address-container {
    width: 470px;
  }
}
.new-address-container .button-container {
  width: 100%;
}
@media (min-width: 768px) {
  .new-address-container .button-container {
    width: 220px;
  }
}
.new-address-container .check-container {
  width: 100%;
}

.h1_v2 {
  font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
  color: #28373a !important;
  line-height: normal;
  font-size: 21px;
  font-weight: 400;
  margin-bottom: 0;
}

.next-arrow-circle-right-custom {
  margin-left: 5px;
  padding-bottom: 1px;
}

.mobileTextV2 {
  font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
  color: #657493 !important;
  font-size: 14px;
}

