.faq-box-container {
  margin-top: 5px;
  width: 100%;
}
@media (min-width: 992px) {
  .faq-box-container {
    border: 2px solid #E4E4E4;
    border-radius: 8px;
    background: #F0F0EE;
  }
}
.faq-box-container a {
  text-decoration: none !important;
}
.faq-box-container .faq-header {
  color: #28373a;
  font-size: 23px;
  padding: 15px 15px 10px 15px;
  border-bottom: 2px solid #E4E4E4;
}
.faq-box-container .link-text {
  font-weight: 700;
}
.faq-box-container .link-icon {
  font-size: 18px;
}
.faq-box-container .faq-body {
  background: #ffffff;
}
.faq-box-container .faq-body .faq {
  width: 100%;
  display: block;
  padding: 10px 15px 10px 15px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  border-bottom: 2px solid #E4E4E4;
  color: #6b61f2;
  cursor: pointer;
}
.faq-box-container .faq-body .faq-more-info {
  border-bottom: none !important;
  font-family: "OpenSans-Bold", "Open Sans Bold", "Open Sans" !important;
  font-weight: 700 !important;
  color: #6b61f2 !important;
}
.faq-box-container .faq-footer {
  padding: 15px;
  border-top: 2px solid #E4E4E4;
}
.faq-box-container .collapse-margin {
  margin-top: 10px;
}
.faq-box-container .faq-mobile-container {
  border-width: 2px;
  border-style: solid;
  border-color: #E4E4E4;
  border-radius: 8px;
}

.modal-close-button {
  width: 100%;
}
@media (min-width: 768px) {
  .modal-close-button {
    width: 238px;
  }
}

