.dont-cancel-yet {
  margin-top: 20px;
}
.dont-cancel-yet a {
  font-weight: 700;
}
@media (max-width: 991.98px) {
  .dont-cancel-yet .title-container {
    margin-bottom: 15px;
  }
}
.dont-cancel-yet .content-wrapper {
  padding: 22px 20px;
  border: 2px solid #038062;
  height: 100%;
  border-radius: 10px;
  overflow-wrap: break-word;
  color: #038062;
}
.dont-cancel-yet .content-links a {
  text-decoration: unset;
}
@media (min-width: 992px) {
  .dont-cancel-yet {
    margin-bottom: 40px;
    margin-top: 0;
  }
}
.dont-cancel-yet .button-container {
  width: 100%;
}
@media (min-width: 768px) {
  .dont-cancel-yet .button-container {
    width: 300px;
  }
}

