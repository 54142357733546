@import "../../../styles/mixins";

.change-policy-confirmation {
  padding: 0 15px;

  .summary-content {
    border: 2px solid rgba(228, 228, 228, 1);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 25px;

    .summary-list {
      padding-inline-start: 20px;
    }

    .font-weight-bold-new{
      color:#657493;
      font-weight: 500;
    }
    .new-summary-list{
      color: #333333;
      font-family: "ArialMT", "Arial", sans-serif;
      font-weight: 550;
    }

    .keep-in-mind-content {
      font-size: 13px;
    }
  }
  @include media-breakpoint-up(lg) {
    padding-top: 0 !important;
  }
}
