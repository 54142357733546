@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";

#file-upload-preview-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: 0 auto;
  width: 100%;
  text-align: center;

  .zoom-view {
    margin: 25px;
    padding: 25px 10px;
    background-color: white;
    top: 100px;
    position: relative;
    @include media-breakpoint-up(sm) {
      height: 667px;
      width: 500px;
    }
    @include media-breakpoint-up(md) {
      height: 860px;
      width: 645px;
    } 
    width: 266px;
    height: 355px;
    margin: 0 auto;
  }

  .preview-arrow-button {
    height: 30px;
    width: 30px;
    align-self: center;
    color: black;
    flex-shrink: 0;
  }

  .delete-icon-zoom {
    width: 10px;
    height: 10px;
    float: right;
    transform: translate(-50%, -50%);
  }

  .image-number-zoom {
    font-size: 20px;
    font-weight: bold;
    color: white;
    background-color: $primary;
    padding: 5px;
    margin-bottom: 0px;
    position: absolute;
    width: 30px;
    height: 40px;
  }

  .trash-icon-zoom {
    margin-top: 20px;
    width: 30px;
    height: 30px;
    float: right;
  }

  .zoomed-preview {
    border: 2px solid $primary;
    height: 90%;
    width:100%;
  }


  .btn-close {
    margin-top: 20px !important;
    width: 50% !important;
  }

  .preview-fallback {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: black;
  }
  .download-icon {
    color: $secondary;
  }

}