@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";


.text-color-violet1 {
    color: $violet-1 !important;
}

.text-color-solidgreen3 {
    color: $solidgreen-3 !important;
}

.file-claim {
  .text-primary {
    margin: 15px 0px;
  }
}
.text-primary-heading {
  color: $header-Color !important;
}

.claims {
  padding-top: 10px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  font-size: 14px;

  .section-check-status {
    padding-right: 5px;
    display: inline-block;
  }

  .upload-file-edit {
    color: $secondary;
    font-weight: 700;
    text-decoration: underline;
    cursor: pointer;
  }

  .problem-upload-toggler {
    font-weight: 700;
    font-size: 12px;
    margin-bottom: 10px;
    cursor: pointer;
    color: $text-Blue !important;
  }

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }

  .claim-collapse-box {
    border: 2px solid $light;
    border-radius: 10px;
    margin-bottom: 15px;

    .collapse-box-item {
      padding: 10px;
      border-bottom: 2px solid $light;
      background: #ffffff;
    }

    .collapse-box-item:last-of-type {
      border-bottom: none !important;
    }

    .selected {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background: #FFFFFF;
    }

    .disable {
      color: $gray-2;
      pointer-events: none;

      i {
        display: none;
      }
    }
    .cancel-btn {
      color: $text-Blue !important;
    }

    .claimform-submit-box {
      @include media-breakpoint-up(sm) {
        margin-left: 14px;
        margin-right: 14px;
      }
    }

    .box-title {
      font-weight: 700;
      @include media-breakpoint-up(sm) {
        margin-left: 14px;
        margin-top: 10px;
      }

      span {
        font-weight: normal;
      }

      i {
        font-size: 22px !important;
      }
    }

    textarea {
      font-size: 12px;
      font-weight: normal;
      width: 100%;
      margin: 10px 0;
      font-size: 10px;
      height: 90px;

      @include media-breakpoint-up(md) {
        font-size: 12px;
        height: 45px;
      }
    }

    .btn-secondary {
      font-weight: 700;
      padding: 12px;
      width: 100%;
      border-radius: 5px;
      border: none;

      @include media-breakpoint-up(md) {
        width: 65%;
      }
    }

    .btn-secondary:hover {
      background: $secondary !important;
      color: #ffffff !important;
    }

    .disabled-btn {
      background: $gray-3 !important;
      pointer-events: none;
    }

    .cancel-btn {
      font-weight: 700;
      width: 25%;
      text-align: center;
      text-decoration: underline;
      border: none;
      background: none;
    }
  }

  .faq-toggle {
    font-weight: 700;
  }
}

.modal-dialog.message-modal {
  max-width: 700px !important;
}
@media (max-width: 767px) {
  .modal-dialog {
    top: 140px;
  }
}
@media (min-width: 768px) {
  .modal-dialog {
    top: 40px;
  }
}

.modal-header {
  border: none !important;

  .title-container {
    padding-top: 20px;
  }
  .modal-title {
    font-size: 21px;
    font-weight: 400;
    line-height: none;
  }

  button.close {
    padding: 0.5rem;
  }
  .close span {
    font-size: 30px;
    font-weight: 700;
    color: $dark;
    font-family: Arial, Helvetica, sans-serif;
  }
  span {
    font-size: 14px;
    font-weight: normal;
  }

  @include media-breakpoint-up(md) {
    .title-container {
      padding: 20px 0 0 20px;
    }

    .modal-title {
      font-size: 30px;
    }
  }
}

.modal-body {
  border: none !important;

  .body-container {
    padding: 0;
  }

  .modal-buttons {
    margin-top: 20px;

    .btn-secondary {
      font-weight: 700;
      font-size: 14px;
      padding: 10px;
      width: 60%;
      border-radius: 5px;
      border: none;
    }

    .btn-secondary:hover {
      background-color: $button_Green !important;
      color: #ffffff !important;
    }

    .btn-skip {
      border: none;
      width: 40%;
      text-align: center;
      color: $button_Green;
      background: none;
      font-weight: 700;
      text-align: center;
    }

    .btn-skip:hover {
      cursor: pointer;
      text-decoration: underline !important;
    }
  }

  @include media-breakpoint-up(md) {
    .body-container {
      padding: 0 20px;
    }
    .pet-joined-date {
      width: 65%;
    }

    .btn-secondary {
      font-size: 16px;
      width: 40% !important;
    }

    .btn-skip {
      width: 30% !important;
    }
  }
}

.modal-footer {
  border: none !important;
}
