.confirm-charges {
  padding: 30px 15px 0 15px;
}
.confirm-charges .link-text {
  color: #6b61f2;
  font-weight: bold;
}
.confirm-charges .summary-content {
  font-weight: normal;
}
.confirm-charges .billing-summary {
  border: 2px solid rgb(228, 228, 228);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 25px;
}
.confirm-charges .billing-summary .summary-list {
  padding-inline-start: 20px;
}
.confirm-charges .terms-conditions {
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 25px;
  height: 88px;
}
.confirm-charges .agreement {
  margin-bottom: 25px;
}
.confirm-charges .disabled-btn {
  background: #dae3e8;
  pointer-events: none;
}
@media (min-width: 992px) {
  .confirm-charges {
    padding-top: 0 !important;
  }
}

@media (min-width: 768px) {
  .confirm-charges {
    padding: 30px 15px;
  }
  .confirm-charges .summary-content {
    font-weight: bold;
  }
  .confirm-charges .terms-conditions {
    height: 44px;
  }
}

