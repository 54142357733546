@import "../../../styles/mixins";
@import "../../../styles/variables";

.message-modal {
  max-width: 700px;
}
.modal-content{
    border-radius: 20px;
}
.modalBoxContent{
    font-size:18px;
}


.message-footer {
  border-top: 30 !important;
  div {
    cursor: pointer;
  }
}

.message-body {
  font-weight: 400;
  font-size: 18px;
  color:  #4f4f4f;
  padding: 0px 27px 0px 27px !important;
  text-align: left;
  // word-break: break-all;

  @media (max-width: 767px) {
    .modalBoxContent{
        font-size:16px;
    }
  }
}

.message-header-container {
  margin-left: 11px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 30px;
  color: #333333;
  line-height: normal;
  text-align: left;
}