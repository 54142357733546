.pet-plan-collapse {
  border: 2px solid #E4E4E4;
  padding: 9px 15px;
  margin-top: -2px;
}
.pet-plan-collapse .pet-plan-header {
  font-weight: bold;
  margin-bottom: 0;
}
.pet-plan-collapse .pet-plan-header .pet-collapse-button {
  position: absolute;
  right: 50px;
  margin-top: -15px;
}
.pet-plan-collapse .pet-plan-header .pet-collapse-button .arrow-icon {
  font-size: 36px;
  color: #6b61f2;
}
.pet-plan-collapse .pet-plan-header .month-text {
  font-size: 12px;
  font-weight: normal;
}
.pet-plan-collapse .rate-title, .pet-plan-collapse .rate-text {
  padding-left: 25px;
}

@media (min-width: 768px) {
  .pet-plan-collapse {
    padding: 5px 25px;
  }
}

