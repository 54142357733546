@import "./styles/_variables.scss";
@import "./styles/_mixins.scss";

.content-container {
  padding-top: $header-height-mobile + $header-line-height;

  @include media-breakpoint-up(sm) {
    padding-top: $header-height-tablet + $header-line-height;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 0px;
  }
}

#chatButtons {
  display: none;
}