@import "./../../../styles/_variables.scss";
@import "./../../../styles/_mixins.scss";

.text-color-violet {
    color: $violet-1 !important;
}

#file-upload-component {
    #file-upload {
        @include media-breakpoint-up(sm) {
            padding: 15px;
            padding-top: 25px;
        }

        position: relative;

        .preview-fallback {
            display: flex;
            height: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
        }

        .download-icon {
            color: $secondary;
        }
    }

    .button-disabled {
        background-color: $gray-2 !important;
        pointer-events: none !important;
    }

    .mobile-zoomed-image-container {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }

    .mobile-zoomed-image-view::after {
        display: block;
        position: absolute;
        background-image: -webkit-linear-gradient(top, white 0, #646262 100%);
        background-image: linear-gradient(to bottom, white 0, #646262 100%);
        margin-top: 148px;
        height: 15%;
        width: 100%;
        content: "";
        bottom: 0;
        left: 0;
        opacity: 0.8;
    }

    .zoom-icon {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 38px;
        left: 38px;

        @include media-breakpoint-up(sm) {
            top: 53px;
            left: 50px;
            width: 30px;
            height: 30px;
        }

        transform: translate(-50%, -50%);
    }

    .upload-error-exclam {
        color: $danger;
    }

    .zoom-out-icon {
        font-size: 23px;
        padding: 10px;
        position: absolute;
        color: white;
        left: 18px;
        bottom: -20px;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    .file-upload-overlay {
        /*background: rgba(0, 0, 0, 0.5);*/
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
    }

  h4 {
    font-weight: 800;
    padding: 2px;
    font-size: 16px;
    margin-bottom: 0px;
    margin-left:10px;
    color: $home-button !important;
    @include media-breakpoint-up(sm) {      
      font-weight: 700;
      padding: 5px;
      color: $home-button !important;
    }
  }

  .row {
    margin-left: 0px;
    margin-right: 0px;
  }

  /* Upload */
  .upload {    
    @include media-breakpoint-up(sm) {
      border: 2px solid #00c885;
      padding: 25px;
      text-align: center;
    }
    width: 100%;
    border: 2px solid #00c885;
    padding: 10px;    
    border-radius: 8px;
  }
  .upload-icon {
    height: 60px;
    width: 60px;
    @include media-breakpoint-up(md) {
    margin-bottom: 10px;
    }
  }
  .drag-text-header {
    font-size: 16px;
    color: #038062 !important;
    font-weight: bold;

    @include media-breakpoint-up(md) {
      font-size: 20px;
    }
  }
  .drag-text {
    font-weight: 400;
    font-size: 20px;
    color: #038062;
  }
  .btn-select {
    background-color:$button_Green;
    color: white;
    border-radius: 8px;
    width: 100%;
    border: 0px;
    @include media-breakpoint-up(sm) {
      width: 164px;
    }
    font-weight: 700;
    font-size: 16px;
    padding: 8px;
    // padding: 10px 50px 10px 50px;
  }

  .btn-select:hover {
    background-color: $button_Green !important;
    // color: white !important;
  }

  /* AddFiles */
  .btn-add-files {
    width: 73px;
    height: 73px;
    text-align: center;
    margin: 0 auto;
    font-size: 12px;
    border-radius: 8px;
    @include media-breakpoint-up(sm) {
      width: 100px;
      height: 100px;
      font-size: 16px;
    }
    border: 2px solid $home-button;
    position: relative;
    margin: 0px 20px 20px 0px;
  }

  /* SelectionView */
  .upload-edit {
    width: 100%;
    border-radius: 8px;
    @include media-breakpoint-up(sm) {
      border: 2px solid #00c885;
      padding: 25px;
    }
  }

    .selection-container {
        position: relative;
        margin: 0px 20px 20px 0px;
        border: 3px solid $primary;
    }

    .file-upload-loader {
        position: absolute;
        bottom: 10px;
        text-align: center;
        color: white;
        font-weight: 700;
    }

    .upload-error {
        border: 3px solid $danger !important;
    }

    .preview-selected {
        width: 266px;
        height: 355px;
        margin: 10px auto;

        @include media-breakpoint-up(sm) {
            width: 100%;
        }
    }

    .prev-img {
        display: flex;
        align-items: center;

        img {
            @include media-breakpoint-up(sm) {
                max-height: 100px;
                max-width: 100px;
            }

            max-height: 73px;
            max-width: 73px;
        }

        @include media-breakpoint-up(sm) {
            height: 100px;
            width: 100px;
        }

        height: 73px;
        width: 73px;
        background: $dark;
        filter: brightness(80%);
    }

    .zoomed-image {
        @include media-breakpoint-up(sm) {
            height: 100px;
            width: 100px;
        }

        height: 73px;
        width: 73px;
        background: $dark;
    }

    .zoomed-pdf {
        height: 100%;
        width: 100%;
        background: $dark;
    }

    .image-number {
        min-height: 18px;
        min-width: 18px;
        top: 7px;
        left: 8px;
        font-size: 12px;
        padding: 0px 4px;

        @include media-breakpoint-up(sm) {
            min-height: 23px;
            min-width: 23px;
            top: 10px;
            left: 11px;
            padding: 0px 5px;
            font-size: 16px;
        }

        font-weight: bold;
        color: white;
        background-color: $primary;
        position: absolute;
        transform: translate(-50%, -50%);
    }

    .file-upload-error {
        background-color: $danger;
    }

    .delete-icon {
        width: 10px;
        height: 10px;
        position: absolute;

        @include media-breakpoint-up(sm) {
            left: 88px;
            width: 15px;
            height: 15px;
            top: 11px;
        }

        top: 7px;
        left: 66px;
        transform: translate(-50%, -50%);
    }

    .garpage-icon {
        font-size: 23px;
        padding: 10px;
        position: absolute;
        bottom: -20px;
        color: white;
        right: -20px;
        transform: translate(-50%, -50%);
    }

    .note-text {
        font-size: 12px !important;
        font-weight: bold !important;
        line-height: 1 !important;
    }

    .note-subtext {
        font-size: 12px !important;
        line-height: 1 !important;
    }
}

.btn-close {
  margin-top: 20px !important;
  width: 50% !important;
}

.file-upload-modal-button {
  width: 150px;
  margin: 10px;
}

.file-info {
  font-size: 10px;
  font-weight: 400;

  @include media-breakpoint-up(sm) {
    font-size: 12px;
  }
}
