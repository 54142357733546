@import "../../../../styles/variables";
@import "../../../../styles/mixins";

.status-message {
    padding: 10px 15px;
    font-size: 13px;
    margin-top: 10px;
    margin-left: 10px;

    .icons {
        font-size: 28px;
    }

    @include media-breakpoint-up(md) {
        font-size: 14px;
        padding: 15px;
    }
}