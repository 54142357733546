#alerts-header-container {
  color: white;
  font-size: 14px;
  transform-origin: top;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
#alerts-header-container .alert-header-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
#alerts-header-container .alert-header-ellipse .read-more {
  text-decoration: underline;
  font-weight: 600;
}
#alerts-header-container.alerts-color {
  background-color: #ff2f6d;
}
#alerts-header-container.notifications-color {
  background-color: #0097ab;
}
#alerts-header-container.alerts-error-color {
  background-color: #dae3e8;
}
#alerts-header-container .drop-angle-icon {
  font-size: 28px;
  font-weight: 500;
  padding-left: 5px;
  color: white;
  cursor: pointer;
}

#alerts-content-container {
  position: absolute;
  color: white;
  width: 100%;
  z-index: 40;
  font-size: 14px;
}
#alerts-content-container .alerts-color {
  background-color: #ff2f6d;
}
#alerts-content-container .notifications-color {
  background-color: #0097ab;
}
#alerts-content-container .alerts-error-color {
  background-color: #dae3e8;
}
#alerts-content-container .alerts-content {
  transform: scaleY(0);
  transform-origin: top;
  transition: all 250ms ease-in-out;
  padding-bottom: 5px;
}
#alerts-content-container a {
  color: white !important;
}
#alerts-content-container ul {
  padding-left: 20px;
  margin-bottom: 0px;
}
#alerts-content-container .action-button {
  width: 100%;
  background-color: white;
  color: #ff2f6d;
  height: 40px;
  margin: 7px 0px;
  border-radius: 7px;
  font-size: 14px;
  padding: 5px;
  box-shadow: none !important;
  font-weight: 650;
}
@media (min-width: 768px) {
  #alerts-content-container .action-button {
    width: 44%;
    max-width: 300px;
    margin-right: 10px;
  }
}
@media (min-width: 992px) {
  #alerts-content-container .action-button {
    min-width: 17%;
  }
}
#alerts-content-container .action-button-notification {
  color: #0097ab;
  margin-left: 0px;
  padding: 5px 5px;
}
#alerts-content-container .action-button-notification:hover {
  color: blue !important;
}
#alerts-content-container .open {
  /* this is blocking fixed positioning of chat buttons */
  transform: scaleY(1);
}

