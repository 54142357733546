@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";

.edit-field-reimbrusement-container {
    
    h4 {
        margin-bottom: 0px;
    }

    .link-icon {
        font-size: 12px;
        text-decoration: none;
        font-weight: 700;
        margin-top: 5px;

        @include media-breakpoint-up(md) {
            margin-top: 0px;
        }
    }
    .button-link_cancel {
        margin-top: 6px;
    }
    .button-link {
        color: $text-Blue;
        font-weight: 700;
    }

    .button-mc-container {
        width: auto !important;

        @include media-breakpoint-up(md) {
            min-width: 466px !important;
        }
    }

    .select-description {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 0px;
        font-weight: 400;
        font-size: 14px;
        color:$header-Color;

        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
    }

    .form-container {
        margin-left: 15px;
        margin-right: 15px;
    }

    .street-address-container {
        width: auto !important;

        @include media-breakpoint-up(md) {
            width: 569px !important;
        }
    }

    .address-container {
        width: auto !important;

        @include media-breakpoint-up(md) {
            width: 269px !important;
        }
    }

    .button-container {
        width: auto !important;

        @include media-breakpoint-up(md) {
            width: 466px !important;
        }
    }

    .toggle-container {
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 0;
    }

    .toggle-image {
        max-width: 100%;
        max-height: 100%;
    }

    .toggle-header {
        font-weight: 700;
        font-size: 12px;
        color: $dark;

        margin-top: 15px;
        margin-left: 0;
        margin-right: 15px;
        margin-bottom: 0;
    }

    .toggle-description {
        font-weight: 400;
        font-size: 12px;
        color: $dark;
    }
}