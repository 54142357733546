#upload-missing-docs .upload-file-edit {
  color: #6b61f2;
  font-weight: 700;
  cursor: pointer;
}
#upload-missing-docs .text-danger {
  color: #6b61f2 !important;
}
#upload-missing-docs .text-secondary, #upload-missing-docs .text-blue {
  color: #6b61f2;
}
#upload-missing-docs .problem-upload-toggler {
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 10px;
  cursor: pointer;
}
@media (min-width: 768px) {
  #upload-missing-docs {
    font-size: 16px;
  }
}
@media (min-width: 992px) {
  #upload-missing-docs {
    width: 70%;
  }
}
#upload-missing-docs .upload-claim-info {
  font-size: 14px;
}
#upload-missing-docs .upload-claim-info span {
  font-weight: bold;
}
@media (min-width: 768px) {
  #upload-missing-docs .upload-claim-info {
    font-size: 16px;
  }
}
#upload-missing-docs .claim-collapse-box {
  border: 2px solid #E4E4E4;
  border-radius: 10px;
  margin-bottom: 15px;
  margin-top: 15px;
}
#upload-missing-docs .claim-collapse-box .collapse-box-item {
  padding: 10px;
  border-bottom: 2px solid #E4E4E4;
}
#upload-missing-docs .claim-collapse-box .collapse-box-item:last-of-type {
  border-bottom: none !important;
}
#upload-missing-docs .claim-collapse-box .selected {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
#upload-missing-docs .claim-collapse-box .disable {
  color: #CCCCCC;
  pointer-events: none;
}
#upload-missing-docs .claim-collapse-box .disable i {
  display: none;
}
#upload-missing-docs .claim-collapse-box .box-title {
  font-weight: 700;
}
@media (min-width: 576px) {
  #upload-missing-docs .claim-collapse-box .box-title {
    margin-left: 14px;
    margin-top: 10px;
  }
}
#upload-missing-docs .claim-collapse-box .box-title span {
  font-weight: normal;
}
#upload-missing-docs .claim-collapse-box .box-title i {
  font-size: 22px !important;
}
@media (min-width: 576px) {
  #upload-missing-docs .claim-collapse-box .tc-box {
    margin-left: 14px;
    margin-right: 14px;
  }
}
#upload-missing-docs .claim-collapse-box textarea {
  font-size: 12px;
  font-weight: normal;
  width: 100%;
  margin: 10px 0;
  font-size: 10px;
  height: 90px;
}
@media (min-width: 768px) {
  #upload-missing-docs .claim-collapse-box textarea {
    font-size: 12px;
    height: 45px;
  }
}
#upload-missing-docs .claim-collapse-box .btn-secondary {
  font-weight: 700;
  padding: 12px;
  width: 100%;
  border-radius: 30px;
  border: none;
  background-color: #4aa574;
}
@media (min-width: 768px) {
  #upload-missing-docs .claim-collapse-box .btn-secondary {
    width: 65%;
  }
}
#upload-missing-docs .claim-collapse-box .btn-secondary:hover {
  background: #4aa574 !important;
  color: #ffffff !important;
}
#upload-missing-docs .claim-collapse-box .disabled-btn {
  background: #dae3e8 !important;
  pointer-events: none;
}
#upload-missing-docs .claim-collapse-box .cancel-btn {
  font-weight: 700;
  width: 25%;
  text-align: center;
  border: none;
  background: none;
}
#upload-missing-docs .faq-toggle {
  font-weight: 700;
}
#upload-missing-docs .missing-doc-info-container {
  border: 2px solid #ff2f6d;
  border-radius: 10px;
  padding: 10px 20px;
  color: #ff2f6d;
  font-weight: 400;
}

.missing-doc-faq-box {
  border: 2px solid #E4E4E4;
  border-radius: 8px;
  margin-top: 5px;
  background: #F0F0EE;
}
.missing-doc-faq-box .missing-doc-faq-header {
  color: #34A350;
  font-size: 23px;
  padding: 15px 15px 10px 15px;
  border-bottom: 2px solid #E4E4E4;
}
.missing-doc-faq-box .missing-doc-faq-body {
  background: #ffffff;
}
.missing-doc-faq-box .missing-doc-faq-body .missing-doc-faq {
  font-weight: 700;
  padding: 10px;
  border-bottom: 1px solid #E4E4E4;
}
.missing-doc-faq-box .missing-doc-faq-body .missing-doc-faq:last-of-type {
  border-bottom: none;
}
.missing-doc-faq-box .missing-doc-faq-footer {
  padding: 20px;
  border-top: 2px solid #E4E4E4;
}

.h-400 {
  min-height: 400px;
}

