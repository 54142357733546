.modal-dialog {
  top: 0px !important;
}

.pet-form {
  padding: 30px 15px;
}
.pet-form .why-important-mobile-text-container {
  width: 100%;
  float: left;
  padding-bottom: 55px;
}
.pet-form .why-important-mobile-text {
  min-height: 20px;
  float: left;
}
.pet-form .submit-button-container {
  width: 100%;
}
.pet-form .submit-button-text {
  float: left;
}
.pet-form .next-arrow-circle-right-custom {
  height: 25px;
  margin-left: 10px;
  padding-bottom: 1px;
}
.pet-form .fa-arrow-circle-right {
  background-color: transparent;
  margin-left: 10px;
}
.pet-form .fa-question-circle-o {
  font-size: 20px !important;
  float: left;
  margin-left: 10px;
}
.pet-form h1 {
  font-family: "OpenSans-Regular", "Open Sans", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
  font-size: 36px !important;
  color: #28373A !important;
}
@media (max-width: 991.98px) {
  .pet-form h1 {
    font-size: 21px !important;
  }
}
.pet-form h4 {
  font-size: 18px;
}
.pet-form .dropdown-container {
  color: #999;
}
.pet-form .why-important {
  font-size: 13px;
  color: #6B61F2 !important;
}
.pet-form .error {
  margin: -10px 0 10px 0;
  font-size: 12px;
  font-weight: 650;
}
.pet-form .dropdown-disabled {
  opacity: 0.3;
  pointer-events: none;
}
.pet-form .noQuoteMessage {
  font-size: 18px;
  font-weight: 650;
}
@media (min-width: 992px) {
  .pet-form {
    padding-top: 0 !important;
  }
}

