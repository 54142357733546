@import "./../../../styles/_variables";
@import "./../../../styles/_mixins.scss";

.avatar-editor {
  .avatar-dropzone {
    position: static !important;
  }

  .active-avatar-dropzone {
    .avatar-container {
      border: 4px solid $primary;
    }
  }

  .avatar-container {
    height: 133px;
    width: 133px;
    background-color: rgb(233, 230, 230);
    color: grey;
    border-radius: 133px;
    border: 2px solid $primary;
    overflow: hidden;
    align-self: center;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }

    &.no-image {
      cursor: pointer;
      padding: 0;
    }
  }

  .edit-container {
    height: 25px;
    width: 25px;
    border-radius: 12px;
    background-color: $primary;
    color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 4px;
    right: 10px;

    i {
      cursor: pointer;
    }
  }
}

@include media-breakpoint-up(md) {
  .avatar-editor {
    .active-avatar-dropzone {
      .avatar-container {
        border: 6px solid $primary;
      }
    }
    .avatar-container {
      height: 191px;
      width: 191px;
      border: 4px solid $primary;
    }
    .edit-container {
      height: 35px;
      width: 35px;
      right: 20px;
      i {
        font-size: 24px;
      }
    }
  }
}

// Photo Modal Window Styling
.photo-editor-modal {
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;
  max-width: 700px;

  .loader-container {
    z-index: 3;
  }

  .modal-content {
    margin: 0;
    border: none;
    border-radius: 0;
    width: 100%;
    height: inherit;

    .modal-header {
      padding: 4px 16px;
      border: 0;

      .close {
        padding: 0;
        margin: 0;
        z-index: 2;
      }

      .modal-title {
        width: 100%;
        text-align: center;
      }
    }

    .modal-body {
      padding: 0;

      .grow {
        padding: 10px 0;
      }

      .title-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding: 10px;
      }
    }

    .modal-popup {
      position: fixed;
      top: 50%;
      left: 50%;
       transform: translate(-50%, -50%);
    }
  }
}

@include media-breakpoint-down(sm) {
  .photo-editor-modal {
    max-width: 100%;
    height: 100%;
    margin: 0;
    min-height: calc(100vh - 20px);

    .modal-content {
      width: 100%;
      height: 100%;
    }
  }
}

//Media Query to handle styling for Portrait Orientation for Small Screen Devices
@media only screen and (max-device-width: 767px) and (orientation: portrait) {
  .photo-editor-modal {
    .modal-body {
      display: flex;
      flex-direction: column;

      & > div {
        flex: 1;
        display: flex;
        flex-direction: column;

        .grow {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .modal-popup {
      display: block;
    }
  }
}
