@import "./../../../../styles/_variables.scss";

.checkbox-container{
    input[type="checkbox"] {
        display: none;
    }
    label {
      cursor: pointer;
      user-select: none;
      margin: 0;
    }

    input[type="checkbox"] + label span {
        display: inline-block;
        vertical-align: middle;
        width: 26px;
        height: 26px;
        margin-right:10px;
        background: url(../../../../../../healthypaws/public/assets/images/checkbox-unchecked.png) 0px center no-repeat;
    }

    input[type="checkbox"]:checked + label span {
        background: url(../../../../../../healthypaws/public/assets/images/checkbox-checked.png) 0px center no-repeat;
    }
}