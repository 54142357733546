@import "../../../styles/variables";
@import "../../../styles/mixins";

.status {
    .fa-check-circle {
        font-size: 28px;
      }
    
      .status-info {
        font-size: 18px;
        font-weight: 700;
        padding-left: 8px;
      }
    
      .status-text {
        font-size: 18px;
        color: white;
        font-weight: 400;
      }
}
.review-policy {
    padding: 30px 15px;

    a {
        text-decoration: none;
        font-weight:700;
    }
    
    a:hover {
        text-decoration: underline;
    }

    .veterinary-exam, .new-pet {
        border: 2px solid rgba(228, 228, 228, 1);
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 25px;
    }
    .refer-friend {
        margin-top:0;
        margin-bottom:60px;
    }
    .add-another-pet-btn {
        height: 40px;
        margin-bottom: 20px;
    }
    .new-pet {
        .pet-name {
            font-size: 24px;
            font-weight: bold;
        }
        .tag {
            background-color:$primary;
            padding: 2px 8px 2px 5px;
            text-transform: uppercase;
            color: white;
            font-weight: bold;
            font-size: 13px;
            margin:0;
            margin-left: 15px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 5px;
            position:relative;
            &:before {
                content: "";
                float: left;
                position: absolute;
                top: 0;
                left: -7px;
                width: 0;
                height: 0;
                border-color: transparent $primary transparent transparent;
                border-style: solid;
                border-width: 12px 10px 11px 0;	  
            }
        }

        .table-borderless th, .table-borderless td {
            padding: 2px;
        }

        ul {
            padding-inline-start: 16px;
            font-size: 14px;
        }
    }
    @include media-breakpoint-up(lg) {
        padding-top: 0 !important;
    }
}