@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";

.mobile-app-update-billing-container {
    min-height: 60vh;
    padding-bottom: 55px;
    padding-bottom: 15px;
    display: block;

    .title-container {
        color: #28373a;
        font-family: "OpenSans-Regular", "Open Sans", sans-serif;
        font-size: 21px;
        padding: 15px 0 0;

        @include media-breakpoint-up(md) {
            padding: 15px 0;
        }
    }

    .creditOrDebitCard {
        font-weight: 700;
        font-family: "OpenSans-Bold", "Open Sans Bold", "Open Sans", sans-serif;
        color: #28373a;
        font-size: 14px;
    }

    #what-is-this-toggler {
        bottom: 13px;
        left: 13px;
        position: relative;
    }

    .dropdown-container {
        border: 2px solid #657493;
        border-radius: 16px;
        font-family: "OpenSans-Regular", "Open Sans", sans-serif;
        font-size: 16px;
        color: #28373a;
        padding-top: 7px;
        height: 45px;
    }

    .button-primary {
        background-color: #4aa574;
        border-radius: 20px;
        font-family: "Open Sans Semibold", "Open Sans", sans-serif;
        font-weight: 600;
        color: #ffffff;
    }

    .edit-text-container {
        .demo-form-group {
            border:none;
        }

        .demo-form-group-float {

            input[type=text] {
                border: 2px solid #657493;
                border-radius: 16px;
                font-family: "OpenSans-Regular", "Open Sans", sans-serif;
                font-size: 16px;
                color: #28373a;
                padding-top: 7px;
            }

            .floating-label-with-value {
                position: absolute;
                left: 10px;
                top: -3px;
                pointer-events: none;
                font-weight: 600;
                font-size: 12px;
                font-family: "Open Sans Semibold", "Open Sans", sans-serif;
                color: #657493;
                transition: 0.5s;
                background-color: #FFFFFF;
                width: min-content;
                padding-left: 5px;
                padding-right: 5px;
            }

            .floating-label-with-value,
            input:focus~label,
            input:focus~label {
              position: absolute;
              left: 10px;
              top: -3px;
              pointer-events: none;
              font-weight: 700;
              font-size: 12px;
              color: #0070C9;
              transition: 0.5s;
              background-color: #FFFFFF;
              padding-left: 5px;
              padding-right: 5px;
              width: min-content;
            }
        }
    }

    .cards-image {
        width: 182px;
        height: 25px;
        margin-bottom: 15px;

        @include media-breakpoint-up(md) {
            width: 211px;
            height: 29px;
            margin-bottom: 12px;
        }
    }

    .cybersource-logo {
        margin: 0px 0px 0px 10px;
        display: inline-block;
        width: 109px;
        height: 25px;
        position:relative;
        top:1px;

        @include media-breakpoint-up(md) {
            width: 125px;
            height: 29px;
            
        }
    }

    .pay-securely {
        margin: 0px 5px;
        font-size: 11px;

        @include media-breakpoint-up(md) {
            margin: 3px 5px;
            font-size: 14px;
        }
    }

    .authorize-net-text {
        display: inline-block;
        vertical-align: sub;
        height: 12px;
        width: 80px;
        margin: 2px 0px 0px 0px;

        @include media-breakpoint-up(md) {
            height: 13px;
            width: 83px;
            margin: 10px 0px 0px 5px;
        }
    }

    .online-protection-icon {
        height: 14px;
        width: 11px;
        margin: 3px 6px;
    }

    .online-protection-text {
        color: $secondary;
        font-weight: 700;
    }

    #cc_number_container {
        padding-left: 10px;
        color: #333333;
        border: 2px solid #657493;
        border-radius: 16px;   
        height: 45px;     
    }

    #cc_cvn_container {
        padding-left: 10px;
        color: #333333;
        border: 2px solid #657493;
        border-radius: 16px;
        height: 45px;        
    }

    #iframe_holder {
        width: 100%;

        @media (min-width: 815px) {
            height: 531px;
        }
    }

    .edit-payment-iframe {
        height: 807px;
        max-width: 768px;
        width: 100%;

        @media (min-width: 815px) {
            height: 531px;
        }
    }

    .input-container {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 470px;
        }
    }

    .street-address-container {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 569px;
        }
    }

    .address-container {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 269px;
        }
    }

    .button-container {
        width: 100%;
        margin-left: 0px;

        @include media-breakpoint-up(md) {
            width: 670px;
        }
    }

    .expiry-date {
        @include media-breakpoint-up(md) {
            width: 322px;
            margin-right: 26px;
        }
    }

    .cvn-container {
        width: auto;

        #cvn {
            width:50%;
        }
    }

    .margin-bottom {
        margin-bottom: 12px;
    }

    .margin-bottom-half {
        margin-bottom: 6px;
    }

    .cvn-number {
        width: 50%;

        @include media-breakpoint-up(md) {
            width: 122px;
        }
    }

    .link-text {
        color: #6b61f2;
        font-family: "Open Sans Semibold", "Open Sans", sans-serif;
        font-size: 12px;
        font-weight: 600;
    }

    .link-icon {
        font-size: 18px;
    }

    a {
        text-decoration: none !important;
    }

    .drop-down-text {
        font-size: 12px;
        font-weight: 400;
    }

    .note-text {
        font-size: 14px;
    }

    .note-text-border {
        background-color: rgb(242, 242, 242);
        border-color: $light;
        border-radius: 8px;
        border-style: solid;
        border-width: 2px;
        padding: 15px;
        box-sizing: border-box;
    }

    .lock-icon {
        font-size: 13px;
    }
    /*BEGIN address finder styling*/
    .recommended-address-container {
        position: absolute;
        width: 100%;
        //z-index: 200;
        display: none;
        margin-top: -12px;
        margin-left: 0px;
        max-width: 518px;
        height: 220px;
        border-width: 0px;
        //position: absolute;
        //left: 47px;
        //top: 742px;
        //width: 518px;
        height: 308px;
        background: inherit;
        background-color: rgba(255, 255, 255, 0);
        border: none;
        border-radius: 0px;
        //padding-top:60px;
    }

    @media only screen and (max-width: 767px) {

        .recommended-address-container {
            //background-color: red;
            //left: 35px;
            //top: 1029px;
            margin-left: 0px;
            //max-width: 355px;
            font-family: "ArialMT", "Arial", sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 13px;
            letter-spacing: normal;
            color: #333333;
            vertical-align: none;
            //text-align: center;
            line-height: normal;
            text-transform: none;
            //margin-top: 0px;
            width: 80%;
        }
    }

    .recommended-address-list {
        list-style: none;
        padding: 0px;
        font-weight: bold;
        font-size: 18px;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .recommended-address-list li {
        min-height: 44px;
        text-wrap: none;
        min-width: 250px;
        background: inherit;
        background-color: white;
        box-sizing: border-box;
        border-width: 1px;
        border-style: solid;
        border-color: #cccccc;
        border-radius: 0px;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        box-shadow: none;
        font-family: "OpenSans-Regular", "Open Sans", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 20px;
        text-wrap: none;
        padding-top: 6px;
        padding-left: 20px;
    }

    @media only screen and (max-width: 767px) {

        .recommended-address-list li {
            font-family: "ArialMT", "Arial", sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 13px;
            letter-spacing: normal;
            padding-top: 15px;
        }
    }



    .recommended-address-list li:hover {
        //background-color: lightyellow;
        cursor: pointer;
    }

    .addressIndex {
        display: none !important;
    }

    #streetAddressIcon {
        position: absolute;
        top: 12px;
        padding-left: 3px;
        padding-top: 2px;
    }

    #streetAddressIcon img {
        height: 18px;
    }

    .streetAddressMatching {
        font-weight: 700;
    }

    .display-none {
        display: none !important;
        z-index: 0;
    }

    .display-zindex { 
        z-index: 100;
        position: relative;
    }
    /*END address finder styling*/
}
