.stepper .stepper-header {
  background-color: #00a870;
  padding: 10px 0;
  font-size: 14px;
  color: #fff;
}
.stepper .stepper-header .step-details {
  font-size: 18px;
}

