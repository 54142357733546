.edit-text-container .demo-form-group {
  position: relative;
  height: 55px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #8D8D8D;
  margin-bottom: 0;
}
.edit-text-container .demo-form-group:focus-within {
  border: 2px solid #0070C9;
}
.edit-text-container .secure-field {
  margin-right: 40px !important;
  text-overflow: unset;
}
.edit-text-container .demo-form-group-normal {
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}
.edit-text-container .demo-form-group-normal input {
  width: 100%;
  height: 100%;
  margin-left: 10px;
  margin-right: 10px;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
}
.edit-text-container .demo-form-group-normal input::-webkit-input-placeholder {
  font-weight: 400;
  font-size: 20px;
  color: rgb(153, 153, 153);
}
.edit-text-container .demo-form-group-normal input:-moz-placeholder {
  /* Firefox 18- */
  font-weight: 400;
  font-size: 20px;
  color: rgb(153, 153, 153);
}
.edit-text-container .demo-form-group-normal input::-moz-placeholder {
  /* Firefox 19+ */
  font-weight: 400;
  font-size: 20px;
  color: rgb(153, 153, 153);
}
.edit-text-container .demo-form-group-normal input:-ms-input-placeholder {
  font-weight: 400;
  font-size: 20px;
  color: rgb(153, 153, 153);
}
.edit-text-container .demo-form-group-normal input:focus::-webkit-input-placeholder {
  color: transparent;
}
.edit-text-container .demo-form-group-normal input:focus:-moz-placeholder {
  color: transparent;
}
.edit-text-container .demo-form-group-normal input:focus::-moz-placeholder {
  color: transparent;
}
.edit-text-container .demo-form-group-normal input:focus:-ms-input-placeholder {
  color: transparent;
}
.edit-text-container .error {
  font-weight: 700 !important;
  font-size: 12px !important;
  color: #ff2f6d;
  text-align: left;
  margin: 1px 0px 0px 2px !important;
}
.edit-text-container .red-label {
  color: #ff2f6d !important;
  font-weight: 700;
}
.edit-text-container [placeholder] {
  text-overflow: ellipsis;
}
.edit-text-container .floating-label {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  padding-right: 20px;
}
.edit-text-container .demo-form-group-float input[type=text],
.edit-text-container .demo-form-group-float input[type=password] {
  width: 100%;
  height: 45px;
  margin-top: 5px;
  padding-top: 13px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  box-shadow: none;
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: 400;
  font-size: 20px;
  color: #333333;
}
.edit-text-container .demo-form-group-float input + label {
  position: absolute;
  left: 10px;
  top: 13px;
  pointer-events: none;
  font-weight: 400;
  font-size: 20px;
  color: rgb(153, 153, 153);
  transition: 0.5s;
}
.edit-text-container .demo-form-group-float .floating-label-with-value,
.edit-text-container .demo-form-group-float input:focus ~ label,
.edit-text-container .demo-form-group-float input:focus ~ label {
  position: absolute;
  left: 10px;
  top: 4px;
  pointer-events: none;
  font-weight: 700;
  font-size: 12px;
  color: #0070C9;
  transition: 0.5s;
}
.edit-text-container .demo-form-group-float .floating-label-with-value-font {
  font-weight: 400;
  font-size: 12px;
  color: #000000;
}
.edit-text-container .demo-form-group:focus-within ~ div {
  display: flex !important;
}
.edit-text-container .note-view {
  width: 100%;
  height: auto;
  margin-top: 5px;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
  border-width: 2px;
  border-style: solid;
  border-color: rgb(0, 112, 201);
  border-radius: 0px;
  font-weight: 400;
  font-size: 14px;
  color: #0070C9;
}
.edit-text-container .note-icon {
  margin-right: 10px;
  font-size: 18px;
}

