@import "../../../styles/mixins";
@import "../../../styles/variables";

.message-modal {
  max-width: 700px;
}

.close {
  font-size: 30px;
  font-weight: 700;
  color: $dark;
  opacity: 1;
  
  span{
    content:url("../../../../public/assets/images/modal-close.svg");
  width:24px;
  height:24px;
  }
}

.message-footer {
  border-top: 0 !important;
  padding: 30px 27px 27px 27px !important;
  
  div {
    cursor: pointer;
  }
}

.message-body {
  font-weight: 400;
  font-size: 14px;
  color: rgb(30, 30, 30);
  padding: 0px 27px 0px 27px !important;
  // word-break: break-all;

  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
  .disabled-link-wrapper {
    display: inline-block;
    pointer-events: none;
  }
  .disabled-link {
    pointer-events: none;
    color: #1e1e1e !important;
    text-decoration: none !important;
    font-weight: normal;
  }
}

.message-header-container {
  margin-left: 11px;
  margin-top: 20px;
  font-weight: 400;
  font-size: 30px;
  color: rgb(25, 166, 74);
  line-height: 1;
}