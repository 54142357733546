@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";

.update-profile-container {
    display: block;
    min-height: 60vh;

    .title-container {
        height: 50px;
        padding: 15px 0;

        @include media-breakpoint-up(md) {
            height: 79px !important;
        }

        @include media-breakpoint-up(lg) {
            height: 97px !important;
        }
    }

    .input-container {
        width: 100%;
        

        @include media-breakpoint-up(md) {
            width: 470px;
            max-width: 470px;
        }
    }
    .button-link_cancel {
        margin-top: 5px;
    }

    .phone-number-container {
        width: 100%;

        @include media-breakpoint-up(md) {
            width: 200px;
        }
    }

    .margin-bottom {
        margin-bottom: 12px !important;

        @include media-breakpoint-up(md) {
            margin-bottom: 14px !important;
        }
    }

    .second-parent-title {
        margin-top: 30px;
    }

    .sub_title_1 {
        font-weight: 700;
        font-size: 16px;
        color: $header-Color;

        @include media-breakpoint-up(md) {
            font-size: 20px;
        }

        @include media-breakpoint-up(md) {
            font-size: 24px;
        }
    }

    .sub_title_2 {
        font-weight: 400;
        color: $dark;
        font-size: 14px;
        margin-bottom: 5px;
        padding-left: 5px;
    }
}

.uneditable-input {
    
    background-color: rgba(242, 242, 242, 1);
    
}

.uneditable-input-wrapper {
    padding-top: 0px;
    padding-bottom: 5px;
    padding-left: 10px;
    margin-bottom: 15px;
    box-sizing: border-box;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(141, 141, 141, 1);
    border-radius: 0px;
}

.uneditable-label {
    padding: 0px;
    font-family: 'OpenSans-Regular', 'Open Sans', sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
    color: #000000;
    margin:0px;
}

.uneditable-field {
    padding: 0px; 
    font-size: 20px;
    margin:0px; 
    margin-top:-5px;
}

.cursor-pointer {
    cursor: pointer !important;
}