@import "../../../styles/variables";
@import "../../../styles/mixins";

.faq-box-container {

    margin-top: 5px;
    width: 100%;

    @include media-breakpoint-up(lg) {
        border: 2px solid $light;
        border-radius: 8px;
        background: $gray-1;
    }

    a {
        text-decoration: none !important;
    }

    .faq-header {
        color: $header-Color;
        font-size: 23px;
        padding: 15px 15px 10px 15px;
        border-bottom: 2px solid $light;
    }

    .link-text {
        font-weight: 700;
    }

    .link-icon {
        font-size: 18px;
    }

    .faq-body {
        background: #ffffff;

        .faq {
            width: 100%;
            display: block;
            padding: 10px 15px 10px 15px;
            font-weight: 600;
            font-size: 16px;
            text-align: left;
            border-bottom: 2px solid $light;
            color: $text-Blue;
            cursor: pointer;
        }

        .faq-more-info {
            border-bottom: none !important;
            font-family: 'OpenSans-Bold', 'Open Sans Bold', 'Open Sans' !important;
            font-weight: 700 !important;
            color:$text-Blue !important;
        }
    }

    .faq-footer {
        padding: 15px;
        border-top: 2px solid $light;
    }

    .collapse-margin {
        margin-top: 10px;
    }

    .faq-mobile-container {
        border-width: 2px;
        border-style: solid;
        border-color: $light;
        border-radius: 8px;
    }
}

.modal-close-button {
    width: 100%;

    @include media-breakpoint-up(md) {
        width: 238px;
    }
}