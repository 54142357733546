.segment-control .segment {
  flex: 1;
  background-color: rgb(255, 255, 255);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(52, 163, 80);
  box-shadow: none;
  font-weight: 700;
  font-size: 16px;
  color: #34A350;
  cursor: pointer;
}
.segment-control .segment-content {
  width: 100%;
  height: 44px;
  position: relative;
  text-align: center;
}
.segment-control .segment-icon {
  width: 34px;
  height: 27px;
  margin-right: 5px;
}
.segment-control .segment-info {
  font-size: 9px;
  line-height: normal;
  margin: 0;
  position: absolute;
  top: 2px;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.segment-control .segment-label {
  font-size: 20px;
  line-height: 45px;
  margin: 2px;
}
.segment-control .active {
  background-color: rgb(52, 163, 80);
  border-width: 1px;
  color: #fff;
}
.segment-control .disabled {
  background-color: #fff;
  border-width: 1px;
  border-color: #cccccc;
  box-shadow: none;
  color: #cccccc;
}
.segment-control .invisible {
  visibility: hidden;
}
.segment-control .segment:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.segment-control .segment:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

@media (max-width: 767.98px) {
  .invisible {
    display: none !important;
  }
  .segment-control.triplet {
    flex-wrap: wrap;
    margin-top: 1px;
  }
  .segment-control.triplet .segment {
    border-radius: 0;
    margin-top: -1px;
    margin-left: -1px;
    flex: 1 0 33.33%;
  }
  .segment-control.triplet .segment:first-child {
    border-top-left-radius: 8px;
  }
  .segment-control.triplet .segment:last-child {
    border-bottom-right-radius: 8px;
  }
  .segment-control.triplet .segment.lbr {
    border-bottom-left-radius: 8px;
  }
  .segment-control.triplet .segment.rbr {
    border-bottom-right-radius: 8px;
  }
  .segment-control.triplet .segment.rtr {
    border-top-right-radius: 8px;
  }
}

