@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";

.success-container {

    .successHeader {
        color: $gray-4 !important;
        margin-bottom: 0;
        font-size:12px !important;
        text-transform: uppercase;
        font-weight: 400;
    }

    .keep-in-mind {
        font-size: 13px !important;
        font-weight: 600;
        color:$header-Color !important;
        text-transform: none;
    }

    .margin-bottom {
        margin-bottom: 20px;
    }

    span {
        font-size: 14px;
        font-weight:600;

        @include media-breakpoint-up(md) {
            font-size: 16px;
        }
        
        &.keep-in-mind{
            font-weight:400;
        }
    }
}