.change-policy-options {
  padding: 30px 15px;
}
.change-policy-options .rate-text-large {
  font-size: 32px;
  font-weight: bold !important;
  font-family: "ArialMT", "Arial", sans-serif;
}
.change-policy-options .rate-text-large small {
  font-size: 12px;
  font-weight: normal;
}
.change-policy-options h1, .change-policy-options h2, .change-policy-options h4 {
  color: #28373a !important;
  font-weight: 400 !important;
}
.change-policy-options .subDescription {
  color: #28373a;
}
.change-policy-options textarea {
  font-size: 10px;
  color: #657493;
  font-weight: 400;
  border-color: #dae3e8;
}
.change-policy-options .labelStyle {
  font-family: "ArialMT", "Arial", sans-serif;
  color: #28373a;
}
.change-policy-options .button-link {
  color: #6b61f2 !important;
}
.change-policy-options .btn-outline-secondary {
  color: #6b61f2 !important;
  border: none;
}
.change-policy-options .pet-icon {
  width: 43px;
  height: 36px;
}
.change-policy-options .pet-policy-rates-content {
  border: 2px solid rgb(228, 228, 228);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.change-policy-options .monthly-rate-container {
  border: 2px solid #F0F0EE;
  margin-top: -2px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin-bottom: 30px;
}
.change-policy-options .monthly-rate-container .calendar-icon {
  font-size: 24px;
  color: #333333;
  padding-right: 15px;
}
.change-policy-options .monthly-rate-container .rate-container {
  padding: 15px;
}
.change-policy-options .monthly-rate-container .effective-details {
  background-color: #f7f7f7;
  padding: 15px;
}
.change-policy-options .monthly-rate-container .effective-details .effective-date {
  font-size: 14px;
}
.change-policy-options .monthly-rate-container .effective-details ul {
  padding-inline-start: 16px;
  font-size: 14px;
}
.change-policy-options .monthly-rate-container .CAFraudWarning {
  background-color: #f7f7f7;
  padding: 0px 15px 15px 15px;
  font-size: 12px;
  color: #28373a;
}
.change-policy-options .new-policy-effect p {
  font-size: 14px;
}

@media (min-width: 768px) {
  .change-policy-options .monthly-rate-container .rate-container {
    padding: 25px;
  }
  .change-policy-options .monthly-rate-container .effective-details {
    padding: 25px;
    background-color: #E4E4E4;
    padding: 15px;
  }
  .change-policy-options .monthly-rate-container .CAFraudWarning {
    background-color: #E4E4E4;
    padding: 0px 15px 15px 15px;
    font-size: 12px;
  }
}
@media (min-width: 992px) {
  .change-policy-options {
    padding-top: 0 !important;
  }
  .change-policy-options .pet-policy-rates-content {
    border: none;
  }
  .change-policy-options .monthly-rate-container {
    border: 2px solid #4aa574;
    border-radius: 8px;
    padding: 25px;
  }
  .change-policy-options .monthly-rate-container .rate-container {
    padding: 0;
  }
  .change-policy-options .monthly-rate-container .effective-details {
    padding: 0;
    background-color: transparent;
  }
  .change-policy-options .monthly-rate-container .CAFraudWarning {
    background-color: transparent;
    padding: 0px;
    font-size: 12px;
  }
}

