.no-enrollments .sorry {
  margin-top: 15px;
}
@media (min-width: 992px) {
  .no-enrollments {
    padding-top: 0 !important;
  }
}
.no-enrollments .back-to-home {
  margin-bottom: 16px;
  width: 255px;
}

