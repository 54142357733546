@import "../../../styles/variables";
@import "../../../styles/mixins";

.view-claim-status-header {
  .text-primary {
    margin: 15px 0px;
    color:$header-Color !important;
  }
  .button-secondary{
    padding:5px 30px;
    height:auto;
    width:auto;
    font-weight:600;
  }
  padding: 0px 0px;
}

.view-claim-status {
  padding: 0px 0px 30px 0px;
  
  a {
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }

  #claim-search-edit-text {
    .informed-text-field {
      margin-right: 35px;
    }
    .floating-label {
      padding-right: 50px;
    }
  }

  .claim-status {

    .btn-outline-secondary {
      border-width: 2px;
      font-weight: 700;
      width: 100%;
    }
    .btn-outline-secondary:hover {
      background: transparent !important;
    }

    .search-container {
      position: relative;
    }
    .search-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      color: $gray-2;
    }

    .claims-table {
      margin-top: 20px;

      .claims-table-header {
        display: flex;
        flex-direction: row;
        font-weight: 700;
        font-size: 12px;
        margin-bottom: 5px;

        @include media-breakpoint-up(md) {
          font-size: 14px;
        }
      }

      .no-claims {
        border: 2px solid $light;
        padding: 25px 10px;
        background-color: #f2f2f2;
      }

      .claims-striped-table {
        border: 2px solid $light;

        .claim-item {
          border-bottom: 2px solid $light;
          padding: 10px 0;

          .slideup,
          .slidedown {
            max-height: 0;
            overflow-y: hidden;
            -webkit-transition: max-height 0.5s ease-in-out;
            -moz-transition: max-height 0.5s ease-in-out;
            -o-transition: max-height 0.5s ease-in-out;
            transition: max-height 0.5s ease-in-out;
          }
          .slidedown {
            max-height: 60px;
          }
        }
        .claim-item:last-child {
          border-bottom: none;
        }
        .claim-item:nth-child(odd) {
          background-color: #f5fafb;
        }
        .claim-item:nth-child(even) {
          background-color: #ffffff;
        }
      }
    }
  }

  .refer-friend {
    margin-bottom: 24px;
  }
}
