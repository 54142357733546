.edit-field-reimbrusement-container h4 {
  margin-bottom: 0px;
}
.edit-field-reimbrusement-container .link-icon {
  font-size: 12px;
  text-decoration: none;
  font-weight: 700;
  margin-top: 5px;
}
@media (min-width: 768px) {
  .edit-field-reimbrusement-container .link-icon {
    margin-top: 0px;
  }
}
.edit-field-reimbrusement-container .button-link_cancel {
  margin-top: 6px;
}
.edit-field-reimbrusement-container .button-link {
  color: #6b61f2;
  font-weight: 700;
}
.edit-field-reimbrusement-container .button-mc-container {
  width: auto !important;
}
@media (min-width: 768px) {
  .edit-field-reimbrusement-container .button-mc-container {
    min-width: 466px !important;
  }
}
.edit-field-reimbrusement-container .select-description {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 0px;
  font-weight: 400;
  font-size: 14px;
  color: #28373a;
}
@media (min-width: 768px) {
  .edit-field-reimbrusement-container .select-description {
    font-size: 16px;
  }
}
.edit-field-reimbrusement-container .form-container {
  margin-left: 15px;
  margin-right: 15px;
}
.edit-field-reimbrusement-container .street-address-container {
  width: auto !important;
}
@media (min-width: 768px) {
  .edit-field-reimbrusement-container .street-address-container {
    width: 569px !important;
  }
}
.edit-field-reimbrusement-container .address-container {
  width: auto !important;
}
@media (min-width: 768px) {
  .edit-field-reimbrusement-container .address-container {
    width: 269px !important;
  }
}
.edit-field-reimbrusement-container .button-container {
  width: auto !important;
}
@media (min-width: 768px) {
  .edit-field-reimbrusement-container .button-container {
    width: 466px !important;
  }
}
.edit-field-reimbrusement-container .toggle-container {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 0;
}
.edit-field-reimbrusement-container .toggle-image {
  max-width: 100%;
  max-height: 100%;
}
.edit-field-reimbrusement-container .toggle-header {
  font-weight: 700;
  font-size: 12px;
  color: #333333;
  margin-top: 15px;
  margin-left: 0;
  margin-right: 15px;
  margin-bottom: 0;
}
.edit-field-reimbrusement-container .toggle-description {
  font-weight: 400;
  font-size: 12px;
  color: #333333;
}

