@import "./../../../../styles/_variables";

.photo-control {
  background-color: #fff;

  .btn-secondary {
    &.photo-control-btn {
      width: 136px !important;
    }
  }

  .photo-stepper-indicators {
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin: 0 10px;
    list-style: none;

    .step-indicator {
      flex: 0 1 auto;
      width: 10px;
      height: 10px;
      margin-right: 3px;
      margin-left: 3px;
      background-color: $gray-2;
      border-radius: 50%;

      &.active {
        background-color: $primary;
      }
    }
  }

  .control-group {
    padding: 25px;
  }

  .outline-btn {
    margin: 15px;
    width: 120px;
    height: 40px;
    background-color: rgba(255, 255, 255, 1);
    border: 2px solid rgba(121, 121, 121, 1);
    border-radius: 5px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    user-select: none;

    i {
      margin-right: 5px;
    }
  }
}
