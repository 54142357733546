.policy-docs .content-wrapper {
  padding: 22px 20px;
  border: 2px solid #E4E4E4;
  height: 100%;
  border-radius: 10px;
  word-break: break-word;
}
.policy-docs .content-wrapper p, .policy-docs .content-wrapper p a, .policy-docs .content-wrapper strong {
  font-size: 13px;
}
.policy-docs .faq-text {
  font-weight: 600;
}
.policy-docs a {
  text-decoration: none;
  font-weight: 600;
}

