@import "./../../../styles/_mixins.scss";
@import "./../../../styles/_variables.scss";

.reimbursement-container {

    display: block; 
    min-height: 60vh;

    .title-container {
        height: 50px;
        padding: 15px 0;

        @include media-breakpoint-up(md) {
            height: 79px !important;
        }

        @include media-breakpoint-up(lg) {
            height: 97px !important;
        }
    }

    .select-type {
        margin-top: 15px;
        margin-left: 15px;
        margin-right: 15px;
        margin-bottom: 0;
        font-size:18px;
        color:$header-Color;
        font-weight: 600;
    }

    .margin-bottom {
        margin-bottom: 15px !important;
    }

    .margin-bottom-half {
        margin-bottom: 7px !important;
    }

    a {
        text-decoration: none;
    }

    hr {
        height: 2px;
        background: $light;
        margin: 0px;
        border-top: 0;
    }

    .faq-container {
        border-width: 2px;
        border-style: solid;
        border-color: $light;
        border-radius: 8px;
    }

    .edit-reimbrusement-container {
        border-width: 2px;
        border-style: solid;
        border-color: $light;
        border-radius: 8px;

        .tab-parent {
            margin: 25px 15px;
            max-width: 384px;

            .unselected-tab {
                background-color: rgba(255, 255, 255, 1);
                box-sizing: border-box;
                border-width: 2px;
                border-style: solid;
                border-color: $button_Green;
                box-shadow: none;
                font-weight: 700;
                font-size: 16px;
                color: $button_Green;
            }

            .selected-tab {
                background-color: $button_Green;
                box-sizing: border-box;
                border-width: 2px;
                border-style: solid;
                border-color: $button_Green;
                font-weight: 700;
                font-size: 16px;
                color: #FFFFFF;
            }

            .left-tab {
                height: 44px;
                margin-bottom: 0;
                border-radius: 40px;
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                padding-left: 0;
                padding-right: 0;
            }

            .right-tab {
                height: 44px;
                margin-bottom: 0;
                border-radius: 40px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                padding-left: 0;
                padding-right: 0;
            }
        }

        .billing-notes {
            background-color: #f5fafb;
            padding: 15px;
            margin-bottom: 0;
            font-weight: 400;
            color: #000000;
            font-size: 12px;

            @include media-breakpoint-up(lg) {
                font-size: 14px;
            }
        }
    }


    .link-text {
        font-size: 14px;
        font-weight: 700;
    }

    .link-icon {
        font-size: 13px;
    }
}

.account-info-image {
    //width: 306px;
    height: 105px;
    object-fit: contain;
}

.account-info-heading-text {
    color: rgb(51, 51, 51);
    margin-bottom: 0px;
}
.account-info-margin {
    margin-top: 22px;
}

.close-button {
    width: 238px;
}