#footer-group {
  background-color: #717171;
  color: white;
  padding: 10px 2px;
  font-size: 14px;
}
@media (min-width: 768px) {
  #footer-group {
    padding-top: 25px;
  }
}
#footer-group .social-icons {
  padding: 10px;
}
#footer-group .social-icons-group {
  color: white;
}
#footer-group .social-icon {
  height: 38px;
  width: 38px;
  margin: 5px;
  cursor: pointer;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  #footer-group .address-social-icons-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }
}
#footer-group .address {
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  padding-right: 0px;
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  #footer-group .address {
    padding-left: 0px;
  }
}
#footer-group .address-row {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 15px;
}
#footer-group .address-row :last-child {
  width: fit-content;
}
#footer-group .address:first-child :last-child {
  margin-bottom: 20px;
}
#footer-group .footer-info, #footer-group .footer-info a {
  margin-top: 20px;
  font-size: 10px;
  color: white !important;
}
#footer-group .footer-image {
  margin: 10px;
}
#footer-group ul {
  list-style: none;
  padding: 0px;
}
#footer-group ul > li > a {
  color: white !important;
  font-size: 14px;
  white-space: nowrap;
  text-decoration: none !important;
  cursor: pointer;
}
#footer-group a:hover {
  text-decoration: underline !important;
}
#footer-group ul li:first-child > a {
  font-weight: 700;
}
#footer-group ul li {
  padding: 2px;
}
#footer-group.has-banner {
  padding: 10px 0 35px 0;
}

@media (min-width: 768px) {
  #footer-group {
    font-size: 12px;
  }
  #footer-group .footer-info {
    font-size: 10px;
  }
  #footer-group ul > li > a {
    font-size: 12px;
  }
  #footer-group .address {
    font-size: 12px;
  }
  #footer-group #footer-connected-icons {
    padding-right: 15px;
    padding-left: 0px;
  }
  #footer-group .mobile-hide {
    display: inline;
  }
  #footer-group .mobile-show {
    display: none;
  }
}
.mobile-hide {
  display: none;
}

.mobile-show {
  display: inline;
}

.USFooterLink {
  text-decoration: underline !important;
  cursor: pointer;
  font-size: 14px;
}

