@import "../../../styles/variables";
@import "../../../styles/mixins";

.change-policy-options {
  padding: 30px 15px;

  .rate-text-large {
    font-size: 30px;
    font-weight: bold;

    small {
      font-size: 12px;
      font-weight: normal;
    }
  }

  textarea {
    font-size: 10px;
  }

  .pet-icon {
    width: 43px;
    height: 36px;
  }

  .pet-policy-rates-content {
    border: 2px solid rgba(228, 228, 228, 1);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .monthly-rate-container {
    border: 2px solid $gray-1;
    margin-top: -2px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-bottom: 30px;

    .calendar-icon {
      font-size: 24px;
      color: #333333;
      padding-right: 15px;
    }

    .rate-container {
      padding: 15px;

      .notice {
        font-weight: 800;
        color: #d9001b;
      }
    }

    .effective-details {
      background-color: #f7f7f7;
      padding: 15px;

      .effective-date {
        font-size: 14px;
      }

      ul {
        padding-inline-start: 16px;
        font-size: 14px;
      }
    }
  }

  .new-policy-effect {
    p {
      font-size: 14px;
    }
  }
}

@include media-breakpoint-up(md) {
  .change-policy-options {
    .monthly-rate-container {
      .rate-container {
        padding: 25px;
      }

      .effective-details {
        padding: 25px;
        background-color: $light;
        padding: 15px;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .change-policy-options {
    .pet-policy-rates-content {
      border: none;
    }

    .monthly-rate-container {
      border: 2px solid $secondary;
      border-radius: 8px;
      padding: 25px;

      .rate-container {
        padding: 0;

        .notice {
          font-weight: 800;
          color: #d9001b;
        }
      }

      .effective-details {
        padding: 0;
        background-color: transparent;
      }
    }
    padding-top: 0 !important;
  }
}
