#mobile-logo-image {
  width: 192px;
}

#mobile-header-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fcfaf4;
  height: 80px;
}
@media (min-width: 768px) {
  #mobile-header-toolbar {
    height: 76px;
  }
}

#mobile-header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;
}
@media (min-width: 768px) {
  #mobile-header-sticky {
    z-index: 110;
  }
}

#mobile-horizontal-line {
  height: 0px;
  width: 100%;
  background-color: #34A350;
}

#mobile-header-message-image {
  height: 25px;
  width: 30px;
}

#mobile-toggle-icon img {
  width: 25px;
  height: 25px;
}

#mobile-toggle-menu-button {
  text-align: center;
  cursor: pointer;
  margin-left: 15px;
  padding-top: 12px;
}

#toggle-menu-label {
  font-size: 8px;
  font-weight: bold;
  vertical-align: top;
  color: #28373a;
}

#mobile-message-count-container {
  background-color: #ff2f6d;
  text-align: center;
  position: relative;
  display: block;
}

.mobile-message-count-small {
  height: 16px;
  width: 16px;
  left: -10px;
  top: -5px;
  border-radius: 9px;
}

.mobile-message-count-large {
  height: 20px;
  width: 20px;
  left: -12px;
  top: -6px;
  border-radius: 9px;
}

#mobile-message-count {
  color: white;
  font-weight: 700;
  font-size: 11px;
  display: inline-block;
  vertical-align: top;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
}

