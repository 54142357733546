.loader-container {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  overflow: hidden;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.5);
  width: 100%;
}

.spinner {
  border: 2px solid #f3f3f3;
  border-top: 3px solid #34A350;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: spin 1s infinite linear;
}

.full-page {
  position: fixed;
}

.op1 {
  background: rgb(255, 255, 255);
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

