.mobile-app-update-billing-container {
  min-height: 60vh;
  padding-bottom: 55px;
  padding-bottom: 15px;
  display: block;
  /*BEGIN address finder styling*/
  /*END address finder styling*/
}
.mobile-app-update-billing-container .title-container {
  color: #28373a;
  font-family: "OpenSans-Regular", "Open Sans", sans-serif;
  font-size: 21px;
  padding: 15px 0 0;
}
@media (min-width: 768px) {
  .mobile-app-update-billing-container .title-container {
    padding: 15px 0;
  }
}
.mobile-app-update-billing-container .creditOrDebitCard {
  font-weight: 700;
  font-family: "OpenSans-Bold", "Open Sans Bold", "Open Sans", sans-serif;
  color: #28373a;
  font-size: 14px;
}
.mobile-app-update-billing-container #what-is-this-toggler {
  bottom: 13px;
  left: 13px;
  position: relative;
}
.mobile-app-update-billing-container .dropdown-container {
  border: 2px solid #657493;
  border-radius: 16px;
  font-family: "OpenSans-Regular", "Open Sans", sans-serif;
  font-size: 16px;
  color: #28373a;
  padding-top: 7px;
  height: 45px;
}
.mobile-app-update-billing-container .button-primary {
  background-color: #4aa574;
  border-radius: 20px;
  font-family: "Open Sans Semibold", "Open Sans", sans-serif;
  font-weight: 600;
  color: #ffffff;
}
.mobile-app-update-billing-container .edit-text-container .demo-form-group {
  border: none;
}
.mobile-app-update-billing-container .edit-text-container .demo-form-group-float input[type=text] {
  border: 2px solid #657493;
  border-radius: 16px;
  font-family: "OpenSans-Regular", "Open Sans", sans-serif;
  font-size: 16px;
  color: #28373a;
  padding-top: 7px;
}
.mobile-app-update-billing-container .edit-text-container .demo-form-group-float .floating-label-with-value {
  position: absolute;
  left: 10px;
  top: -3px;
  pointer-events: none;
  font-weight: 600;
  font-size: 12px;
  font-family: "Open Sans Semibold", "Open Sans", sans-serif;
  color: #657493;
  transition: 0.5s;
  background-color: #FFFFFF;
  width: min-content;
  padding-left: 5px;
  padding-right: 5px;
}
.mobile-app-update-billing-container .edit-text-container .demo-form-group-float .floating-label-with-value,
.mobile-app-update-billing-container .edit-text-container .demo-form-group-float input:focus ~ label,
.mobile-app-update-billing-container .edit-text-container .demo-form-group-float input:focus ~ label {
  position: absolute;
  left: 10px;
  top: -3px;
  pointer-events: none;
  font-weight: 700;
  font-size: 12px;
  color: #0070C9;
  transition: 0.5s;
  background-color: #FFFFFF;
  padding-left: 5px;
  padding-right: 5px;
  width: min-content;
}
.mobile-app-update-billing-container .cards-image {
  width: 182px;
  height: 25px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .mobile-app-update-billing-container .cards-image {
    width: 211px;
    height: 29px;
    margin-bottom: 12px;
  }
}
.mobile-app-update-billing-container .cybersource-logo {
  margin: 0px 0px 0px 10px;
  display: inline-block;
  width: 109px;
  height: 25px;
  position: relative;
  top: 1px;
}
@media (min-width: 768px) {
  .mobile-app-update-billing-container .cybersource-logo {
    width: 125px;
    height: 29px;
  }
}
.mobile-app-update-billing-container .pay-securely {
  margin: 0px 5px;
  font-size: 11px;
}
@media (min-width: 768px) {
  .mobile-app-update-billing-container .pay-securely {
    margin: 3px 5px;
    font-size: 14px;
  }
}
.mobile-app-update-billing-container .authorize-net-text {
  display: inline-block;
  vertical-align: sub;
  height: 12px;
  width: 80px;
  margin: 2px 0px 0px 0px;
}
@media (min-width: 768px) {
  .mobile-app-update-billing-container .authorize-net-text {
    height: 13px;
    width: 83px;
    margin: 10px 0px 0px 5px;
  }
}
.mobile-app-update-billing-container .online-protection-icon {
  height: 14px;
  width: 11px;
  margin: 3px 6px;
}
.mobile-app-update-billing-container .online-protection-text {
  color: #FF5B0B;
  font-weight: 700;
}
.mobile-app-update-billing-container #cc_number_container {
  padding-left: 10px;
  color: #333333;
  border: 2px solid #657493;
  border-radius: 16px;
  height: 45px;
}
.mobile-app-update-billing-container #cc_cvn_container {
  padding-left: 10px;
  color: #333333;
  border: 2px solid #657493;
  border-radius: 16px;
  height: 45px;
}
.mobile-app-update-billing-container #iframe_holder {
  width: 100%;
}
@media (min-width: 815px) {
  .mobile-app-update-billing-container #iframe_holder {
    height: 531px;
  }
}
.mobile-app-update-billing-container .edit-payment-iframe {
  height: 807px;
  max-width: 768px;
  width: 100%;
}
@media (min-width: 815px) {
  .mobile-app-update-billing-container .edit-payment-iframe {
    height: 531px;
  }
}
.mobile-app-update-billing-container .input-container {
  width: 100%;
}
@media (min-width: 768px) {
  .mobile-app-update-billing-container .input-container {
    width: 470px;
  }
}
.mobile-app-update-billing-container .street-address-container {
  width: 100%;
}
@media (min-width: 768px) {
  .mobile-app-update-billing-container .street-address-container {
    width: 569px;
  }
}
.mobile-app-update-billing-container .address-container {
  width: 100%;
}
@media (min-width: 768px) {
  .mobile-app-update-billing-container .address-container {
    width: 269px;
  }
}
.mobile-app-update-billing-container .button-container {
  width: 100%;
  margin-left: 0px;
}
@media (min-width: 768px) {
  .mobile-app-update-billing-container .button-container {
    width: 670px;
  }
}
@media (min-width: 768px) {
  .mobile-app-update-billing-container .expiry-date {
    width: 322px;
    margin-right: 26px;
  }
}
.mobile-app-update-billing-container .cvn-container {
  width: auto;
}
.mobile-app-update-billing-container .cvn-container #cvn {
  width: 50%;
}
.mobile-app-update-billing-container .margin-bottom {
  margin-bottom: 12px;
}
.mobile-app-update-billing-container .margin-bottom-half {
  margin-bottom: 6px;
}
.mobile-app-update-billing-container .cvn-number {
  width: 50%;
}
@media (min-width: 768px) {
  .mobile-app-update-billing-container .cvn-number {
    width: 122px;
  }
}
.mobile-app-update-billing-container .link-text {
  color: #6b61f2;
  font-family: "Open Sans Semibold", "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
}
.mobile-app-update-billing-container .link-icon {
  font-size: 18px;
}
.mobile-app-update-billing-container a {
  text-decoration: none !important;
}
.mobile-app-update-billing-container .drop-down-text {
  font-size: 12px;
  font-weight: 400;
}
.mobile-app-update-billing-container .note-text {
  font-size: 14px;
}
.mobile-app-update-billing-container .note-text-border {
  background-color: rgb(242, 242, 242);
  border-color: #E4E4E4;
  border-radius: 8px;
  border-style: solid;
  border-width: 2px;
  padding: 15px;
  box-sizing: border-box;
}
.mobile-app-update-billing-container .lock-icon {
  font-size: 13px;
}
.mobile-app-update-billing-container .recommended-address-container {
  position: absolute;
  width: 100%;
  display: none;
  margin-top: -12px;
  margin-left: 0px;
  max-width: 518px;
  height: 220px;
  border-width: 0px;
  height: 308px;
  background: inherit;
  background-color: rgba(255, 255, 255, 0);
  border: none;
  border-radius: 0px;
}
@media only screen and (max-width: 767px) {
  .mobile-app-update-billing-container .recommended-address-container {
    margin-left: 0px;
    font-family: "ArialMT", "Arial", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    letter-spacing: normal;
    color: #333333;
    vertical-align: none;
    line-height: normal;
    text-transform: none;
    width: 80%;
  }
}
.mobile-app-update-billing-container .recommended-address-list {
  list-style: none;
  padding: 0px;
  font-weight: bold;
  font-size: 18px;
  overflow-x: hidden;
  overflow-y: auto;
}
.mobile-app-update-billing-container .recommended-address-list li {
  min-height: 44px;
  text-wrap: none;
  min-width: 250px;
  background: inherit;
  background-color: white;
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: #cccccc;
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "OpenSans-Regular", "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
  text-wrap: none;
  padding-top: 6px;
  padding-left: 20px;
}
@media only screen and (max-width: 767px) {
  .mobile-app-update-billing-container .recommended-address-list li {
    font-family: "ArialMT", "Arial", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 13px;
    letter-spacing: normal;
    padding-top: 15px;
  }
}
.mobile-app-update-billing-container .recommended-address-list li:hover {
  cursor: pointer;
}
.mobile-app-update-billing-container .addressIndex {
  display: none !important;
}
.mobile-app-update-billing-container #streetAddressIcon {
  position: absolute;
  top: 12px;
  padding-left: 3px;
  padding-top: 2px;
}
.mobile-app-update-billing-container #streetAddressIcon img {
  height: 18px;
}
.mobile-app-update-billing-container .streetAddressMatching {
  font-weight: 700;
}
.mobile-app-update-billing-container .display-none {
  display: none !important;
  z-index: 0;
}
.mobile-app-update-billing-container .display-zindex {
  z-index: 100;
  position: relative;
}

