@import "./../../styles/_variables.scss";
@import "./../../styles/_mixins.scss";
$blue:#6b61f2;
.sign-in-box {
  background-color: white;
  margin: 0 20px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  border-radius: 6px;
  position: relative;

  @include media-breakpoint-up(sm) {
    width: 381px;
  }

  @include media-breakpoint-up(md) {
    padding: 10px 20px 0 20px;
  }

  .success_logo {
    width: 75px;
    height: 75px;
  }

  .loader-container {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;
    flex-wrap: wrap;
    background-color: black;
    opacity: 0.25;
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    .loader-container {
      width: 381px;
    }
  }

  .loader-image {
    width: 50px;
    height: 50px;
  }

  .password-conatiner {
    position: relative;
    width: 100%;
  }

  .title-green {
    font-size: 28px;
    color: #28373a;
    text-align: left;
    @include media-breakpoint-down(sm) {
      font-size:22px;
    }
  }
  
  .title-green-center {
    text-align: center;
  }

  .margin-bottom {
    margin-bottom: 10px;
  }

  .title-description {
    font-weight: 400;
    font-size: 16px;
  }

  .login-lock-icon {
    margin-right: 5px;
    
  }

  .login-button-container {
    margin-bottom: 15px;
    width: 100%;
    background-color:#4aa574;
    border-radius:30px;
    margin-top:10px;
    @include media-breakpoint-down(sm) {
      font-size:16px;
    }
  }

  .send-Password-container {
    margin-top: 15px;
    height: 40px !important;
  }

  .back-to-signin-container {
    margin-top: 15px;
    height: 40px !important;
  }

  .button-secondary:disabled {
    background: #ffffff;
  }

  .back-to-signin-button {
    font-weight: 700;
    font-size: 18px;
    color: $blue;
    text-align: center;
    cursor: pointer;
  }

  .login-button-text {
    height: 23px;
  }

  .remember-me-container {
    margin-bottom: 10px;
  }

  @include media-breakpoint-up(sm) {
    .remember-me-container {
      margin-bottom: 0px;
    }
  }

  .remember-me {
    font-weight: 400;
    font-size: 14px;
    color: $dark;
    margin-bottom: 0px;
  }

  .forgot-password {
    font-weight: 700;
    height: 40px;
    font-size: 14px;
    color: $blue;
    text-align: center;
    cursor: pointer;
  }

  @include media-breakpoint-up(sm) {
    .forgot-password {
      // text-align: right;
      // margin-bottom: 5px;
      line-height: 40px;
    }
  }

  .create-password-container {
    // height: 45px;
    margin: 10px 0;
  }

  @include media-breakpoint-up(sm) {
    .create-password-container {
      margin: 20px 0;
    }
  }

  .ruler {
    display: block;
    margin: 0px;
  }

  @include media-breakpoint-up(sm) {
    .ruler {
      display: none;
    }
  }

  .enrolled-by-phone {
    font-weight: 400;
    font-size: 14px;
    color: $dark;
    margin-right: 4px;
  }

  .create-password {
    font-weight: 700;
    color: $blue;
    font-size: 14px;
    text-align: center;
    cursor: pointer;
  }

  // Check Box - ios 8 like
  // Irrespective of form rule
  input[type="checkbox"].ios8-switch {
    position: absolute;
    margin: 8px 0 0 16px;
    display: none;
  }

  input[type="checkbox"].ios8-switch+label {
    position: relative;
    padding: 0px 0 0 50px; // line-height: 2.0em;
  }

  input[type="checkbox"].ios8-switch+label:before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 40px;
    /* x*5 */
    height: 24px;
    /* x*3 */
    border-radius: 16px;
    /* x*2 */
    background: #fff;
    border: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  input[type="checkbox"].ios8-switch+label:after {
    content: "";
    position: absolute;
    display: block;
    left: 0px;
    top: 0px;
    width: 24px;
    height: 24px;
    border-radius: 16px;
    background: #fff;
    border: 1px solid #d9d9d9;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }

  input[type="checkbox"].ios8-switch+label:hover:after {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  }

  input[type="checkbox"].ios8-switch:checked+label:after {
    margin-left: 16px;
  }

  input[type="checkbox"].ios8-switch:checked+label:before {
    background: #55D069;
  }

  .no-padding {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .remember-me-forgot-password-continer {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .remember-me-container {
    justify-content: center;
    align-items: center;
    // height: 40px;
    // margin-bottom: 15px;
  }


  @include media-breakpoint-up(sm) {
    .remember-me-container {
      justify-content: flex-start;
      margin-bottom: 0px;
    }
  }
}

.password-sent-box {
  height: 377px;
}