@import "../../../styles/variables";
@import "../../../styles/mixins";
.pet-plan-collapse {
    border: 2px solid $light;
    padding: 9px 15px;
    margin-top:-2px;
    .pet-plan-header {
        font-weight: bold;
        margin-bottom: 0;
        .pet-collapse-button {
            position: absolute;
            right: 50px;
            margin-top: -15px;
            .arrow-icon {
                font-size:36px;
                color: $text-Blue
            }
        }
        .month-text {
            font-size: 12px;
            font-weight: normal;
        }
    }
    .rate-title, .rate-text {
        padding-left: 25px;
    }
}

@include media-breakpoint-up(md) {
    .pet-plan-collapse {
        padding: 5px 25px;
    }
}