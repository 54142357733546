html.noscroll {
  overflow: hidden;
}

body.noscroll {
  overflow: visible;
  height: 100%;
  position: fixed;
  width: 100%;
}

.side-drawer-container.closed {
  position: static;
}
.side-drawer-container.closed .overlay {
  display: none;
}
.side-drawer-container.closed #right-side-drawer {
  width: 0px;
  position: static;
}

.side-drawer-container {
  height: 100%;
  width: 100%;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 80px;
  z-index: 200;
  display: flex;
  justify-content: space-between;
  /* These 2 below for menu scroll handling: */
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}
@media (min-width: 768px) {
  .side-drawer-container {
    top: 76px;
  }
}
.side-drawer-container .overlay {
  position: absolute;
  left: 0px;
  z-index: -1;
  width: 100%;
  height: 100%;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
.side-drawer-container #left-side-drawer {
  height: 100%;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
  width: 100%;
}
.side-drawer-container #left-side-drawer .left-content-holder {
  min-width: 280px;
  height: 100%;
  width: 95%;
  max-width: 380px;
  overflow-y: scroll;
  background: white;
  padding: 0px 0px;
}
.side-drawer-container #left-side-drawer .left-drawer-close-button {
  padding: 5px 10px;
  min-width: 40px;
  color: white;
  font-size: 28px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}
.side-drawer-container #right-side-drawer {
  height: 100%;
  width: 100%;
  position: absolute;
  right: 0px;
  transform: translateX(100%);
  transition: transform 0.3s ease-out;
}
.side-drawer-container #right-side-drawer .right-content-holder {
  min-width: 280px;
  width: 95%;
  max-width: 380px;
  height: 100%;
  overflow-y: scroll;
  background: white;
  padding: 0px 0px;
}
.side-drawer-container #right-side-drawer .right-drawer-close-button {
  padding: 5px 10px;
  color: white;
  font-size: 28px;
  min-width: 40px;
  font-weight: 400;
  font-family: Arial, Helvetica, sans-serif;
}

.side-drawer-container.left-open {
  position: absolute;
}
.side-drawer-container.left-open #left-side-drawer {
  transform: translateX(0);
}
.side-drawer-container.left-open .overlay {
  opacity: 1;
}

.side-drawer-container.right-open {
  position: absolute;
}
.side-drawer-container.right-open #right-side-drawer {
  transform: translateX(0);
}
.side-drawer-container.right-open .overlay {
  opacity: 1;
}

