.claim-form-box {
  border: 2px solid #dae3e8;
  border-radius: 10px;
  background: #f5fafb;
  margin-bottom: 25px;
  width: 100%;
}
.claim-form-box .claim-form-header {
  color: #28373a;
  padding: 15px 15px 10px 15px;
  font-size: 23px;
}
.claim-form-box .claim-form-body {
  padding: 15px;
  background: #ffffff;
}
.claim-form-box .claim-form-body .form-links {
  border-bottom: 1px solid #E4E4E4;
}
.claim-form-box .claim-form-body .form-links i {
  font-size: 20px;
  color: #28373a;
}
.claim-form-box .claim-form-body .form-links a {
  padding-left: 10px;
  color: #FF5B0B;
  font-weight: 700;
  text-decoration: none;
  word-break: break-all;
  word-break: break-word;
  max-width: inherit;
}
.claim-form-box .claim-form-body .form-links a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.claim-form-box .claim-form-body .form-links a:hover .truncate {
  text-decoration: underline;
}
.claim-form-box .claim-form-body .form-info {
  margin-bottom: 22px;
}
.claim-form-box .claim-form-body .form-info .subtitle {
  font-weight: 700;
  color: #657493;
  font-size: 12px;
}
.claim-form-box .claim-form-body .form-info .subtitle-heading {
  font-weight: 700;
  color: #28373a;
}
.claim-form-box .claim-form-body .form-info a {
  color: #FF5B0B;
  font-weight: 700;
  text-decoration: none;
}
.claim-form-box .claim-form-body .form-info a:hover {
  text-decoration: underline;
  cursor: pointer;
}
.claim-form-box .claim-form-footer {
  padding: 20px;
}

