.oops {
  margin-top: 20px;
  margin-bottom: 20px;
}
.oops .m-block {
  display: block;
}
.oops .link {
  text-decoration: none;
  line-height: 2;
  color: #6b61f2 !important;
  font-size: 16px !important;
  font-weight: 600;
  white-space: nowrap;
}
.oops .subHeadline {
  font-family: "OpenSans-Regular", "Open Sans", sans-serif;
  font-size: 16px !important;
  color: #28373a !important;
  text-align: left;
  line-height: normal;
}
.oops .oopsHeading {
  color: #28373a;
  font-size: 36px;
}
@media (min-width: 768px) {
  .oops .m-block {
    display: inline;
  }
}
@media (max-width: 991.98px) {
  .oops .oopsHeading {
    font-size: 21px;
    font-weight: 400;
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .oops .oopsHeading {
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 0;
  }
}

