@import "../../../styles/variables";
@import "../../../styles/mixins";

.text-color-violet1 {
    color: $violet-1 !important;
}

 

.claim-details-form {
  font-size: 14px;
  padding: 10px 0;
  @include media-breakpoint-up(sm) {
    margin-left: 14px;
  }

  a {
    color: $secondary;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none !important;
    vertical-align: bottom;
  }

  .vet-clinic-dropdown-disable {
    pointer-events: none;
    opacity: 0.5;
  }

  a:hover {
    color: $secondary !important;
    text-decoration: underline !important;
  }

  .icon {
    font-size: 12px !important;
  }
  .add-claim-next-button-block {
    position: relative;
    .add-details-next-step-btn {
      width: 200px !important;
    }
  }

  .sub-title-file-claim {
    font-size: 13px;
    @include media-breakpoint-up(sm) {
      font-size: 18px;
    }
  }

  .add-details-skip-now-btn {
    float: none;
    width: 100% !important;
    padding-top: 10px;
    position: absolute;
    right: 0;
    @include media-breakpoint-up(sm) {
      float: right;
      padding: 15px;
      margin-top: 18px;
      width: 150px !important;
    }
  }

  form {
    width: 100%;
    .form-field {
      margin-bottom: 12px;
    }

    .btn-secondary {
      margin-top: 20px;
    }

    @include media-breakpoint-up(md) {
      margin: auto !important;
      .btn-secondary {
        width: 50% !important;
      }
    }

    .form-elements-container {
      width: 100%;
      margin: 0 auto;
      @include media-breakpoint-up(md) {
        width: 60%;
      }
    }
  }

  .claim-question-toggle {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    p {
      font-weight: normal;
    }
    i {
      font-size: 12px;
    }
  }
}

.modal-body {
  padding-top: 0 !important;

  .btn-secondary:hover {
    color: #ffffff !important;
    background: $secondary !important;
  }
}
