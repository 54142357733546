.photo-filter {
  background-color: #fff;
}
.photo-filter .image-container {
  display: flex;
  justify-content: center;
  background-color: black;
}
.photo-filter .image-container .original-image {
  width: 200px;
  height: 200px;
}
.photo-filter .filters-holder {
  overflow-x: scroll;
  padding: 15px 0 0 15px;
  background-color: #000;
  border-top: 1px solid #272626;
}
.photo-filter .filters-holder .image-filters {
  height: 110px;
  display: flex;
  min-width: 595px;
  justify-content: center;
}
.photo-filter .filters-holder .image-filters .filter-image-container {
  padding-right: 15px;
}
.photo-filter .filters-holder .image-filters .filter-image-container.active svg {
  border: 2px solid #fff;
}
.photo-filter .filters-holder .image-filters .filter-image-container.active .filter-name {
  color: #fff;
}
.photo-filter .filters-holder .image-filters .filter-image-container .filter-image {
  width: 70px;
  height: 70px;
  margin-bottom: 5px;
}
.photo-filter .filters-holder .image-filters .filter-image-container .filter-image svg {
  border-radius: 50%;
}
.photo-filter .filters-holder .image-filters .filter-image-container .filter-name {
  user-select: none;
  color: #dae3e8;
  font-size: 12px;
  text-align: center;
}

